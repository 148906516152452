import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, catchError, map, throwError } from "rxjs";
import { FLIGHTS_ENDPOINTS, FlightsEndpoints } from "../flights.tokens";
import { Alert } from "../models/alerts.model";
import {
    AlertPointOfInterest,
    AlertPointOfInterestWithPages,
    AlertProposal,
    AlertType,
    ArchiveCheckinFormValues,
    FlightsData,
    FlightsErrorType,
    FlightsTabType,
    LocationAlertEntity,
    ModifyCheckinFormValues,
    PoiType,
} from "../models/flight.models";
import {
    ArchiveCheckinRequestPayload,
    GetFlightsResponseBody,
    GetPoiListResponseBody,
    ModifyCheckinRequestPayload,
    PageableResponseBody,
    categorizeAlertsByBadge,
    convertGetPoiListResponseBodyToPoiWithPages,
    getFlights,
    prepareCheckinModificationPayload,
} from "./flights-api.converters";

@Injectable({
    providedIn: "root",
})
export class FlightsApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(FLIGHTS_ENDPOINTS) private readonly endpoints: FlightsEndpoints) {}

    public getFlights(isAtcController: boolean, tabType?: FlightsTabType): Observable<FlightsData> {
        return this.httpClient.get<GetFlightsResponseBody>(this.endpoints.getFlights).pipe(
            map((response) => getFlights(response?.items, isAtcController, tabType)),
            catchError(() => throwError(() => ({ type: FlightsErrorType.CannotGetFlights })))
        );
    }

    public standbyCheckin(id: string, duration: number): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.standbyCheckin, { id }), {
                duration: `PT${duration}M`,
            })
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotStandbyCheckin }))));
    }

    public modifyCheckinAndAccept(id: string, formValues: ModifyCheckinFormValues): Observable<void> {
        const requestBody: ModifyCheckinRequestPayload = prepareCheckinModificationPayload(formValues);

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.modifyCheckin, { id }), requestBody)
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotStandbyCheckin }))));
    }

    public acceptCheckin(id: string) {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.modifyCheckin, { id }), {})
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotStandbyCheckin }))));
    }

    public stopCheckin(id: string) {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.stopCheckin, { id }), {})
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotStandbyCheckin }))));
    }

    public rejectCheckin(id: string) {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.modifyCheckin, { id }))
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotStandbyCheckin }))));
    }

    public archiveCheckin(id: string, formValues: ArchiveCheckinFormValues) {
        const body: ArchiveCheckinRequestPayload = {
            reason: {
                id: formValues.id,
                args: {
                    value: formValues.description,
                },
            },
        };

        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.getFlights, { id }), { body })
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotArchiveCheckin }))));
    }

    public getAlerts() {
        return this.httpClient.get<{ alerts: PageableResponseBody<Alert> }>(this.endpoints.alerts).pipe(
            map((response) => categorizeAlertsByBadge(response.alerts.content)),
            catchError(() => throwError(() => ({ type: FlightsErrorType.CannotGetAlerts })))
        );
    }

    public createLocationAlert(alertData: LocationAlertEntity): Observable<void> {
        return this.httpClient
            .post<void>(this.endpoints.alerts, alertData)
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotCreateLocationAlert }))));
    }

    public getAlertProposals(type: AlertType, poiId?: string, designator?: string): Observable<AlertProposal> {
        const params: HttpParams = new HttpParams();
        params.append("type", type);

        if (poiId) {
            params.append("poiId", poiId);
        }

        if (designator) {
            params.append("designator", designator);
        }

        return this.httpClient
            .get<AlertProposal>(this.endpoints.getAlertProposals, { params })
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotGetAlertProposals }))));
    }

    public resendCheckinConfirmation(id: string): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.resendCheckinConfirmation, { id }), {})
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotResendCheckinConfirmation }))));
    }

    public getPoiList(poiType: PoiType, isBookmarked: boolean = false): Observable<AlertPointOfInterestWithPages> {
        const params: HttpParams = new HttpParams().append("bookmarked", isBookmarked);

        if (poiType) {
            params.append("type", poiType);
        }

        return this.httpClient.get<GetPoiListResponseBody>(this.endpoints.getPoiList, { params }).pipe(
            map((response) => convertGetPoiListResponseBodyToPoiWithPages(response.items)),
            catchError(() => throwError(() => ({ type: FlightsErrorType.CannotGetPointsOfInterest })))
        );
    }

    public setBookmarkedHospitals(bookmarkedHospitals: AlertPointOfInterest[], newBookmarkedHospitals: AlertPointOfInterest[]) {
        const payload = this.createBookmarkedHospitalsRequestPayload(bookmarkedHospitals, newBookmarkedHospitals);

        return this.httpClient
            .put<GetPoiListResponseBody>(this.endpoints.getPoiList, payload)
            .pipe(catchError(() => throwError(() => ({ type: FlightsErrorType.CannotBookmarkHospitals }))));
    }

    private createBookmarkedHospitalsRequestPayload(
        bookmarkedHospitals: AlertPointOfInterest[],
        newBookmarkedHospitals: AlertPointOfInterest[]
    ) {
        const addBookmarks = newBookmarkedHospitals
            .filter(
                (newBookmarkedHospital) =>
                    newBookmarkedHospital.isBookmarked &&
                    !bookmarkedHospitals.some(
                        (bookmarkedHospital) => bookmarkedHospital.id === newBookmarkedHospital.id && bookmarkedHospital.isBookmarked
                    )
            )
            .map((hospital) => hospital.id);

        const removeBookmarks = bookmarkedHospitals
            .filter(
                (bookmarkedHospital) =>
                    bookmarkedHospital.isBookmarked &&
                    !newBookmarkedHospitals.some(
                        (newBookmarkedHospital) => newBookmarkedHospital.id === bookmarkedHospital.id && newBookmarkedHospital.isBookmarked
                    )
            )
            .map((hospital) => hospital.id);

        return { addBookmarks, removeBookmarks };
    }
}
