<ng-container *ngrxLet="{ isBookmarkedHospitalsVisible: isBookmarkedHospitalsVisible$, hospitalList: hospitalList$ } as vm">
    <div class="poi-action">
        <dtm-ui-icon name="search"></dtm-ui-icon>
    </div>
    <section class="hospitals">
        <div class="poi-action" (click)="displayBookmarkedHospitals()">
            <dtm-ui-icon name="first-aid-kit-line"></dtm-ui-icon>
        </div>
        <div class="list-wrapper" *ngIf="vm.isBookmarkedHospitalsVisible">
            <div class="bookmarked-hospital-list">
                <div class="element" *ngFor="let hospital of vm.hospitalList">
                    {{ hospital.title | ellipsifyText : 15 }}
                </div>
            </div>
            <div class="element bookmarked" (click)="hospitalClick.emit()"><dtm-ui-icon name="star-fill"></dtm-ui-icon></div>
        </div>
    </section>

    <div class="poi-action">
        <dtm-ui-icon name="complex-helicopter"></dtm-ui-icon>
    </div>
    <div class="poi-action">
        <dtm-ui-icon name="collage"></dtm-ui-icon>
    </div>
</ng-container>
