import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { AlertBadgesComponent } from "./components/alert-badges/alert-badges.component";
import { AlertGeometryPanelComponent } from "./components/alert-geometry-panel/alert-geometry-panel.component";
import { AlertSelectButtonComponent } from "./components/alert-select-button/alert-select-button.component";
import { AlertsListComponent } from "./components/alerts-list/alerts-list.component";
import { AlertApiService } from "./services/alert-api.service";
import { AlertState } from "./state/alert.state";

@NgModule({
    imports: [
        CommonModule,
        LetModule,
        MatSlideToggleModule,
        FormsModule,
        PushModule,
        MatTooltipModule,
        SharedUiModule,
        SharedI18nModule,
        MatMenuModule,
        MatButtonModule,
        NgxsModule.forFeature([AlertState]),
        IconDirective,
    ],
    declarations: [AlertBadgesComponent, AlertGeometryPanelComponent, AlertSelectButtonComponent, AlertsListComponent],
    exports: [AlertBadgesComponent, AlertGeometryPanelComponent, AlertSelectButtonComponent, AlertsListComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "datsLibAlert",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class AlertModule {
    public static forRoot(): ModuleWithProviders<AlertModule> {
        return {
            ngModule: AlertModule,
            providers: [AlertApiService],
        };
    }

    public static forTest(): ModuleWithProviders<AlertModule> {
        return {
            ngModule: AlertModule,
            providers: [
                {
                    provide: AlertApiService,
                    useValue: null,
                },
            ],
        };
    }
}
