import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { FunctionUtils, LocalComponentStore, MINUTES_IN_HOUR, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map } from "rxjs";
import { FlightOperation } from "../../models/flight.models";

// eslint-disable-next-line no-magic-numbers
const PREDEFINED_TIME_OFFSET = [5, 15, 30, 59];

interface StandbyCheckinFormComponentState {
    isTimeExceeded: boolean;
    flightOperation: FlightOperation | undefined;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-standby-checkin-form[flightOperation]",
    templateUrl: "./standby-checkin-form.component.html",
    styleUrls: ["./standby-checkin-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StandbyCheckinFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => StandbyCheckinFormComponent),
            multi: true,
        },
    ],
})
export class StandbyCheckinFormComponent implements ControlValueAccessor, Validator {
    @Input() public set flightOperation(value: FlightOperation) {
        this.localStore.patchState({ flightOperation: value });
    }

    @Output() public isTimeExceededChange = new EventEmitter<boolean>();

    protected readonly PREDEFINED_TIME_OFFSET = PREDEFINED_TIME_OFFSET;
    protected readonly isTimeExceeded$ = this.localStore.selectByKey("isTimeExceeded");
    protected readonly operationDetails$ = this.localStore.selectByKey("flightOperation").pipe(
        RxjsUtils.filterFalsy(),
        map((operation) => ({
            plannedStartAt: operation.plannedStartAt,
            plannedEndAt: operation.plannedEndAt,
            maxHeight: operation.geography.maxHeight,
        }))
    );

    protected readonly durationControl = new FormControl<number>(0, { nonNullable: true });

    private propagateTouch = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: number) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<StandbyCheckinFormComponentState>) {
        this.localStore.setState({
            isTimeExceeded: false,
            flightOperation: undefined,
        });

        this.propagateFormValues();
        this.listenOnOffsetControl();
    }

    public registerOnChange(fn: (value: number) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.durationControl.valid) {
            return null;
        }

        return this.durationControl.invalid ? { invalidOffset: true } : null;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.durationControl.disable();
        } else {
            this.durationControl.enable();
        }
    }

    public writeValue(value: number | null): void {
        if (value) {
            this.durationControl.reset(value);
        } else {
            this.durationControl.reset();
        }
    }

    private listenOnOffsetControl(): void {
        this.durationControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            const flightOperation = this.localStore.selectSnapshotByKey("flightOperation");

            if (!flightOperation) {
                return;
            }

            const isTimeExceeded = value >= MINUTES_IN_HOUR;

            this.localStore.patchState({ isTimeExceeded });

            this.isTimeExceededChange.emit(isTimeExceeded);
        });
    }

    private propagateFormValues(): void {
        this.durationControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.durationControl.value);
            this.propagateTouch();
        });
    }
}
