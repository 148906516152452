import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { AirspaceElement, TagImportance } from "../../models/flight.models";

interface RpaBadgeComponentState {
    elements: AirspaceElement[];
    designatorsLabel: string | undefined;
}

const TAG_IMPORTANCE = [TagImportance.Red, TagImportance.Yellow, TagImportance.Green, TagImportance.Blue];

@Component({
    selector: "dats-lib-rpa-badge[elements]",
    templateUrl: "./rpa-badge.component.html",
    styleUrls: ["./rpa-badge.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RpaBadgeComponent {
    @Input() public set elements(value: AirspaceElement[]) {
        if (value && value.length > 0) {
            this.localStore.patchState({
                elements: this.sortElements(value),
                designatorsLabel: this.getDesignatorsLabel(value),
            });
        } else {
            this.localStore.patchState({
                elements: [],
                designatorsLabel: undefined,
            });
        }
    }

    protected readonly elements$ = this.localStore.selectByKey("elements").pipe(RxjsUtils.filterFalsy());
    protected readonly designatorsLabel$ = this.localStore.selectByKey("designatorsLabel");
    protected readonly badgeClassName$ = this.elements$.pipe(
        map((elements: AirspaceElement[]) => {
            if (!elements?.length) {
                return "";
            }

            return this.getBadgeClassName(elements[0]);
        })
    );

    constructor(private localStore: LocalComponentStore<RpaBadgeComponentState>) {
        this.localStore.setState({
            elements: [],
            designatorsLabel: undefined,
        });
    }

    private getDesignatorsLabel(elements: AirspaceElement[]): string {
        if (elements.length === 1) {
            return "";
        }

        return elements
            .map((element: AirspaceElement) => element.designator)
            .sort()
            .join(", ");
    }

    private sortElements(elements: AirspaceElement[]): AirspaceElement[] {
        return elements.sort(this.compareElements.bind(this));
    }

    private compareElements(leftElement: AirspaceElement, rightElement: AirspaceElement): number {
        const leftMostImportantTagIndex = this.getMostImportantTagIndex(leftElement.tags);
        const rightMostImportantTagIndex = this.getMostImportantTagIndex(rightElement.tags);

        if (leftMostImportantTagIndex === rightMostImportantTagIndex) {
            return leftElement.designator.localeCompare(rightElement.designator);
        }

        return leftMostImportantTagIndex - rightMostImportantTagIndex;
    }

    private getMostImportantTagIndex(tags: string[]): number {
        let mostImportantTagIndex = TAG_IMPORTANCE.length;

        for (const tag of tags) {
            const tagImportanceIndex = this.getTagImportanceIndex(tag);

            if (tagImportanceIndex !== null && tagImportanceIndex < mostImportantTagIndex) {
                mostImportantTagIndex = tagImportanceIndex;
            }
        }

        return mostImportantTagIndex;
    }

    private getTagImportanceIndex(tag: string | TagImportance): number | null {
        const tagImportanceIndex = TAG_IMPORTANCE.indexOf(tag.toUpperCase() as TagImportance);

        return tagImportanceIndex >= 0 ? tagImportanceIndex : null;
    }

    private getBadgeClassName(element: AirspaceElement): string {
        const importanceIndex = this.getMostImportantTagIndex(element.tags);

        switch (TAG_IMPORTANCE[importanceIndex]) {
            case TagImportance.Red:
                return "error";
            case TagImportance.Yellow:
                return "warning";
            case TagImportance.Green:
                return "success";
            case TagImportance.Blue:
                return "secondary";
            default:
                return "default";
        }
    }
}
