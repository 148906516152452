<ng-container
    *ngrxLet="{
        flightsTabList: flightsTabList$,
        selectedTab: selectedTab$,
        isActionSidebarOpen: isActionSidebarOpen$,
        actionButtonState: actionButtonState$,
        isPanelProcessing: isPanelProcessing$,
        isPanelExpanded: isPanelExpanded$,
        categorizedFlights: categorizedFlights$,
        isHospitalListOpen: isHospitalListOpen$,
        isBookmarkedHospitalsVisible: isBookmarkedHospitalsVisible$,
        selectedFlightId: selectedFlightId$,
    } as vm"
>
    <dtm-map-leaflet-map [mapOptions]="mapOptions" (mapClick)="selectFlight(undefined, 'map')">
        <dats-lib-flights-layer
            [categorizedFlights]="vm.categorizedFlights"
            [selectedFlightId]="vm.selectedFlightId"
            (flightClick)="selectFlight($event, 'map')"
        ></dats-lib-flights-layer>
    </dtm-map-leaflet-map>
    <section class="points-of-interest">
        <div class="poi-action">
            <dtm-ui-icon name="search"></dtm-ui-icon>
        </div>
        <section class="hospitals">
            <div class="poi-action" (click)="displayBookmarkedHospitals()">
                <dtm-ui-icon name="first-aid-kit-line"></dtm-ui-icon>
            </div>

            <div class="bookmarked-hospital-list" *ngIf="vm.isBookmarkedHospitalsVisible">
                <!-- TODO: DTATS-103 -->
                <div class="element">{{ "Radom" }}</div>
                <div class="element">{{ "Lublin" }}</div>
                <div class="element">{{ "Warszawa" }}</div>

                <div class="element bookmarked" (click)="manageHospitalListPanel(true)">
                    <dtm-ui-icon name="star-fill"></dtm-ui-icon>
                </div>
            </div>
        </section>

        <div class="poi-action">
            <dtm-ui-icon name="complex-helicopter"></dtm-ui-icon>
        </div>
        <div class="poi-action">
            <dtm-ui-icon name="collage"></dtm-ui-icon>
        </div>

        <dats-lib-alert-badges></dats-lib-alert-badges>
    </section>
    <section class="flights-panel-wrapper">
        <dats-lib-flights-panel
            [selectedTab]="vm.selectedTab"
            [tabs]="vm.flightsTabList"
            [isProcessing]="vm.isPanelProcessing"
            [isExpanded]="vm.isPanelExpanded"
            [isAtcController]="isAtcController"
            [categorizedFlights]="vm.categorizedFlights"
            [selectedFlightId]="vm.selectedFlightId"
            (selectedTabUpdate)="updateSelectedTab($event)"
            (selectionClear)="clearSelection()"
            (panelClose)="closePanel()"
            (checkinModify)="handleCheckinAction($event)"
            (confirmationResend)="resendConfirmation($event)"
            (selectedFlightChanged)="selectFlight($event, 'list')"
        ></dats-lib-flights-panel>
    </section>
    <section class="action-sidebar" *ngrxLet="checkinAction$ as checkinAction">
        <dats-lib-checkin-action-sidebar
            [headerText]="'datsLibDashboard.flightsContainer.actionSidebarHeader' | transloco : { value: checkinAction.type }"
            [confirmButtonType]="checkinAction.type === CheckinActionType.Standby ? ButtonTheme.Warn : ButtonTheme.Primary"
            [confirmButtonText]="'datsLibDashboard.flightsContainer.checkinActionButtonLabel' | transloco : { value: vm.actionButtonState }"
            [isOpen]="vm.isActionSidebarOpen"
            [shouldDisplayRestoreButton]="checkinAction.type === CheckinActionType.Modify && (hasModifyFormChanged$ | ngrxPush)"
            [isProcessing]="isActionProcessing$ | ngrxPush"
            (actionCancel)="closeActionSidebar(checkinAction.type === CheckinActionType.Modify)"
            (actionConfirm)="applyCheckinAction()"
            (restore)="restoreFormValues()"
        >
            <ng-container [formGroup]="modifyCheckinFormGroup" *ngrxLet="flightOperation$ as flightOperation">
                <dats-lib-standby-checkin-form
                    *ngIf="checkinAction.type === CheckinActionType.Standby"
                    formControlName="standbyDuration"
                    [flightOperation]="flightOperation"
                    (isTimeExceededChange)="setActionButtonState($event, vm.actionButtonState)"
                ></dats-lib-standby-checkin-form>
                <dats-lib-modify-checkin-form
                    *ngIf="checkinAction.type === CheckinActionType.Modify"
                    formControlName="modifyCheckin"
                    [flightOperation]="flightOperation"
                ></dats-lib-modify-checkin-form>
            </ng-container>
        </dats-lib-checkin-action-sidebar>
    </section>
    <section class="action-sidebar">
        <dats-lib-checkin-action-sidebar
            [headerText]="'datsLibDashboard.flightsContainer.hospitalsSidebarTitle' | transloco"
            [isOpen]="vm.isHospitalListOpen"
            (actionCancel)="manageHospitalListPanel(false)"
            (actionConfirm)="dispatchHospitalList()"
        >
            <dats-lib-bookmarked-hospital-list
                [hospitalList]="hospitalList$ | ngrxPush"
                (bookmarkedHospitalsChange)="setBookmarkedHospitalList($event)"
                *ngIf="vm.isHospitalListOpen"
            ></dats-lib-bookmarked-hospital-list>
        </dats-lib-checkin-action-sidebar>
    </section>
</ng-container>
