<ng-container
    *ngrxLet="{
        isMainPanelExpanded: isMainPanelExpanded$,
        tabs: tabs$,
        selectedTab: selectedTab$,
        isProcessing: isProcessing$,
        isAtcController: isAtcController$,
        categorizedFlights: categorizedFlights$,
        chipFilters: chipFilters$,
        appliedFilters: appliedFilters$,
        selectedFlightId: selectedFlightId$,
        lastSelectedFlight: lastSelectedFlight$
    } as vm"
>
    <div [@foldLeft]="vm.isMainPanelExpanded ? 'open' : 'closed'" class="panel" (@foldLeft.done)="handleFoldAnimationDone($event)">
        <div class="flights-wrapper" #flightsPanelWrapper>
            <dtm-ui-loader-container [isShown]="vm.isProcessing">
                <div class="flights-list">
                    <div class="header">
                        <button type="button" class="close-button button-icon" (click)="closePanel()">
                            <dtm-ui-icon name="close"></dtm-ui-icon>
                        </button>
                        {{ "datsLibDashboard.flightsPanel.header" | transloco : { value: vm.selectedTab?.type } }}
                        <dtm-ui-icon
                            *ngIf="vm.selectedTab?.type === FlightsTabType.UTM_ATC || vm.selectedTab?.type === FlightsTabType.UTM_FIS"
                            [name]="vm.selectedTab?.isActionRequired ? 'notification-2-fill' : 'notification'"
                            [ngClass]="{ 'action-required': vm.selectedTab?.isActionRequired }"
                        ></dtm-ui-icon>
                    </div>
                    <dats-lib-flights-filters
                        [chipFilters]="vm.chipFilters"
                        [totalCount]="vm.selectedTab?.total"
                        selectAllValueLabel="datsLibDashboard.flightsPanel.allFiltersLabel"
                        [isMultiple]="true"
                        (filtersChange)="applyFilters($event)"
                    ></dats-lib-flights-filters>
                    <ng-container *ngIf="vm.categorizedFlights">
                        <ng-container *ngFor="let list of vm.categorizedFlights | keyvalue">
                            <dats-lib-category-flight-list
                                *ngIf="list?.value?.length"
                                [flights]="list.value"
                                [category]="list.key | toType : FlightCategory"
                                [isAtcController]="vm.isAtcController"
                                [filters]="vm.appliedFilters"
                                [selectedFlightId]="vm.selectedFlightId"
                                (confirmationResend)="confirmationResend.emit($event)"
                                (checkinModify)="checkinModify.emit($event)"
                                (selectedFlightChanged)="selectedFlightChanged.emit($event)"
                            >
                            </dats-lib-category-flight-list>
                        </ng-container>
                    </ng-container>
                </div>
            </dtm-ui-loader-container>
        </div>

        <ul>
            <li *ngFor="let tab of vm.tabs">
                <dats-lib-flights-tab-item
                    [isSelected]="tab.type === vm.selectedTab?.type"
                    [flightsTab]="tab"
                    (click)="selectTab(tab)"
                ></dats-lib-flights-tab-item>
            </li>
        </ul>
    </div>

    <div
        [@foldLeft]="!vm.isMainPanelExpanded && vm.lastSelectedFlight && vm.selectedFlightId ? 'open' : 'closed'"
        class="single-flight-panel"
    >
        <dats-lib-flight-item
            *ngIf="vm.lastSelectedFlight"
            [flight]="vm.lastSelectedFlight"
            [category]="vm.lastSelectedFlight.category"
            [isAtcController]="vm.isAtcController"
            [isSelected]="false"
            (confirmationResend)="confirmationResend.emit($event)"
            (checkinModify)="checkinModify.emit($event)"
        ></dats-lib-flight-item>
    </div>
</ng-container>
