import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule } from "@jsverse/transloco";

const MAX_DESCRIPTION_LENGTH = 200;

@Component({
    selector: "dats-lib-archive-dialog.component",
    standalone: true,
    imports: [CommonModule, SharedI18nModule, SharedUiModule, TranslocoModule, MatDialogModule, ReactiveFormsModule, MatInputModule],
    templateUrl: "./archive-dialog.component.html",
    styleUrls: ["./archive-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveDialogComponent {
    private readonly dialogRef = inject(MatDialogRef<ArchiveDialogComponent>);
    // TODO: DTATS-144 integration with backend with system translation or only front
    protected readonly archiveOptions = [
        {
            id: "datsLibFlights.archiveConfirmationDialog.pilotCallTemplate",
            description: "Call from the pilot. Some reason given by the controller.",
        },
    ];

    protected readonly form = new FormGroup({
        id: new FormControl(this.archiveOptions[0].id),
        description: new FormControl(this.archiveOptions[0].description, {
            validators: [Validators.required, Validators.maxLength(MAX_DESCRIPTION_LENGTH)],
        }),
    });

    protected submit() {
        if (this.form.invalid) {
            this.form.markAsTouched();

            return;
        }
        this.dialogRef.close(this.form.value);
    }
}
