import { AlertBadgeType } from "../models/alerts.model";
import { AirspaceType, FlightCategory, FlightsTabType } from "../models/flight.models";
import { FlightFilterType } from "../models/flights-filters.models";

export const ATC_CONTROLLER_TABS: FlightsTabType[] = [FlightsTabType.UTM_ATC, FlightsTabType.AtcAccepted, FlightsTabType.SystemAccepted];
export const FIS_INFORMER_TABS: FlightsTabType[] = [FlightsTabType.UTM_FIS, FlightsTabType.FIS, FlightsTabType.TWR, FlightsTabType.ATZ];

export const UTM_ATC_CATEGORIES: FlightCategory[] = [
    FlightCategory.Pending_112,
    FlightCategory.Emergency,
    FlightCategory.Stop,
    FlightCategory.Pending,
    FlightCategory.Standby,
];
export const UTM_FIS_CATEGORIES: FlightCategory[] = [FlightCategory.Emergency, FlightCategory.Stop];
export const ATC_ACCEPTED_CATEGORIES: FlightCategory[] = [FlightCategory.AcceptedATC, FlightCategory.OverdueATC];
export const SYSTEM_ACCEPTED_CATEGORIES: FlightCategory[] = [FlightCategory.AcceptedSystem, FlightCategory.OverdueSystem];
export const FIS_CATEGORIES: FlightCategory[] = [FlightCategory.OverdueATC, FlightCategory.AcceptedATC];
export const FIS_ZONES_CATEGORIES: FlightCategory[] = [
    FlightCategory.Emergency,
    FlightCategory.Stop,
    FlightCategory.OverdueATC,
    FlightCategory.OverdueSystem,
    FlightCategory.AcceptedATC,
    FlightCategory.AcceptedSystem,
];

export const AVAILABLE_CATEGORY_FILTERS: FlightFilterType[] = [
    FlightCategory.Emergency,
    FlightCategory.Stop,
    FlightCategory.Pending,
    FlightCategory.Standby,
    FlightCategory.AcceptedATC,
    FlightCategory.AcceptedSystem,
    FlightCategory.AcceptedOther,
    FlightCategory.CompletedATC,
    FlightCategory.CompletedSystem,
    FlightCategory.CompletedOther,
    FlightCategory.Rejected,
    FlightCategory.OverdueATC,
    FlightCategory.OverdueSystem,
    FlightCategory.OverdueOther,
    FlightCategory.Standby,
    FlightCategory.Canceled,
    FlightCategory.Other,
];

export const AVAILABLE_AIRSPACE_TYPE_FILTERS = [AirspaceType.CTR, AirspaceType.MCTR];

export const ALERT_BADGES = [
    {
        type: AlertBadgeType.NoFlyZone,
        class: "no-fly-zone",
        icon: "forbid",
    },
    {
        type: AlertBadgeType.Message,
        class: "message",
        icon: "mail-send",
    },
    {
        type: AlertBadgeType.Modification,
        class: "modification",
        icon: "equalizer-fill",
    },
    {
        type: AlertBadgeType.NotStarted,
        class: "not-started",
        icon: "time",
    },
] as const;
