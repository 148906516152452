import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AlertType } from "../../models/alert.models";

interface AlertGeometryPanelComponentState {
    selectedAlertType: AlertType | undefined;
}

const FADE_ANIMATION_DURATION = 200;

@UntilDestroy()
@Component({
    selector: "dats-lib-alert-geometry-panel",
    templateUrl: "./alert-geometry-panel.component.html",
    styleUrls: ["./alert-geometry-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.fadeAnimation(FADE_ANIMATION_DURATION), AnimationUtils.slideInSideOffScreenUp(FADE_ANIMATION_DURATION)],
})
export class AlertGeometryPanelComponent {
    protected readonly AlertType = AlertType;

    @Input({ required: true }) public set selectedAlertType(value: AlertType | undefined) {
        this.localStore.patchState({ selectedAlertType: value });
    }

    protected readonly selectedAlertType$ = this.localStore.selectByKey("selectedAlertType");

    @Output() public readonly alertTypeChange = new EventEmitter<AlertType | undefined>();

    constructor(private readonly localStore: LocalComponentStore<AlertGeometryPanelComponentState>) {
        this.localStore.setState({
            selectedAlertType: undefined,
        });
    }
}
