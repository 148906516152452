<ng-container *ngrxLet="{ flight: flight$, heightSliderOptions: heightSliderOptions$ } as vm">
    <dats-lib-action-sidebar-header
        [headerText]="
            'datsLibFlights.modifyCheckinForm.actionSidebarHeader' | transloco : { phaseType: vm.flight.acceptance.phase | lowercase }
        "
        (actionCancel)="cancel()"
    >
    </dats-lib-action-sidebar-header>
    <div class="action-sidebar-content">
        <span class="section-header">{{ "datsLibFlights.modifyCheckinForm.modifyTimeHeader" | transloco }}</span>
        <section class="mission-time">
            <dtm-ui-label-value [label]="'datsLibFlights.modifyCheckinForm.startTimeValueLabel' | transloco">
                <div
                    class="utc-time-wrapper"
                    [matTooltip]="
                        vm.flight.operation.plannedStartAt | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }
                    "
                >
                    <dtm-ui-icon name="time-utc"></dtm-ui-icon>
                    {{ vm.flight.operation.plannedStartAt | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}
                </div>
            </dtm-ui-label-value>

            <dtm-ui-label-value [label]="'datsLibFlights.modifyCheckinForm.finishTimeValueLabel' | transloco">
                <div
                    class="utc-time-wrapper"
                    [matTooltip]="
                        vm.flight.operation.plannedEndAt | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }
                    "
                >
                    <dtm-ui-icon name="time-utc"></dtm-ui-icon>
                    {{ vm.flight.operation.plannedEndAt | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}
                </div>
            </dtm-ui-label-value>
        </section>
        <div class="time-info" *ngIf="vm.flight.operation.mission?.endAt">
            <dtm-ui-icon name="error-warning-fill"></dtm-ui-icon>
            {{
                "datsLibFlights.operationDetails.timeInfo"
                    | transloco
                        : {
                              time: vm.flight.operation.mission?.endAt | localizeDate : { timeStyle: "short", timeZone: "UTC" },
                              date: vm.flight.operation.mission?.endAt | localizeDate : { dateStyle: "short", timeZone: "UTC" }
                          }
            }}
        </div>
        <div class="time-range">
            <dtm-ui-time-field
                *ngrxLet="{
                    minTime: vm.flight.operation.plannedStartAt,
                    maxTime: vm.flight.operation.plannedEndAt | invoke : getStartTimeMax : vm.flight.operation.mission?.endAt
                } as start"
                [isUtcTime]="true"
                [minTime]="start.minTime"
                [maxTime]="start.maxTime"
                [formControl]="modifyForm.controls.startAt"
                (valueChange)="updateDelayAndFlightTime($event)"
            >
                <label>{{ "datsLibFlights.modifyCheckinForm.startTimeLabel" | transloco }} </label>
                <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.startAt; name: 'min'">
                    {{
                        "datsLibFlights.modifyCheckinForm.startTimeMinErrorMessage"
                            | transloco
                                : {
                                      time: start.minTime | localizeDate : { timeStyle: "short", timeZone: "UTC" }
                                  }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.startAt; name: 'max'">
                    {{
                        "datsLibFlights.modifyCheckinForm.startTimeMaxErrorMessage"
                            | transloco
                                : {
                                      time: start.maxTime | localizeDate : { timeStyle: "short", timeZone: "UTC" }
                                  }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.startAt; name: 'timeInPast'">
                    {{ "datsLibFlights.modifyCheckinForm.timeInPastErrorMessage" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="modifyForm; name: 'startTimeExceeded'">
                    {{ "datsLibFlights.modifyCheckinForm.startTimeExceededErrorMessage" | transloco }}
                </div>
            </dtm-ui-time-field>
            <dtm-ui-time-field
                *ngrxLet="{
                    minTime: vm.flight.operation.plannedStartAt | invoke : getFinishTimeMin,
                    maxTime: vm.flight.operation.plannedEndAt | invoke : getFinishTimeMax : vm.flight.operation.mission?.endAt
                } as finish"
                [isUtcTime]="true"
                [minTime]="finish.minTime"
                [maxTime]="finish.maxTime"
                [formControl]="modifyForm.controls.finishAt"
                (valueChange)="updateFlightTime($event)"
            >
                <label>{{ "datsLibFlights.modifyCheckinForm.finishTimeLabel" | transloco }} </label>
                <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.finishAt; name: 'min'">
                    {{
                        "datsLibFlights.modifyCheckinForm.finishMinTimeError"
                            | transloco
                                : {
                                      time: finish.minTime | localizeDate : { timeStyle: "short", timeZone: "UTC" }
                                  }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.finishAt; name: 'max'">
                    {{
                        "datsLibFlights.modifyCheckinForm.finishMaxTimeError"
                            | transloco
                                : {
                                      time: finish.maxTime | localizeDate : { timeStyle: "short", timeZone: "UTC" }
                                  }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.finishAt; name: 'timeInPast'">
                    {{ "datsLibFlights.modifyCheckinForm.timeInPastErrorMessage" | transloco }}
                </div>
            </dtm-ui-time-field>
        </div>

        <dtm-ui-expanded-number-input
            [formControl]="modifyForm.controls.flightTime"
            [minValue]="0"
            [stepValue]="10"
            [valueSuffix]="'datsLibFlights.standbyCheckinForm.controlSuffix' | transloco"
            (valueChange)="updateEndTime($event)"
        >
            <label>{{ "datsLibFlights.modifyCheckinForm.changeFlightTimeLabel" | transloco }}</label>
        </dtm-ui-expanded-number-input>

        <dtm-ui-expanded-number-input
            *ngIf="modifyForm.controls.delayStart.enabled"
            [formControl]="modifyForm.controls.delayStart"
            [minValue]="0"
            [stepValue]="10"
            [valueSuffix]="'datsLibFlights.standbyCheckinForm.controlSuffix' | transloco"
            (valueChange)="updateTime($event)"
        >
            <label>{{ "datsLibFlights.modifyCheckinForm.delayFlightLabel" | transloco }}</label>
        </dtm-ui-expanded-number-input>

        <span class="section-header">{{ "datsLibFlights.modifyCheckinForm.modifyHeightSectionHeader" | transloco }}</span>
        <dtm-ui-label-value
            [label]="'datsLibFlights.modifyCheckinForm.heightLabel' | transloco"
            [value]="
                'datsLibFlights.modifyCheckinForm.heightValue'
                    | transloco : { value: vm.flight.operation.geography.maxHeight | localizeNumber }
            "
        ></dtm-ui-label-value>

        <section class="modify-height">
            <!-- TODO: DTM-5197 - Replace ngx-slider after create custom slider component -->
            <ngx-slider
                (userChangeEnd)="modifyForm.controls.customHeight.setValue($event.value)"
                [formControl]="modifyForm.controls.height"
                class="dtm-slider-vertical-height"
                [options]="vm.heightSliderOptions"
            ></ngx-slider>
            <div class="custom-value-tiles">
                <div class="predefined-values">
                    <div
                        class="selectable-tile"
                        tabindex="0"
                        *ngFor="let value of PREDEFINED_HEIGHT_VALUES"
                        (click)="setHeightValue(value)"
                    >
                        <span class="selectable-tile-label">{{ value }}</span>
                    </div>
                </div>
                <dtm-ui-expanded-number-input
                    [formControl]="modifyForm.controls.customHeight"
                    [minValue]="0"
                    [stepValue]="1"
                    [stepValue]="10"
                >
                    <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.customHeight; name: 'max'; error as error">
                        {{ "datsLibFlights.modifyCheckinForm.maxHeightErrorMessage" | transloco : { value: error.max } }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.customHeight; name: 'min'; error as error">
                        {{ "datsLibFlights.modifyCheckinForm.minHeightErrorMessage" | transloco : { value: error.min } }}
                    </div>
                </dtm-ui-expanded-number-input>
            </div>
        </section>
    </div>
    <dats-lib-action-sidebar-footer (actionCancel)="cancel()" (actionConfirm)="confirm()">
        <button *ngIf="hasFormChanged$ | ngrxPush" type="button" class="button-secondary" (click)="restoreInitialData()">
            <dtm-ui-icon name="refresh"></dtm-ui-icon>
            {{ "datsLibFlights.actionSidebar.restoreButtonLabel" | transloco }}
        </button>
    </dats-lib-action-sidebar-footer>
</ng-container>
