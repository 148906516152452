import { GeoJSON, Page, UavType } from "@dtm-frontend/shared/ui";

export interface FlightsData {
    tabs: FlightsTab[];
    categorizedFlights?: CategorizedFlights;
}

export interface FlightsTab {
    type: FlightsTabType;
    total: number;
    isActionRequired: boolean;
}

export enum AlertType {
    NoFlyZone = "NO_FLY_ZONE",
    Message = "MESSAGE",
    Modification = "MODIFICATION",
}

export enum PoiType {
    Other = "OTHER",
    Airspace = "AIRSPACE",
    Hems = "HEMS",
    Hospital = "HOSPITAL",
    TakeoffSpot = "TAKEOFF_SPOT",
}

export interface AlertPointOfInterest {
    id: string;
    title: string;
    description: string;
    type: PoiType;
    isBookmarked: boolean;
    point: {
        data: string;
    };
}

export interface AlertPointOfInterestWithPages extends Page {
    content: AlertPointOfInterest[];
}

export interface AlertTextTemplate {
    id: string;
    content: string;
}

export interface AlertProposal {
    alertType: AlertType;
    textTemplates: AlertTextTemplate[];
}

export enum AlertAreaType {
    Poi = "POI",
    Wkb = "WKB",
    Zone = "Zone",
}

interface ZoneArea {
    "@type": AlertAreaType.Zone;
    designator: string;
}

interface PoiArea {
    "@type": AlertAreaType.Poi;
    id: string;
}

interface WkbArea {
    "@type": AlertAreaType.Wkb;
    polygon: GeoJSON;
}

export interface AlertEntity<TArea> {
    type: AlertType;
    scope: {
        area: TArea;
        startAt: string;
        finishAt: string;
    };
    text?: {
        content: string;
        template: AlertTextTemplate;
    };
}

export type ZoneAlertEntity = AlertEntity<ZoneArea>;
export type PoiAlertEntity = AlertEntity<PoiArea>;
export type WkbAlertEntity = AlertEntity<WkbArea>;

export type LocationAlertEntity = ZoneAlertEntity | PoiAlertEntity | WkbAlertEntity;

export enum CheckinActionType {
    Standby = "Standby",
    Modify = "Modify",
    Approve = "Approve",
    Stop = "Stop",
    Archive = "Archive",
}

export interface CheckinAction {
    id: string;
    type: CheckinActionType;
    flightOperation: FlightOperation;
}

export enum CheckinActionButtonState {
    Delay = "Delay",
    Reject = "Reject",
    Save = "Save",
}

export interface OperationDetails {
    plannedStartAt: Date;
    plannedEndAt: Date;
    maxHeight: number;
}

export enum FlightsTabType {
    UTM_ATC = "UTM_ATC",
    UTM_FIS = "UTM_FIS",
    AtcAccepted = "AtcAccepted",
    SystemAccepted = "SystemAccepted",
    FIS = "FIS",
    TWR = "TWR",
    ATZ = "ATZ",
    Mission = "Mission",
    Archive = "Archive",
}

export enum FlightCategory {
    Pending_112 = "Pending_112",
    Emergency = "Emergency",
    Stop = "Stop",
    Pending = "Pending",
    AcceptedATC = "AcceptedATC",
    AcceptedSystem = "AcceptedSystem",
    AcceptedOther = "AcceptedOther",
    CompletedATC = "CompletedATC",
    CompletedSystem = "CompletedSystem",
    CompletedOther = "CompletedOther",
    Rejected = "Rejected",
    OverdueATC = "OverdueATC",
    OverdueSystem = "OverdueSystem",
    OverdueOther = "OverdueOther",
    Standby = "Standby",
    Canceled = "Canceled",
    Other = "Other",
    Mission = "Mission",
}

export interface FlightItem {
    id: string;
    createdAt: Date;
    completedAt?: Date;
    acceptance: FlightAcceptance;
    progress: FlightProgress;
    modifications: FlightModification[];
    operation: FlightOperation;
    category: FlightCategory;
    is112?: boolean;
    isAirspaceTypeCTR?: boolean;
    isAirspaceTypeMCTR?: boolean;
}

export interface FlightAcceptance {
    phase?: FlightAcceptancePhase;
    isAcknowledged: boolean;
    type?: FlightAcceptanceType;
}

export enum FlightAcceptanceType {
    Other = "OTHER",
    System = "SYSTEM",
    ATC = "ATC",
}

export enum FlightAcceptancePhase {
    Uncompleted = "UNCOMPLETED",
    Pending = "PENDING",
    Canceled = "CANCELED",
    Standby = "STANDBY",
    Rejected = "REJECTED",
    Accepted = "ACCEPTED",
}

export interface FlightProgress {
    phase?: FlightProgressPhase;
    isAcknowledged: boolean;
}

export enum FlightProgressPhase {
    Uncompleted = "UNCOMPLETED",
    Emergency = "EMERGENCY",
    Stop = "STOP",
    Overdue = "OVERDUE",
    Completed = "COMPLETED",
}

export interface FlightModification {
    createdAt: Date;
    isAcknowledged: boolean;
    plannedStartAt: Date;
    plannedEndAt: Date;
    maxHeight: number;
}

export interface FlightOperation {
    plannedStartAt: Date;
    plannedEndAt: Date;
    isBvlos: boolean;
    mission?: FlightMission;
    pilot: Pilot;
    operator: Operator;
    uav: Uav;
    airspace: Airspace;
    geography: Geography;
    type: FlightOperationType;
}

export interface FlightMission {
    id: string;
    startAt: Date;
    endAt: Date;
    maxHeight: number;
}

export interface Pilot {
    firstName: string;
    lastName: string;
    mobile: string;
}

export interface Operator {
    name: string;
}

export interface Uav {
    model: string;
    type: UavType;
    isSwarm: boolean;
    takeOffMass?: number;
    takeOffMassLimit: number;
}

export interface Airspace {
    elements: AirspaceElement[];
}

export interface AirspaceElement {
    geozone: string;
    designator: string;
    type: string;
    tags: string[];
}

export interface Geography {
    latitude: number;
    longitude: number;
    maxHeight: number;
    radius: number;
}

export enum FlightOperationType {
    Standard = "STANDARD",
    Emergency112 = "EMERGENCY_112",
    Ghost112 = "GHOST_112",
}

export enum UavMass {
    VeryLight = "VeryLight",
    Light = "Light",
    MediumLight = "MediumLight",
    Medium = "Medium",
    Heavy = "Heavy",
}

export enum TagImportance {
    Red = "RED",
    Yellow = "YELLOW",
    Green = "GREEN",
    Blue = "BLUE",
}

export interface ModifyCheckinFormValues {
    height: number;
    flightTime: number | null;
    customHeight: number | null;
    delayStart: number | null;
    startAt: Date;
    finishAt: Date;
}

export interface ArchiveCheckinFormValues {
    id: string;
    description: string;
}

export enum FlightsErrorType {
    CannotGetFlights = "CannotGetFlights",
    CannotStandbyCheckin = "CannotStandbyCheckin",
    CannotRejectCheckin = "CannotRejectCheckin",
    CannotArchiveCheckin = "CannotArchiveCheckin",
    CannotResendCheckinConfirmation = "CannotResendCheckinConfirmation",
    CannotCreateLocationAlert = "CannotCreateLocationAlert",
    CannotGetAlertProposals = "CannotGetAlertProposals",
    CannotGetAlerts = "CannotGetAlerts",
    CannotGetPointsOfInterest = "CannotGetPointsOfInterest",
    CannotBookmarkHospitals = "CannotBookmarkHospitals",
}

export interface FlightsError {
    type: FlightsErrorType;
}

export type CategorizedFlights = Partial<Record<FlightCategory, FlightItem[]>>;

export enum AirspaceType {
    RPA = "RPA",
    CTR = "CTR",
    MCTR = "MCTR",
    ATZ = "ATZ",
}
