import { animate, state, style, transition, trigger } from "@angular/animations";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { IconDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { LetModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { AlertBadgeType } from "../../../models/alerts.model";
import { FlightsActions } from "../../../state/flights.actions";
import { FlightsState } from "../../../state/flights.state";
import { ALERT_BADGES } from "../../../utils/defaults";
import { AlertsListComponent } from "../alerts-list/alerts-list.component";

interface AlertBadgesComponentState {
    activeAlertBadge: AlertBadgeType | undefined;
}

@Component({
    selector: "dats-lib-alert-badges",
    standalone: true,
    imports: [CommonModule, IconDirective, LetModule, AlertsListComponent],
    templateUrl: "./alert-badges.component.html",
    styleUrls: ["../alert-badges.scss", "./alert-badges.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [
        trigger("slideInOut", [
            state("void", style({ transform: "translate(10%,-50%)", opacity: 0 })),
            state("*", style({ transform: "translate(0,-50%)", opacity: 1 })),
            transition(":enter", [animate("200ms ease-in-out")]),
            transition(":leave", [animate("200ms ease-in-out", style({ transform: "translate(10%,-50%)", opacity: 0 }))]),
        ]),
    ],
})
export class AlertBadgesComponent {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<AlertBadgesComponentState>);

    protected readonly activeAlertBadge$ = this.localStore.selectByKey("activeAlertBadge");
    protected readonly alerts$ = this.store.select(FlightsState.alerts).pipe(RxjsUtils.filterFalsy());

    protected readonly alertBadges = ALERT_BADGES;

    constructor() {
        this.localStore.setState({ activeAlertBadge: undefined });
        this.store.dispatch(new FlightsActions.GetAlerts());
    }

    protected setActiveAlertBadge(newAlertBadge: AlertBadgeType) {
        const activeAlertBadge = this.localStore.selectSnapshotByKey("activeAlertBadge");

        this.localStore.patchState({
            activeAlertBadge: newAlertBadge !== activeAlertBadge ? newAlertBadge : undefined,
        });
    }
}
