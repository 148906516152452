import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import {
    AirspaceElement,
    AirspaceType,
    CheckinAction,
    CheckinActionType,
    FlightAcceptancePhase,
    FlightCategory,
    FlightItem,
    FlightOperationType,
    FlightProgressPhase,
} from "../../models/flight.models";

interface FlightItemComponentState {
    flight: FlightItem | undefined;
    category: FlightCategory | undefined;
    isAtcController: boolean;
    isSelected: boolean;
}

@Component({
    selector: "dats-lib-flight-item",
    templateUrl: "./flight-item.component.html",
    styleUrls: ["./flight-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightItemComponent {
    @Input({ required: true }) public set flight(value: FlightItem | undefined) {
        this.localStore.patchState({ flight: value });
    }

    @Input({ required: true }) public set category(value: FlightCategory | undefined) {
        this.localStore.patchState({ category: value });
    }

    @Input() public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });
    }

    @Input() public set isAtcController(value: BooleanInput) {
        this.localStore.patchState({ isAtcController: coerceBooleanProperty(value) });
    }

    @Output() public readonly confirmationResend = new EventEmitter<string>();
    @Output() public readonly checkinModify = new EventEmitter<CheckinAction>();
    @Output() public readonly selectionChanged = new EventEmitter<boolean>();

    public get flightId(): string | undefined {
        return this.localStore.selectSnapshotByKey("flight")?.id;
    }

    @HostBinding("class") protected get categoryClassName() {
        return this.getCategoryClassName(this.localStore.selectSnapshotByKey("category"));
    }
    @HostBinding("class.selected") protected get selectedClassName() {
        return this.localStore.selectSnapshotByKey("isSelected");
    }
    @HostListener("click")
    protected onSelectionChanged() {
        this.selectionChanged.emit(!this.localStore.selectSnapshotByKey("isSelected"));
    }

    protected readonly flight$ = this.localStore.selectByKey("flight").pipe(RxjsUtils.filterFalsy());
    protected readonly category$ = this.localStore.selectByKey("category").pipe(RxjsUtils.filterFalsy());
    protected readonly isCheckinDataAcknowledged$ = this.flight$.pipe(
        map((flight) => {
            if (flight.modification) {
                return flight.modification.modified.isAcknowledged;
            }

            return true;
        })
    );
    protected readonly isAtcController$ = this.localStore.selectByKey("isAtcController");
    protected readonly FlightProgressPhase = FlightProgressPhase;
    protected readonly FlightAcceptancePhase = FlightAcceptancePhase;
    protected readonly AirspaceType = AirspaceType;
    protected readonly FlightCategory = FlightCategory;
    protected readonly CheckinActionType = CheckinActionType;
    protected readonly FlightOperationType = FlightOperationType;

    constructor(private readonly localStore: LocalComponentStore<FlightItemComponentState>, private readonly element: ElementRef) {
        this.localStore.setState({
            flight: undefined,
            category: undefined,
            isAtcController: false,
            isSelected: false,
        });
    }

    public scrollIntoView(): void {
        this.element.nativeElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    protected openCheckinDetails(): void {
        const flight = this.localStore.selectSnapshotByKey("flight");
        if (!flight) {
            return;
        }

        // TODO: DTATS-139 Open checkin details
        alert("DTATS-139 Open checkin details");
    }

    protected isCheckinNotCompleted(flightCategory: FlightCategory) {
        return (
            flightCategory !== FlightCategory.CompletedATC &&
            flightCategory !== FlightCategory.CompletedSystem &&
            flightCategory !== FlightCategory.CompletedOther
        );
    }

    protected isCheckinAccepted(flightCategory: FlightCategory) {
        return (
            flightCategory === FlightCategory.AcceptedATC ||
            flightCategory === FlightCategory.AcceptedSystem ||
            flightCategory === FlightCategory.AcceptedOther
        );
    }

    protected modifyCheckin(checkinActionType: CheckinActionType): void {
        const flight = this.localStore.selectSnapshotByKey("flight");
        if (!flight) {
            return;
        }

        this.checkinModify.emit({
            type: checkinActionType,
            flight,
            id: flight.id,
        });
    }

    protected isProgressBarVisible(category: FlightCategory) {
        return [
            FlightCategory.AcceptedATC,
            FlightCategory.AcceptedSystem,
            FlightCategory.AcceptedOther,
            FlightCategory.OverdueATC,
            FlightCategory.OverdueSystem,
            FlightCategory.OverdueOther,
            FlightCategory.Stop,
            FlightCategory.Emergency,
        ].includes(category);
    }

    private getCategoryClassName(category: FlightCategory | undefined): string {
        switch (category) {
            case FlightCategory.Pending_112:
                return "category-pending-112";
            case FlightCategory.Emergency:
                return "category-emergency";
            case FlightCategory.Stop:
                return "category-stop";
            case FlightCategory.Pending:
                return "category-pending";
            case FlightCategory.AcceptedATC:
                return "category-atc-accepted";
            case FlightCategory.AcceptedSystem:
                return "category-system-accepted";
            case FlightCategory.CompletedATC:
                return "category-atc-completed";
            case FlightCategory.CompletedSystem:
                return "category-system-completed";
            case FlightCategory.OverdueATC:
            case FlightCategory.OverdueSystem:
            case FlightCategory.OverdueOther:
                return "category-overdue";
            default:
                return "default";
        }
    }

    protected getAirspaceTypeElements(flight: FlightItem, airspaceTypes: (string | AirspaceType)[]): AirspaceElement[] | null {
        const airspaceElements: AirspaceElement[] = flight.operation?.airspace?.elements;

        if (!airspaceElements?.length) {
            return null;
        }

        return airspaceElements.filter((element) => airspaceTypes.includes(element.type));
    }
}
