import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "dats-lib-confirmation-bar",
    templateUrl: "./confirmation-bar.component.html",
    styleUrls: ["./confirmation-bar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationBarComponent {
    @Output() public readonly resend = new EventEmitter<void>();
}
