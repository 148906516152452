import {
    AlertPointOfInterest,
    AlertType,
    ArchiveCheckinFormValues,
    FlightsTabType,
    ModifyCheckinFormValues,
    PoiAlertEntity,
    WkbAlertEntity,
    ZoneAlertEntity,
} from "../models/flight.models";

export namespace FlightsActions {
    export class StandbyCheckin {
        public static readonly type = "[Flights] Standby checkin";
        constructor(public checkinId: string, public duration: number) {}
    }

    export class ModifyCheckinAndAccept {
        public static readonly type = "[Flights] Modify checkin and accept";
        constructor(public checkinId: string, public formValues: ModifyCheckinFormValues) {}
    }

    export class AcceptCheckin {
        public static readonly type = "[Flights] Accept checkin";
        constructor(public checkinId: string) {}
    }

    export class RejectCheckin {
        public static readonly type = "[Flights] Reject checkin";
        constructor(public checkinId: string) {}
    }

    export class StopCheckin {
        public static readonly type = "[Flights] Stop checkin";
        constructor(public checkinId: string) {}
    }

    export class ArchiveCheckin {
        public static readonly type = "[Flights] Archive checkin";
        constructor(public checkinId: string, public formValues: ArchiveCheckinFormValues) {}
    }

    export class ResendCheckinConfirmation {
        public static readonly type = "[Flights] Resend checkin confirmation";
        constructor(public checkinId: string) {}
    }

    export class GetFlights {
        public static readonly type = "[Flights] Get flights";
        constructor(public isAtcController: boolean, public tabType?: FlightsTabType) {}
    }

    export class ClearCategorizedFlights {
        public static readonly type = "[Flights] Clear categorized flights";
    }

    export class GetAlerts {
        public static readonly type = "[Flights] Get alerts";
    }

    export class GetAlertProposals {
        public static readonly type = "[Flights] Get alert proposals";
        constructor(public type: AlertType, public poiId?: string, public designator?: string) {}
    }

    export class CreateZoneAlerts {
        public static readonly type = "[Flights] Create Zone alerts";
        constructor(public zoneAlertEntity: ZoneAlertEntity) {}
    }

    export class CreatePoiAlerts {
        public static readonly type = "[Flights] Create POI alerts";
        constructor(public poiAlertEntity: PoiAlertEntity) {}
    }

    export class CreateWkbAlerts {
        public static readonly type = "[Flights] Create WKB alerts";
        constructor(public poiAlertEntity: WkbAlertEntity) {}
    }

    export class GetAllHospitals {
        public static readonly type = "[Flights] Get all hospitals";
    }

    export class SetBookmarkedHospitals {
        public static readonly type = "[Flights] Set bookmarked hospitals";
        constructor(public newBookmarkedHospitals: AlertPointOfInterest[]) {}
    }

    export class SelectFlight {
        public static readonly type = "[Flights] Select flight";
        constructor(public flightId: string | undefined) {}
    }
}
