import { Injectable, inject } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, catchError, finalize, tap } from "rxjs";
import { AlertError, AlertPointOfInterest, AlertProposal, AlertType, CategorizedAlertsByBadge, PoiType } from "../models/alert.models";
import { AlertApiService } from "../services/alert-api.service";
import { AlertActions } from "./alert.actions";

export interface AlertStateModel {
    error: AlertError | undefined;
    isActionProcessing: boolean;
    selectedAlertType: AlertType | undefined;
    alerts: CategorizedAlertsByBadge | undefined;
    alertProposal: AlertProposal | undefined;
    hospitalList: AlertPointOfInterest[] | undefined;
}

const defaultState: AlertStateModel = {
    error: undefined,
    isActionProcessing: false,
    selectedAlertType: undefined,
    alerts: undefined,
    alertProposal: undefined,
    hospitalList: undefined,
};

@State<AlertStateModel>({
    name: "alert",
    defaults: defaultState,
})
@Injectable()
export class AlertState {
    private alertApi = inject(AlertApiService);

    @Selector()
    public static error(state: AlertStateModel): AlertError | undefined {
        return state.error;
    }

    @Selector()
    public static isActionProcessing(state: AlertStateModel): boolean {
        return state.isActionProcessing;
    }

    @Selector()
    public static selectedAlertType(state: AlertStateModel): AlertType | undefined {
        return state.selectedAlertType;
    }

    @Selector()
    public static alerts(state: AlertStateModel) {
        return state.alerts;
    }

    @Selector()
    public static alertProposals(state: AlertStateModel): AlertProposal | undefined {
        return state.alertProposal;
    }

    @Selector()
    public static hospitalList(state: AlertStateModel): AlertPointOfInterest[] | undefined {
        return state.hospitalList;
    }

    @Action(AlertActions.SelectAlertType)
    public selectAlertType(context: StateContext<AlertStateModel>, action: AlertActions.SelectAlertType) {
        context.patchState({ selectedAlertType: action.type });
    }

    @Action(AlertActions.GetAlerts)
    public getAlerts(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, alertProposal: undefined, isActionProcessing: true });

        return this.alertApi.getAlerts().pipe(
            tap((alerts) => {
                context.patchState({ alerts });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAlertProposals)
    public getAlertProposals(context: StateContext<AlertStateModel>, action: AlertActions.GetAlertProposals) {
        context.patchState({ error: undefined, alertProposal: undefined, isActionProcessing: true });

        return this.alertApi.getAlertProposals(action.type, action.poiId, action.designator).pipe(
            tap((alertProposal) => {
                context.patchState({ alertProposal });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.CreateZoneAlerts)
    public createZoneAlerts(context: StateContext<AlertStateModel>, action: AlertActions.CreateZoneAlerts) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.createLocationAlert(action.zoneAlertEntity).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.CreatePoiAlerts)
    public createPoiAlerts(context: StateContext<AlertStateModel>, action: AlertActions.CreatePoiAlerts) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.createLocationAlert(action.poiAlertEntity).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.CreateWkbAlerts)
    public createWkbAlerts(context: StateContext<AlertStateModel>, action: AlertActions.CreateWkbAlerts) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.createLocationAlert(action.poiAlertEntity).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllHospitals)
    public getPoiList(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(PoiType.Hospital).pipe(
            tap(({ content: hospitalList }) => {
                context.patchState({
                    hospitalList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.SetBookmarkedHospitals)
    public setBookmarkedHospitals(context: StateContext<AlertStateModel>, action: AlertActions.SetBookmarkedHospitals) {
        const bookmarkedHospitals = context.getState().hospitalList;

        if (!bookmarkedHospitals) {
            return;
        }

        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.setBookmarkedHospitals(bookmarkedHospitals, action.newBookmarkedHospitals).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }
}
