<div class="modify-checkin" *ngrxLet="{ operationDetails: operationDetails$ } as vm">
    <span class="section-header">{{ "datsLibDashboard.modifyCheckinForm.modifyTimeHeader" | transloco }}</span>
    <section class="mission-time">
        <dtm-ui-label-value [label]="'datsLibDashboard.modifyCheckinForm.startTimeValueLabel' | transloco">
            <div
                class="utc-time-wrapper"
                [matTooltip]="
                    vm.operationDetails.plannedStartAt | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }
                "
            >
                <dtm-ui-icon name="time-utc"></dtm-ui-icon>
                {{ vm.operationDetails.plannedStartAt | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}
            </div>
        </dtm-ui-label-value>

        <dtm-ui-label-value [label]="'datsLibDashboard.modifyCheckinForm.finishTimeValueLabel' | transloco">
            <div
                class="utc-time-wrapper"
                [matTooltip]="
                    vm.operationDetails.plannedEndAt | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }
                "
            >
                <dtm-ui-icon name="time-utc"></dtm-ui-icon>
                {{ vm.operationDetails.plannedEndAt | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}
            </div>
        </dtm-ui-label-value>
    </section>
    <div class="time-range">
        <dtm-ui-time-field
            [isUtcTime]="true"
            [minTime]="vm.operationDetails.plannedStartAt"
            [maxTime]="vm.operationDetails.plannedEndAt | invoke : getStartTimeMax"
            [formControl]="modifyForm.controls.startAt"
            (valueChange)="updateDelayAndFlightTime($event)"
        >
            <label>{{ "datsLibDashboard.modifyCheckinForm.startTimeLabel" | transloco }} </label>
            <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.startAt; name: 'min'">
                {{
                    "datsLibDashboard.modifyCheckinForm.startTimeMinErrorMessage"
                        | transloco : { time: vm.operationDetails.plannedStartAt | localizeDate : { timeStyle: "short", timeZone: "UTC" } }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.startAt; name: 'max'">
                {{
                    "datsLibDashboard.modifyCheckinForm.startTimeMaxErrorMessage"
                        | transloco
                            : {
                                  time:
                                      vm.operationDetails.plannedEndAt
                                      | invoke : getStartTimeMax
                                      | localizeDate : { timeStyle: "short", timeZone: "UTC" }
                              }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.startAt; name: 'startTimeExceeded'">
                {{ "datsLibDashboard.modifyCheckinForm.startTimeExceededErrorMessage" | transloco }}
            </div>
        </dtm-ui-time-field>
        <dtm-ui-time-field
            [isUtcTime]="true"
            [minTime]="vm.operationDetails.plannedStartAt | invoke : getFinishTimeMin"
            [maxTime]="vm.operationDetails.plannedEndAt"
            [formControl]="modifyForm.controls.finishAt"
            (valueChange)="updateFlightTime($event)"
        >
            <label>{{ "datsLibDashboard.modifyCheckinForm.finishTimeLabel" | transloco }} </label>
            <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.finishAt; name: 'min'">
                {{
                    "datsLibDashboard.modifyCheckinForm.finishMinTimeError"
                        | transloco
                            : {
                                  time:
                                      vm.operationDetails.plannedStartAt
                                      | invoke : getFinishTimeMin
                                      | localizeDate : { timeStyle: "short", timeZone: "UTC" }
                              }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.finishAt; name: 'max'">
                {{
                    "datsLibDashboard.modifyCheckinForm.finishMaxTimeError"
                        | transloco : { time: vm.operationDetails.plannedEndAt | localizeDate : { timeStyle: "short", timeZone: "UTC" } }
                }}
            </div>
        </dtm-ui-time-field>
    </div>

    <dtm-ui-expanded-number-input
        [formControl]="modifyForm.controls.flightTime"
        [minValue]="0"
        [stepValue]="1"
        [valueSuffix]="'datsLibDashboard.standbyCheckinForm.controlSuffix' | transloco"
        (valueChange)="updateEndTime($event)"
    >
        <label>{{ "datsLibDashboard.modifyCheckinForm.changeFlightTimeLabel" | transloco }}</label>
    </dtm-ui-expanded-number-input>

    <dtm-ui-expanded-number-input
        *ngIf="modifyForm.controls.delayStart.enabled"
        [formControl]="modifyForm.controls.delayStart"
        [minValue]="0"
        [stepValue]="1"
        [valueSuffix]="'datsLibDashboard.standbyCheckinForm.controlSuffix' | transloco"
        (valueChange)="updateStartTime($event)"
    >
        <label>{{ "datsLibDashboard.modifyCheckinForm.delayFlightLabel" | transloco }}</label>
    </dtm-ui-expanded-number-input>

    <span class="section-header">{{ "datsLibDashboard.modifyCheckinForm.modifyHeightSectionHeader" | transloco }}</span>
    <dtm-ui-label-value
        [label]="'datsLibDashboard.modifyCheckinForm.heightLabel' | transloco"
        [value]="'datsLibDashboard.modifyCheckinForm.heightValue' | transloco : { value: vm.operationDetails.maxHeight | localizeNumber }"
    ></dtm-ui-label-value>

    <section class="modify-height">
        <!-- TODO: DTM-5197 - Replace ngx-slider after create custom slider component -->
        <ngx-slider
            (userChangeEnd)="modifyForm.controls.customHeight.setValue($event.value)"
            [formControl]="modifyForm.controls.height"
            class="dtm-slider-vertical-height"
            [options]="HEIGHT_SLIDER_OPTIONS"
        ></ngx-slider>
        <div class="custom-value-tiles">
            <div class="predefined-values">
                <div class="selectable-tile" tabindex="0" *ngFor="let value of PREDEFINED_HEIGHT_VALUES" (click)="setHeightValue(value)">
                    <span class="selectable-tile-label">{{ value }}</span>
                </div>
            </div>
            <dtm-ui-expanded-number-input [formControl]="modifyForm.controls.customHeight" [minValue]="0" [stepValue]="1">
                <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.customHeight; name: 'max'; error as error">
                    {{ "datsLibDashboard.modifyCheckinForm.maxHeightErrorMessage" | transloco : { value: error.max } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="modifyForm.controls.customHeight; name: 'min'; error as error">
                    {{ "datsLibDashboard.modifyCheckinForm.minHeightErrorMessage" | transloco : { value: error.min } }}
                </div>
            </dtm-ui-expanded-number-input>
        </div>
    </section>
</div>
