import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FlightsModule, FlightsState } from "@dtm-frontend/drone-air-traffic-services-lib/flights";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { DeviceSize, DeviceSizeService, SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LetDirective } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "dats-header",
    templateUrl: "./header.component.html",
    standalone: true,
    imports: [SharedUiModule, SharedI18nModule, MatTooltipModule, LetDirective, FlightsModule],
    styleUrls: ["./header.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    protected readonly isAtcController$ = this.store.select(FlightsState.isAtcController);
    protected readonly userName$ = combineLatest([
        this.store.select(AuthState.userFirstName),
        this.store.select(AuthState.userLastName),
    ]).pipe(map(([firstName, lastName]) => `${firstName} ${lastName}`));
    protected readonly isHeaderUserButtonShortMode$ = this.deviceSizeService.getSizeObservable(
        DeviceSize.Smartphone,
        DeviceSize.SmartphoneWide
    );

    constructor(private store: Store, private readonly deviceSizeService: DeviceSizeService) {}
    protected logout() {
        this.store.dispatch(new AuthActions.Logout());
    }
}
