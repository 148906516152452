import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { IconDirective } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { LetModule } from "@ngrx/component";
import { Alert, AlertBadgeType } from "../../../models/alerts.model";
import { ALERT_BADGES } from "../../../utils/defaults";

interface AlertsListComponentState {
    alerts: Alert[] | undefined;
    alertsBadgeType: AlertBadgeType;
}

@Component({
    selector: "dats-lib-alerts-list",
    standalone: true,
    imports: [CommonModule, IconDirective, LetModule, TranslocoModule, SharedI18nModule],
    templateUrl: "./alerts-list.component.html",
    styleUrls: ["../alert-badges.scss", "./alerts-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AlertsListComponent {
    @Input() public set alerts(value: Alert[] | undefined) {
        this.localStore.patchState({ alerts: value ?? [] });
    }
    @Input() public set alertsBadgeType(value: AlertBadgeType) {
        this.localStore.patchState({ alertsBadgeType: value });
    }

    private readonly localStore = inject(LocalComponentStore<AlertsListComponentState>);
    protected readonly AlertBadgeType = AlertBadgeType;
    protected readonly alertBadges = ALERT_BADGES;
    protected readonly alertBadgesByType: Record<AlertBadgeType, { class: string; icon: string }> = ALERT_BADGES.reduce(
        (acc: Record<string, { class: string; icon: string }>, badge) => {
            acc[badge.type] = badge;

            return acc;
        },
        {}
    );
    protected readonly alerts$ = this.localStore.selectByKey("alerts");
    protected readonly alertsBadgeType$ = this.localStore.selectByKey("alertsBadgeType");

    constructor() {
        this.localStore.setState({ alerts: undefined, alertsBadgeType: AlertBadgeType.NotStarted });
    }
}
