import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, catchError, finalize, tap } from "rxjs";
import { CategorizedAlertsByBadge } from "../models/alerts.model";
import {
    AlertPointOfInterest,
    AlertProposal,
    CategorizedFlights,
    FlightsData,
    FlightsError,
    FlightsTab,
    PoiType,
} from "../models/flight.models";
import { FlightsApiService } from "../services/flights-api.service";
import { FlightsActions } from "./flights.actions";

export interface FlightsStateModel {
    error: FlightsError | undefined;
    isPanelProcessing: boolean;
    isActionProcessing: boolean;
    flightsTabList: FlightsTab[];
    categorizedFlights: CategorizedFlights | undefined;
    alerts: CategorizedAlertsByBadge | undefined;
    alertProposal: AlertProposal | undefined;
    hospitalList: AlertPointOfInterest[] | undefined;
    selectedFlightId: string | undefined;
}

const defaultState: FlightsStateModel = {
    error: undefined,
    isPanelProcessing: false,
    isActionProcessing: false,
    flightsTabList: [],
    categorizedFlights: undefined,
    alerts: undefined,
    alertProposal: undefined,
    hospitalList: undefined,
    selectedFlightId: undefined,
};

@State<FlightsStateModel>({
    name: "flights",
    defaults: defaultState,
})
@Injectable()
export class FlightsState {
    @Selector()
    public static error(state: FlightsStateModel): FlightsError | undefined {
        return state.error;
    }

    @Selector()
    public static isPanelProcessing(state: FlightsStateModel): boolean {
        return state.isPanelProcessing;
    }

    @Selector()
    public static isActionProcessing(state: FlightsStateModel): boolean {
        return state.isActionProcessing;
    }

    @Selector()
    public static flightsTabList(state: FlightsStateModel): FlightsTab[] | undefined {
        return state.flightsTabList;
    }

    @Selector()
    public static categorizedFlights(state: FlightsStateModel): CategorizedFlights | undefined {
        return state.categorizedFlights;
    }

    @Selector()
    public static alerts(state: FlightsStateModel) {
        return state.alerts;
    }

    @Selector()
    public static alertProposals(state: FlightsStateModel): AlertProposal | undefined {
        return state.alertProposal;
    }

    @Selector()
    public static hospitalList(state: FlightsStateModel): AlertPointOfInterest[] | undefined {
        return state.hospitalList;
    }

    @Selector()
    public static selectedFlightId(state: FlightsStateModel): string | undefined {
        return state.selectedFlightId;
    }

    constructor(private readonly flightsApi: FlightsApiService) {}

    @Action(FlightsActions.GetFlights)
    public getFlights(context: StateContext<FlightsStateModel>, action: FlightsActions.GetFlights) {
        context.patchState({ isPanelProcessing: true, error: undefined });

        return this.flightsApi.getFlights(action.isAtcController, action.tabType).pipe(
            tap((result: FlightsData) => {
                context.patchState({
                    isPanelProcessing: false,
                    flightsTabList: result.tabs,
                    categorizedFlights: result.categorizedFlights,
                });

                const selectedFlightId = context.getState().selectedFlightId;
                if (result.categorizedFlights && selectedFlightId) {
                    const doSelectedFlightStillExist = !!Object.values(result.categorizedFlights).find((flights) =>
                        flights.some((flight) => flight.id === selectedFlightId)
                    );

                    if (!doSelectedFlightStillExist) {
                        context.patchState({ selectedFlightId: undefined });
                    }
                }
            }),
            catchError((error) => {
                context.patchState({
                    isPanelProcessing: false,
                    flightsTabList: [],
                    categorizedFlights: undefined,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightsActions.ClearCategorizedFlights)
    public clearCategorizedFlights(context: StateContext<FlightsStateModel>) {
        context.patchState({ categorizedFlights: undefined, selectedFlightId: undefined });
    }

    @Action(FlightsActions.StandbyCheckin)
    public standbyCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.StandbyCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.standbyCheckin(action.checkinId, action.duration).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ModifyCheckinAndAccept)
    public modifyCheckinAndAccept(context: StateContext<FlightsStateModel>, action: FlightsActions.ModifyCheckinAndAccept) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.modifyCheckinAndAccept(action.checkinId, action.formValues).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.AcceptCheckin)
    public acceptCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.AcceptCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.acceptCheckin(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.RejectCheckin)
    public rejectCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.RejectCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.rejectCheckin(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.StopCheckin)
    public stopCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.StopCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.stopCheckin(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ArchiveCheckin)
    public archiveCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.ArchiveCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.archiveCheckin(action.checkinId, action.formValues).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ResendCheckinConfirmation)
    public resendCheckinConfirmation(context: StateContext<FlightsStateModel>, action: FlightsActions.ResendCheckinConfirmation) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.resendCheckinConfirmation(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.GetAlerts)
    public getAlerts(context: StateContext<FlightsStateModel>) {
        context.patchState({ error: undefined, alertProposal: undefined, isActionProcessing: true });

        return this.flightsApi.getAlerts().pipe(
            tap((alerts) => {
                context.patchState({ alerts });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.GetAlertProposals)
    public getAlertProposals(context: StateContext<FlightsStateModel>, action: FlightsActions.GetAlertProposals) {
        context.patchState({ error: undefined, alertProposal: undefined, isActionProcessing: true });

        return this.flightsApi.getAlertProposals(action.type, action.poiId, action.designator).pipe(
            tap((alertProposal) => {
                context.patchState({ alertProposal });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.CreateZoneAlerts)
    public createZoneAlerts(context: StateContext<FlightsStateModel>, action: FlightsActions.CreateZoneAlerts) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.flightsApi.createLocationAlert(action.zoneAlertEntity).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.CreatePoiAlerts)
    public createPoiAlerts(context: StateContext<FlightsStateModel>, action: FlightsActions.CreatePoiAlerts) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.flightsApi.createLocationAlert(action.poiAlertEntity).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.CreateWkbAlerts)
    public createWkbAlerts(context: StateContext<FlightsStateModel>, action: FlightsActions.CreateWkbAlerts) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.flightsApi.createLocationAlert(action.poiAlertEntity).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.GetAllHospitals)
    public getPoiList(context: StateContext<FlightsStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.flightsApi.getPoiList(PoiType.Hospital).pipe(
            tap(({ content: hospitalList }) => {
                context.patchState({
                    hospitalList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.SetBookmarkedHospitals)
    public setBookmarkedHospitals(context: StateContext<FlightsStateModel>, action: FlightsActions.SetBookmarkedHospitals) {
        const bookmarkedHospitals = context.getState().hospitalList;

        if (!bookmarkedHospitals) {
            return;
        }

        context.patchState({ error: undefined, isActionProcessing: true });

        return this.flightsApi.setBookmarkedHospitals(bookmarkedHospitals, action.newBookmarkedHospitals).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.SelectFlight)
    public selectFlight(context: StateContext<FlightsStateModel>, action: FlightsActions.SelectFlight) {
        context.patchState({ selectedFlightId: action.flightId });
    }
}
