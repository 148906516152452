import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonTheme } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface CheckinActionSidebarComponentState {
    headerText: string;
    confirmButtonText: string | undefined;
    confirmButtonType: ButtonTheme;
    isOpen: boolean;
    isProcessing: boolean;
    shouldDisplayRestoreButton: boolean;
}

@Component({
    selector: "dats-lib-checkin-action-sidebar[headerText]",
    templateUrl: "./checkin-action-sidebar.component.html",
    styleUrls: ["./checkin-action-sidebar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CheckinActionSidebarComponent {
    @Input() public set headerText(value: string) {
        this.localStore.patchState({ headerText: value });
    }
    @Input() public set confirmButtonText(value: string | undefined) {
        this.localStore.patchState({ confirmButtonText: value });
    }
    @Input() public set confirmButtonType(value: ButtonTheme | undefined) {
        this.localStore.patchState({ confirmButtonType: value });
    }
    @Input() public set isOpen(value: BooleanInput) {
        this.localStore.patchState({ isOpen: coerceBooleanProperty(value) });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set shouldDisplayRestoreButton(value: BooleanInput) {
        this.localStore.patchState({ shouldDisplayRestoreButton: coerceBooleanProperty(value) });
    }

    @Output() public readonly actionConfirm = new EventEmitter<void>();
    @Output() public readonly actionCancel = new EventEmitter<void>();
    @Output() public readonly restore = new EventEmitter<void>();

    protected readonly isOpen$ = this.localStore.selectByKey("isOpen");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly shouldDisplayRestoreButton$ = this.localStore.selectByKey("shouldDisplayRestoreButton");
    protected readonly headerText$ = this.localStore.selectByKey("headerText");
    protected readonly confirmButtonType$ = this.localStore.selectByKey("confirmButtonType");
    protected readonly confirmButtonText$ = this.localStore.selectByKey("confirmButtonText");

    protected readonly ButtonTheme = ButtonTheme;

    constructor(private readonly localStore: LocalComponentStore<CheckinActionSidebarComponentState>) {
        this.localStore.setState({
            headerText: "",
            confirmButtonText: undefined,
            confirmButtonType: ButtonTheme.Primary,
            isOpen: false,
            isProcessing: false,
            shouldDisplayRestoreButton: false,
        });
    }
}
