import { Pipe, PipeTransform } from "@angular/core";
import { GRAMS_IN_KILOGRAM } from "@dtm-frontend/shared/utils";

@Pipe({
    name: "uavMass",
})
export class UavMassPipe implements PipeTransform {
    public transform(value: number): string {
        if (value < 1) {
            return `${value * GRAMS_IN_KILOGRAM} g`;
        }

        return `${value} kg`;
    }
}
