import { PageResponseBody, UavType } from "@dtm-frontend/shared/ui";

import { Alert, AlertBadgeType, CategorizedAlertsByBadge } from "../models/alerts.model";
import {
    Airspace,
    AirspaceElement,
    AirspaceType,
    AlertPointOfInterest,
    AlertPointOfInterestWithPages,
    CategorizedFlights,
    FlightAcceptance,
    FlightAcceptancePhase,
    FlightAcceptanceType,
    FlightCategory,
    FlightItem,
    FlightMission,
    FlightModification,
    FlightOperation,
    FlightOperationType,
    FlightProgress,
    FlightProgressPhase,
    FlightsData,
    FlightsTab,
    FlightsTabType,
    Geography,
    ModifyCheckinFormValues,
    Operator,
    Pilot,
    Uav,
} from "../models/flight.models";
import {
    ATC_ACCEPTED_CATEGORIES,
    ATC_CONTROLLER_TABS,
    FIS_CATEGORIES,
    FIS_INFORMER_TABS,
    FIS_ZONES_CATEGORIES,
    SYSTEM_ACCEPTED_CATEGORIES,
    UTM_ATC_CATEGORIES,
    UTM_FIS_CATEGORIES,
} from "../utils/defaults";

export interface GetFlightsResponseBody {
    items: FlightItemResponseBody[];
}

export type CategorizedFlightsResponse = Partial<Record<FlightCategory, FlightItemResponseBody[]>>;

export type AlertResponseBody = Alert;

export interface GetPoiListResponseBody {
    items: PageResponseBody<PointOfInterestResponseBody>;
}

export type PointOfInterestResponseBody = Omit<AlertPointOfInterest, "isBookmarked"> & { bookmarked: boolean };

export interface ModifyCheckinRequestPayload {
    plannedStartAt: string;
    plannedEndAt: string;
    maxHeight: number;
}

export interface ArchiveCheckinRequestPayload {
    reason: {
        id: string;
        args: { [key: string]: string };
    };
}

export interface FlightItemResponseBody {
    id: string;
    createdAt: string;
    completedAt: string;
    acceptance: FlightAcceptanceResponseBody;
    progress: FlightProgressResponseBody;
    modifications: FlightModificationResponseBody[];
    operation: FlightOperationResponseBody;
    isAirspaceTypeCTR?: boolean;
    isAirspaceTypeMCTR?: boolean;
}

export interface PageableRequestPayload {
    size: number;
    page: number;
    sort: string;
}

export interface PageableResponseBody<T> extends Omit<PageResponseBody<T>, "hasContent"> {
    sort: SortResponseBody[];
    empty: boolean;
}

export interface SortResponseBody {
    direction: string;
    nullHandling: string;
    ascending: boolean;
    property: string;
    ignoreCase: boolean;
}

interface FlightAcceptanceResponseBody {
    acknowledged: boolean;
    phase: FlightAcceptancePhase;
    type: FlightAcceptanceType;
}

interface FlightProgressResponseBody {
    acknowledged: boolean;
    phase: FlightProgressPhase;
}

interface FlightModificationResponseBody {
    createdAt: string;
    acknowledged: boolean;
    plannedStartAt: string;
    plannedEndAt: string;
    maxHeight: number;
}

interface FlightOperationResponseBody {
    plannedStartAt: string;
    plannedEndAt: string;
    bvlos: boolean;
    mission?: FlightMissionResponseBody;
    pilot: Pilot;
    operator: Operator;
    uav: FlightUavResponseBody;
    airspace: Airspace;
    geography: Geography;
    type: FlightOperationType;
}

interface FlightMissionResponseBody {
    id: string;
    startAt: string;
    endAt: string;
    maxHeight: number;
}

interface FlightUavResponseBody {
    model: string;
    type: UavType;
    swarm: boolean;
    takeOffMass: number;
    takeOffMassLimit: number;
}

function convertFlightModificationsResponseBodyToFlightModification(response: FlightModificationResponseBody[]): FlightModification[] {
    return response
        .map((modification) => ({
            ...modification,
            isAcknowledged: modification.acknowledged,
            createdAt: new Date(modification.createdAt),
            plannedStartAt: new Date(modification.plannedStartAt),
            plannedEndAt: new Date(modification.plannedEndAt),
        }))
        .sort((left, right) => (left.createdAt?.getTime() ?? 0) - (right.createdAt?.getTime() ?? 0));
}

export function prepareCheckinModificationPayload(formValues: ModifyCheckinFormValues): ModifyCheckinRequestPayload {
    return {
        plannedStartAt: formValues.startAt?.toISOString(),
        plannedEndAt: formValues.finishAt?.toISOString(),
        maxHeight: formValues.height,
    };
}

function getTabTotalFlights(categorizedResponse: CategorizedFlightsResponse): number {
    return Object.values(categorizedResponse).reduce((total, flights) => total + (flights?.length ?? 0), 0);
}

function convertFlightAcceptanceResponseBodyToFlightAcceptance(response: FlightAcceptanceResponseBody): FlightAcceptance {
    return {
        phase: response.phase,
        isAcknowledged: response.acknowledged,
        type: response.type,
    };
}

function convertFlightProgressResponseBodyToFlightProgress(response: FlightProgressResponseBody): FlightProgress {
    return {
        phase: response.phase,
        isAcknowledged: response.acknowledged,
    };
}

function convertFlightMissionResponseBodyToFlightMission(response: FlightMissionResponseBody): FlightMission {
    return {
        ...response,
        startAt: new Date(response.startAt),
        endAt: new Date(response.endAt),
    };
}

function convertFlightUavResponseBodyToUav(response: FlightUavResponseBody): Uav {
    return {
        model: response.model,
        type: response.type,
        isSwarm: response.swarm,
        takeOffMass: response.takeOffMass ?? undefined,
        takeOffMassLimit: response.takeOffMassLimit,
    };
}

function convertFlightOperationResponseBodyToFlightOperation(response: FlightOperationResponseBody): FlightOperation {
    return {
        plannedStartAt: new Date(response.plannedStartAt),
        plannedEndAt: new Date(response.plannedEndAt),
        isBvlos: response.bvlos,
        mission: response.mission ? convertFlightMissionResponseBodyToFlightMission(response.mission) : undefined,
        pilot: response.pilot,
        operator: response.operator,
        uav: convertFlightUavResponseBodyToUav(response.uav),
        airspace: response.airspace,
        geography: response.geography,
        type: response.type,
    };
}

function isFlight112(type: FlightOperationType): boolean {
    if (!type) {
        return false;
    }

    return type === FlightOperationType.Emergency112 || type === FlightOperationType.Ghost112;
}

function getPendingCategory(operation: FlightOperationResponseBody): FlightCategory {
    if (operation.type !== FlightOperationType.Standard) {
        return FlightCategory.Pending_112;
    }

    return FlightCategory.Pending;
}

function getAcceptedCategory(acceptance: FlightAcceptanceResponseBody): FlightCategory {
    switch (acceptance.type) {
        case FlightAcceptanceType.ATC:
            return FlightCategory.AcceptedATC;
        case FlightAcceptanceType.System:
            return FlightCategory.AcceptedSystem;
        default:
            return FlightCategory.AcceptedOther;
    }
}

function getCompletedCategory(acceptance: FlightAcceptanceResponseBody): FlightCategory {
    switch (acceptance.type) {
        case FlightAcceptanceType.ATC:
            return FlightCategory.CompletedATC;
        case FlightAcceptanceType.System:
            return FlightCategory.CompletedSystem;
        default:
            return FlightCategory.CompletedOther;
    }
}

function getOverdueCategory(acceptance: FlightAcceptanceResponseBody): FlightCategory {
    switch (acceptance.type) {
        case FlightAcceptanceType.ATC:
            return FlightCategory.OverdueATC;
        case FlightAcceptanceType.System:
            return FlightCategory.OverdueSystem;
        default:
            return FlightCategory.OverdueOther;
    }
}

function getProgressUncompletedCategory(acceptance: FlightAcceptanceResponseBody, operation: FlightOperationResponseBody): FlightCategory {
    switch (acceptance.phase) {
        case FlightAcceptancePhase.Accepted:
            return getAcceptedCategory(acceptance);
        case FlightAcceptancePhase.Pending:
            return getPendingCategory(operation);
        case FlightAcceptancePhase.Rejected:
            return FlightCategory.Rejected;
        case FlightAcceptancePhase.Canceled:
            return FlightCategory.Canceled;
        case FlightAcceptancePhase.Standby:
            return FlightCategory.Standby;
        default:
            return FlightCategory.Other;
    }
}

export function getFlightCategory({ acceptance, progress, operation }: FlightItemResponseBody): FlightCategory {
    switch (progress.phase) {
        case FlightProgressPhase.Uncompleted:
            return getProgressUncompletedCategory(acceptance, operation);
        case FlightProgressPhase.Completed:
            return getCompletedCategory(acceptance);
        case FlightProgressPhase.Emergency:
            return FlightCategory.Emergency;
        case FlightProgressPhase.Overdue:
            return getOverdueCategory(acceptance);
        case FlightProgressPhase.Stop:
            return FlightCategory.Stop;
        default:
            return FlightCategory.Other;
    }
}

function convertFlightItemResponseBodyToFlightItem(response: FlightItemResponseBody): FlightItem {
    return {
        id: response.id,
        createdAt: new Date(response.createdAt),
        completedAt: response.completedAt ? new Date(response.completedAt) : undefined,
        acceptance: convertFlightAcceptanceResponseBodyToFlightAcceptance(response.acceptance),
        progress: convertFlightProgressResponseBodyToFlightProgress(response.progress),
        modifications: convertFlightModificationsResponseBodyToFlightModification(response.modifications),
        operation: convertFlightOperationResponseBodyToFlightOperation(response.operation),
        category: getFlightCategory(response),
        is112: isFlight112(response.operation.type),
        isAirspaceTypeCTR: response.isAirspaceTypeCTR,
        isAirspaceTypeMCTR: response.isAirspaceTypeMCTR,
    };
}

function categorizeFlights(response: FlightItemResponseBody[], categories: FlightCategory[]): CategorizedFlightsResponse {
    const categorizedFlightsResponse: CategorizedFlightsResponse = {};

    for (const responseItem of response) {
        const category = getFlightCategory(responseItem);

        if (categories.includes(category)) {
            if (!categorizedFlightsResponse[category]) {
                categorizedFlightsResponse[category] = [];
            }
            categorizedFlightsResponse[category]?.push(responseItem);
        }
    }

    return categorizedFlightsResponse;
}

export function categorizeAlertsByBadge(response: AlertResponseBody[]) {
    const categorizedAlertsByBadge: CategorizedAlertsByBadge = {};
    const dateNow = new Date();

    for (const responseItem of response) {
        const startedAt = new Date(responseItem.startedAt);
        if (startedAt <= dateNow && Object.values(AlertBadgeType).includes(responseItem.type)) {
            if (!categorizedAlertsByBadge[responseItem.type]) {
                categorizedAlertsByBadge[responseItem.type] = [];
            }
            categorizedAlertsByBadge[responseItem.type]?.push(responseItem);
        }

        if (startedAt > dateNow) {
            if (!categorizedAlertsByBadge[AlertBadgeType.NotStarted]) {
                categorizedAlertsByBadge[AlertBadgeType.NotStarted] = [];
            }
            categorizedAlertsByBadge[AlertBadgeType.NotStarted]?.push(responseItem);
        }
    }

    return categorizedAlertsByBadge;
}

function markAirspaceTypeForFilters(responseItem: FlightItemResponseBody, airspaceElements: AirspaceElement[]): void {
    for (const element of airspaceElements) {
        switch (element.type) {
            case AirspaceType.CTR:
                responseItem.isAirspaceTypeCTR = true;
                break;
            case AirspaceType.MCTR:
                responseItem.isAirspaceTypeMCTR = true;
                break;
            default:
                break;
        }
    }
}

function filterAndMarkAirspaceTypes(responseItem: FlightItemResponseBody, types: (string | AirspaceType)[]): boolean {
    const airspaceElements: AirspaceElement[] = responseItem.operation?.airspace?.elements || [];
    const hasMatchingType = airspaceElements.some((element) => types.includes(element.type));

    if (hasMatchingType) {
        markAirspaceTypeForFilters(responseItem, airspaceElements);
    }

    return hasMatchingType;
}

function getAirspaceTypesFlights(response: FlightItemResponseBody[], types: (string | AirspaceType)[]): FlightItemResponseBody[] {
    return response.filter((responseItem) => filterAndMarkAirspaceTypes(responseItem, types));
}

function getCategorizedFlights(tabType: FlightsTabType, response: FlightItemResponseBody[]): CategorizedFlightsResponse {
    switch (tabType) {
        case FlightsTabType.UTM_ATC:
            return categorizeFlights(response, UTM_ATC_CATEGORIES);
        case FlightsTabType.UTM_FIS:
            return categorizeFlights(response, UTM_FIS_CATEGORIES);
        case FlightsTabType.AtcAccepted:
            return categorizeFlights(response, ATC_ACCEPTED_CATEGORIES);
        case FlightsTabType.SystemAccepted:
            return categorizeFlights(response, SYSTEM_ACCEPTED_CATEGORIES);
        case FlightsTabType.FIS:
            return categorizeFlights(response, FIS_CATEGORIES);
        case FlightsTabType.TWR: {
            const flights = getAirspaceTypesFlights(response, [AirspaceType.CTR, AirspaceType.MCTR]);

            return categorizeFlights(flights, FIS_ZONES_CATEGORIES);
        }
        case FlightsTabType.ATZ: {
            const flights = getAirspaceTypesFlights(response, [AirspaceType.ATZ]);

            return categorizeFlights(flights, FIS_ZONES_CATEGORIES);
        }
        // TODO DTATS-59 missions, archive
        default:
            return {};
    }
}

function convertGetFlightsResponseBodyToFlightsData(
    response: FlightItemResponseBody[],
    tabTypes: FlightsTabType[],
    selectedTabType?: FlightsTabType
): FlightsData {
    let categorizedFlights: CategorizedFlights = {};
    const tabs: FlightsTab[] = tabTypes.map((type) => {
        const categorizedFlightsResponse = getCategorizedFlights(type, response);
        const tabFlights: FlightsTab = {
            type,
            total: getTabTotalFlights(categorizedFlightsResponse),
            isActionRequired:
                !!categorizedFlightsResponse.Emergency?.length ||
                !!categorizedFlightsResponse.Pending?.length ||
                !!categorizedFlightsResponse.Pending_112?.length,
        };

        if (!selectedTabType || selectedTabType === type) {
            categorizedFlights = Object.keys(categorizedFlightsResponse).reduce((flights: CategorizedFlights, key) => {
                const category = key as FlightCategory;
                flights[category] =
                    categorizedFlightsResponse[category]?.map((flight) => convertFlightItemResponseBodyToFlightItem(flight)) || [];

                return flights;
            }, categorizedFlights);
        }

        return tabFlights;
    });

    return {
        tabs,
        categorizedFlights,
    };
}

export function getFlights(response: FlightItemResponseBody[], isAtcController = true, selectedTab?: FlightsTabType): FlightsData {
    const tabs = isAtcController ? ATC_CONTROLLER_TABS : FIS_INFORMER_TABS;

    return convertGetFlightsResponseBodyToFlightsData(response, tabs, selectedTab);
}

export function convertGetPoiListResponseBodyToPoiWithPages(
    response: PageResponseBody<PointOfInterestResponseBody>
): AlertPointOfInterestWithPages {
    return {
        content: response.content.map((poi) => ({ ...poi, isBookmarked: poi.bookmarked })),
        pageNumber: response.number,
        pageSize: response.size,
        totalElements: response.totalElements,
    };
}
