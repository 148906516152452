import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import {
    DEFAULT_DEBOUNCE_TIME,
    DateUtils,
    FunctionUtils,
    LocalComponentStore,
    MILLISECONDS_IN_MINUTE,
    RxjsUtils,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, map, switchMap } from "rxjs";
import { FlightOperation, ModifyCheckinFormValues } from "../../models/flight.models";

interface ModifyCheckinFormComponentState {
    flightOperation: FlightOperation | undefined;
    referenceStartTime: Date | undefined;
    isAcceptedCheckin: boolean;
}

interface ModifyCheckinFormGroup {
    height: FormControl<number>;
    flightTime: FormControl<number | null>;
    customHeight: FormControl<number | null>;
    delayStart: FormControl<number | null>;
    startAt: FormControl<Date | null>;
    finishAt: FormControl<Date | null>;
}

// eslint-disable-next-line no-magic-numbers
const PREDEFINED_HEIGHT_VALUES = [60, 90, 120];
const MAX_CHECKIN_HEIGHT = 120;
const HEIGHT_SLIDER_OPTIONS = {
    floor: 0,
    ceil: MAX_CHECKIN_HEIGHT,
    vertical: true,
    // eslint-disable-next-line no-magic-numbers
    tickStep: MAX_CHECKIN_HEIGHT / 4,
    showSelectionBar: true,
    showTicksValues: true,
};

@UntilDestroy()
@Component({
    selector: "dats-lib-modify-checkin-form[flightOperation]",
    templateUrl: "./modify-checkin-form.component.html",
    styleUrls: ["./modify-checkin-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ModifyCheckinFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ModifyCheckinFormComponent),
            multi: true,
        },
    ],
})
export class ModifyCheckinFormComponent implements ControlValueAccessor, Validator {
    @Input() public set flightOperation(value: FlightOperation) {
        this.localStore.patchState({ flightOperation: value });
    }
    @Input() public set isAcceptedCheckin(value: BooleanInput) {
        this.localStore.patchState({ isAcceptedCheckin: coerceBooleanProperty(value) });
    }

    protected readonly modifyForm = new FormGroup<ModifyCheckinFormGroup>(
        {
            height: new FormControl(0, { nonNullable: true }),
            customHeight: new FormControl(null, [Validators.max(MAX_CHECKIN_HEIGHT), Validators.min(0)]),
            flightTime: new FormControl(null),
            delayStart: new FormControl(null),
            startAt: new FormControl(null),
            finishAt: new FormControl(null),
        },
        { validators: this.timeExceededValidator() }
    );

    protected readonly PREDEFINED_HEIGHT_VALUES = PREDEFINED_HEIGHT_VALUES;
    protected readonly HEIGHT_SLIDER_OPTIONS = HEIGHT_SLIDER_OPTIONS;

    private propagateTouch = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: ModifyCheckinFormValues) => void = FunctionUtils.noop;

    protected readonly operationDetails$ = this.localStore.selectByKey("flightOperation").pipe(
        RxjsUtils.filterFalsy(),
        map((operation) => ({
            plannedStartAt: operation.plannedStartAt,
            plannedEndAt: operation.plannedEndAt,
            maxHeight: operation.geography.maxHeight,
        }))
    );

    constructor(private readonly localStore: LocalComponentStore<ModifyCheckinFormComponentState>) {
        this.localStore.setState({ flightOperation: undefined, referenceStartTime: undefined, isAcceptedCheckin: false });

        this.propagateFormValues();
        this.listenOnCustomHeight();
        this.listenOnDelayTime();
    }

    public registerOnChange(fn: (value: ModifyCheckinFormValues) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.modifyForm.valid) {
            return null;
        }

        return this.modifyForm.invalid ? { invalidModifyCheckin: true } : null;
    }

    public writeValue(value: ModifyCheckinFormValues | null): void {
        // NOTE: Form should be reset before patch because it keeps the old date object and can't be updated correctly
        this.modifyForm.reset();

        if (value) {
            this.modifyForm.patchValue(value, { emitEvent: false });
            this.localStore.patchState({ referenceStartTime: new Date(value.startAt.getTime()) });
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.modifyForm.disable();
        } else {
            this.modifyForm.enable();
        }

        const isAcceptedCheckin = this.localStore.selectSnapshotByKey("isAcceptedCheckin");

        if (isAcceptedCheckin) {
            this.modifyForm.controls.startAt.disable({ emitEvent: false });
            this.modifyForm.controls.delayStart.disable({ emitEvent: false });
        }
    }

    protected updateEndTime(flightTimeInMinutes: number): void {
        const startAtTime = this.startAtControl.value;

        if (!startAtTime || this.flightTimeControl.pristine) {
            return;
        }

        const calculatedFinishTime = new Date(startAtTime.getTime() + flightTimeInMinutes * MILLISECONDS_IN_MINUTE);

        this.setValueToDateControl(this.finishAtControl, calculatedFinishTime);
    }

    protected updateStartTime(delayInMinutes: number): void {
        const startTime = this.startAtControl.value;
        const finishTime = this.finishAtControl.value;
        const referenceStartTime = this.localStore.selectSnapshotByKey("referenceStartTime");

        if (!startTime || !referenceStartTime || !finishTime || this.delayStartControl.pristine) {
            return;
        }

        const calculatedStartTime = new Date(referenceStartTime.getTime() + delayInMinutes * MILLISECONDS_IN_MINUTE);

        this.setValueToDateControl(this.startAtControl, calculatedStartTime);
    }

    protected updateDelayAndFlightTime(startTime: Date | null): void {
        const finishTime = this.finishAtControl.value;
        const flightOperation = this.localStore.selectSnapshotByKey("flightOperation");

        if (!startTime || !finishTime || !flightOperation) {
            return;
        }

        const isTimeExceeded = startTime.getTime() > finishTime.getTime();

        this.modifyForm.patchValue(
            {
                flightTime: isTimeExceeded ? 0 : DateUtils.minutesBetween(startTime, finishTime),
                delayStart: DateUtils.minutesBetween(startTime, flightOperation.plannedStartAt),
            },
            { emitEvent: false }
        );
    }

    protected updateFlightTime(finishTime: Date | null): void {
        const startTime = this.startAtControl.value;
        const flightOperation = this.localStore.selectSnapshotByKey("flightOperation");

        if (!finishTime || !startTime || !flightOperation) {
            return;
        }

        this.patchFlightTime(startTime, finishTime);
    }

    protected setHeightValue(value: number): void {
        this.modifyForm.controls.height.setValue(value, { emitEvent: false });
        this.modifyForm.controls.customHeight.setValue(value, { emitEvent: false });
    }

    protected getStartTimeMax(startDate: Date): Date {
        return new Date(new Date(startDate).setMinutes(startDate.getMinutes() - 1));
    }

    protected getFinishTimeMin(finishDate: Date): Date {
        return new Date(new Date(finishDate).setMinutes(finishDate.getMinutes() + 1));
    }

    private setValueToDateControl(dateControl: FormControl<Date | null>, value: Date) {
        dateControl.reset();
        dateControl.setValue(value, { emitEvent: false });
    }

    private get finishAtControl(): FormControl<Date | null> {
        return this.modifyForm.controls.finishAt;
    }

    private get startAtControl(): FormControl<Date | null> {
        return this.modifyForm.controls.startAt;
    }

    private get flightTimeControl(): FormControl<number | null> {
        return this.modifyForm.controls.flightTime;
    }

    private get delayStartControl(): FormControl<number | null> {
        return this.modifyForm.controls.delayStart;
    }

    private listenOnCustomHeight(): void {
        this.modifyForm.controls.customHeight.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.setHeightValue(value ?? 0);
        });
    }

    private listenOnDelayTime(): void {
        this.delayStartControl.valueChanges
            .pipe(
                switchMap(() => this.startAtControl.valueChanges),
                untilDestroyed(this)
            )
            .subscribe((startTime) => {
                const finishTime = this.finishAtControl.value;

                if (!startTime || !finishTime) {
                    return;
                }

                this.patchFlightTime(startTime, finishTime);
            });
    }

    private patchFlightTime(startTime: Date, finishTime: Date): void {
        const isTimeExceeded = startTime.getTime() > finishTime.getTime();

        this.modifyForm.patchValue(
            {
                flightTime: isTimeExceeded ? 0 : DateUtils.minutesBetween(startTime, finishTime),
            },
            { emitEvent: false }
        );
    }

    private propagateFormValues(): void {
        this.modifyForm.valueChanges.pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.modifyForm.getRawValue() as ModifyCheckinFormValues);
            this.propagateTouch();
        });
    }

    private timeExceededValidator(): ValidatorFn {
        return (form) => {
            const startAtControl = form.get("startAt");
            const finishAtControl = form.get("finishAt");

            if (!startAtControl || !finishAtControl) {
                return null;
            }

            const startAtTime = startAtControl.value?.getTime();
            const finishAtTime = finishAtControl.value?.getTime();

            if (startAtTime && finishAtTime && startAtTime > finishAtTime) {
                return { startTimeExceeded: true };
            }

            return null;
        };
    }
}
