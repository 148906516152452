import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { DeviceSize, DeviceSizeService } from "@dtm-frontend/shared/ui";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "dats-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    protected readonly userName$ = combineLatest([
        this.store.select(AuthState.userFirstName),
        this.store.select(AuthState.userLastName),
    ]).pipe(map(([firstName, lastName]) => `${firstName} ${lastName}`));
    protected readonly isHeaderUserButtonShortMode$ = this.deviceSizeService.getSizeObservable(
        DeviceSize.Smartphone,
        DeviceSize.SmartphoneWide
    );

    constructor(private store: Store, private readonly deviceSizeService: DeviceSizeService) {}

    protected logout() {
        this.store.dispatch(new AuthActions.Logout());
    }
}
