import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { AirspaceElement, AirspaceType, FlightCategory, FlightItem, FlightList, FlightsTab, FlightsTabType } from "../models/flight.models";
import { FlightFilterType, OperationTypeFilter } from "../models/flights-filters.models";
import {
    ATC_ACCEPTED_CATEGORIES,
    ATC_CONTROLLER_TABS,
    FIS_CATEGORIES,
    FIS_INFORMER_TABS,
    FIS_ZONES_CATEGORIES,
    SYSTEM_ACCEPTED_CATEGORIES,
    UTM_ATC_CATEGORIES,
    UTM_FIS_CATEGORIES,
} from "../utils/defaults";
import { CategorizedFlights } from "./flights.state";

function isFlightVisible(flight: FlightItem, category: FlightCategory, filters: FlightFilterType[]): boolean {
    if (!filters.length) {
        return true;
    }

    for (const filter of filters) {
        if (filter === OperationTypeFilter.Type112 && flight.is112) {
            return true;
        }

        if (filter === AirspaceType.CTR && flight.isAirspaceTypeCTR) {
            return true;
        }

        if (filter === AirspaceType.MCTR && flight.isAirspaceTypeMCTR) {
            return true;
        }

        if (filter === category) {
            return true;
        }
    }

    return false;
}

function markAirspaceTypeForFilters(flightItem: FlightItem, airspaceElements: AirspaceElement[]): void {
    for (const element of airspaceElements) {
        switch (element.type) {
            case AirspaceType.CTR:
                flightItem.isAirspaceTypeCTR = true;
                break;
            case AirspaceType.MCTR:
                flightItem.isAirspaceTypeMCTR = true;
                break;
            default:
                break;
        }
    }
}

function filterAndMarkAirspaceTypes(flightItem: FlightItem, types: (string | AirspaceType)[]): boolean {
    const airspaceElements: AirspaceElement[] = flightItem.operation?.airspace?.elements || [];
    const hasMatchingType = airspaceElements.some((element) => types.includes(element.type));

    if (hasMatchingType) {
        markAirspaceTypeForFilters(flightItem, airspaceElements);
    }

    return hasMatchingType;
}

function getAirspaceTypesFlights(flightItems: FlightItem[], types: (string | AirspaceType)[]): FlightItem[] {
    return flightItems.filter((flightItem) => filterAndMarkAirspaceTypes(flightItem, types));
}

function categorizeFlights(flightItems: FlightItem[], categories: FlightCategory[]): CategorizedFlights {
    const categorizedFlights: CategorizedFlights = {};

    for (const flightItem of flightItems) {
        if (categories.includes(flightItem.category)) {
            if (!categorizedFlights[flightItem.category]) {
                categorizedFlights[flightItem.category] = [];
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            categorizedFlights[flightItem.category]!.push(flightItem);
        }
    }

    return categorizedFlights;
}

function getCategorizedFlights(tabType: FlightsTabType, flightItems: FlightItem[]): CategorizedFlights {
    switch (tabType) {
        case FlightsTabType.UTM_ATC:
            return categorizeFlights(flightItems, UTM_ATC_CATEGORIES);
        case FlightsTabType.UTM_FIS:
            return categorizeFlights(flightItems, UTM_FIS_CATEGORIES);
        case FlightsTabType.AtcAccepted:
            return categorizeFlights(flightItems, ATC_ACCEPTED_CATEGORIES);
        case FlightsTabType.SystemAccepted:
            return categorizeFlights(flightItems, SYSTEM_ACCEPTED_CATEGORIES);
        case FlightsTabType.FIS:
            return categorizeFlights(flightItems, FIS_CATEGORIES);
        case FlightsTabType.TWR: {
            const flights = getAirspaceTypesFlights(flightItems, [AirspaceType.CTR, AirspaceType.MCTR]);

            return categorizeFlights(flights, FIS_ZONES_CATEGORIES);
        }
        case FlightsTabType.ATZ: {
            const flights = getAirspaceTypesFlights(flightItems, [AirspaceType.ATZ]);

            return categorizeFlights(flights, FIS_ZONES_CATEGORIES);
        }
        // TODO DTATS-59 missions, archive
        default:
            return {};
    }
}

export function getFilteredFlightList(
    flights: FlightItem[],
    isAtcController: boolean,
    selectedTabType: FlightsTabType | undefined,
    filters: FlightFilterType[]
): FlightList {
    const resultList: FlightList = {
        categorizedFlights: {},
        visibleFlights: [],
        filteredOutFlights: [],
    };

    const tabTypes = isAtcController ? ATC_CONTROLLER_TABS : FIS_INFORMER_TABS;
    const filteredOutFlightsSet: Set<FlightItem> = new Set();

    if (selectedTabType === FlightsTabType.Mission) {
        resultList.visibleFlights = flights;

        return resultList;
    }

    tabTypes.forEach((type) => {
        const categorizedFlights = getCategorizedFlights(type, flights);

        if (!selectedTabType || selectedTabType === type) {
            resultList.categorizedFlights = Object.keys(categorizedFlights).reduce((result, key) => {
                const category = key as FlightCategory;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const items = Object.values(categorizedFlights[category]!);
                const [visibleItems, filteredOutItems] = ArrayUtils.partition(items, (flight) =>
                    isFlightVisible(flight, category, filters)
                );

                result[category] = {
                    visibleItems,
                    total: items.length,
                };

                resultList.visibleFlights.push(...visibleItems);
                filteredOutItems.forEach((item) => filteredOutFlightsSet.add(item));

                return result;
            }, resultList.categorizedFlights);
        } else {
            const items = Object.values(categorizedFlights).flat(2);
            items.forEach((item) => filteredOutFlightsSet.add(item));
        }
    });

    resultList.filteredOutFlights = [...filteredOutFlightsSet.values()];

    return resultList;
}

export function getFlightsTabs(flights: FlightItem[], isAtcController: boolean): FlightsTab[] {
    const tabTypes = isAtcController ? ATC_CONTROLLER_TABS : FIS_INFORMER_TABS;

    return tabTypes
        .filter((tabType) => tabType !== FlightsTabType.Mission)
        .map((type) => {
            const categorizedFlights = getCategorizedFlights(type, flights);

            return {
                type,
                total: Object.values(categorizedFlights).reduce((total, items) => total + (items?.length ?? 0), 0),
                isActionRequired: !!(
                    categorizedFlights.Emergency?.length ||
                    categorizedFlights.Pending?.length ||
                    categorizedFlights.Pending_112?.length
                ),
            };
        });
}

export function getDefaultTabs(isAtcController: boolean): FlightsTab[] {
    const tabTypes = isAtcController ? ATC_CONTROLLER_TABS : FIS_INFORMER_TABS;

    return tabTypes.map((type) => ({
        type,
        total: 0,
        isActionRequired: false,
    }));
}

export function mergeTabs(existingTabs: FlightsTab[], newTabs: FlightsTab[]): FlightsTab[] {
    return ArrayUtils.unique([...existingTabs, ...newTabs], (tab) => tab.type);
}
