import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ButtonTheme } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, MINUTES_IN_HOUR, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map } from "rxjs";
import { FlightItem } from "../../models/flight.models";

// eslint-disable-next-line no-magic-numbers
const PREDEFINED_TIME_OFFSET = [5, 15, 30, 59];

interface StandbyCheckinFormComponentState {
    isTimeExceeded: boolean;
    flight: FlightItem | undefined;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-standby-checkin-form",
    templateUrl: "./standby-checkin-form.component.html",
    styleUrls: ["../sidebar/action-sidebar.scss", "./standby-checkin-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StandbyCheckinFormComponent {
    @Input({ required: true }) public set flight(value: FlightItem) {
        this.localStore.patchState({ flight: value });
    }
    @Output() public readonly actionConfirm = new EventEmitter<{ id: string; duration: number; isTimeExceeded: boolean }>();
    @Output() public readonly actionCancel = new EventEmitter<void>();

    protected readonly ButtonTheme = ButtonTheme;
    protected readonly PREDEFINED_TIME_OFFSET = PREDEFINED_TIME_OFFSET;
    protected readonly isTimeExceeded$ = this.localStore.selectByKey("isTimeExceeded");
    protected readonly operationDetails$ = this.localStore.selectByKey("flight").pipe(
        RxjsUtils.filterFalsy(),
        map((flight) => ({
            plannedStartAt: flight.operation.plannedStartAt,
            plannedEndAt: flight.operation.plannedEndAt,
            maxHeight: flight.operation.geography.maxHeight,
        }))
    );
    protected readonly durationControl = new FormControl<number>(0, { nonNullable: true });

    constructor(private readonly localStore: LocalComponentStore<StandbyCheckinFormComponentState>) {
        this.localStore.setState({
            isTimeExceeded: false,
            flight: undefined,
        });
        this.listenOnOffsetControl();
    }

    protected confirm() {
        const id = this.localStore.selectSnapshotByKey("flight")?.id;
        const isTimeExceeded = this.localStore.selectSnapshotByKey("isTimeExceeded");
        if (!id) {
            return;
        }

        if (this.durationControl.invalid) {
            this.durationControl.markAllAsTouched();

            return;
        }
        this.actionConfirm.emit({
            id,
            duration: this.durationControl.value,
            isTimeExceeded,
        });
    }

    private listenOnOffsetControl(): void {
        this.durationControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((value) => this.localStore.patchState({ isTimeExceeded: value >= MINUTES_IN_HOUR }));
    }
}
