import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { LEAFLET_MAP_CONFIG, LeafletMapConfig } from "@dtm-frontend/shared/map/leaflet";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { DateUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import equal from "fast-deep-equal";
import { MapOptions } from "leaflet";
import { ToastrService } from "ngx-toastr";
import { combineLatestWith, first, lastValueFrom, map, shareReplay, switchMap } from "rxjs";
import {
    AlertPointOfInterest,
    CheckinAction,
    CheckinActionButtonState,
    CheckinActionType,
    FlightOperation,
    FlightsTab,
    ModifyCheckinFormValues,
} from "../../models/flight.models";
import { FlightsActions } from "../../state/flights.actions";
import { FlightsState } from "../../state/flights.state";
import { ArchiveDialogComponent } from "../archive-dialog/archive-dialog.component";
import { FlightsLayerDirective } from "../flights-layer/flights-layer.directive";
import { FlightsPanelComponent } from "../flights-panel/flights-panel.component";

interface FlightsContainerComponentState {
    selectedTab: FlightsTab | undefined;
    checkinAction: CheckinAction | undefined;
    isActionSidebarOpen: boolean;
    actionButtonState: CheckinActionButtonState | undefined;
    initialModificationFormData: ModifyCheckinFormValues | undefined;
    isPanelExpanded: boolean;
    isHospitalListOpen: boolean;
    isBookmarkedHospitalsVisible: boolean;
    bookmarkedHospitals: AlertPointOfInterest[] | undefined;
}

interface CheckinActionFormGroupData {
    standbyDuration: FormControl<number>;
    modifyCheckin: FormControl<ModifyCheckinFormValues | null>;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-flights-container",
    templateUrl: "./flights-container.component.html",
    styleUrls: ["./flights-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightsContainerComponent implements OnInit {
    protected readonly flightsTabList$ = this.store.select(FlightsState.flightsTabList);
    protected readonly isPanelProcessing$ = this.store.select(FlightsState.isPanelProcessing);
    protected readonly isActionProcessing$ = this.store.select(FlightsState.isActionProcessing);
    protected readonly categorizedFlights$ = this.store.select(FlightsState.categorizedFlights);
    protected readonly hospitalList$ = this.store.select(FlightsState.hospitalList);
    protected readonly selectedFlightId$ = this.store.select(FlightsState.selectedFlightId);

    protected readonly selectedTab$ = this.localStore.selectByKey("selectedTab");
    protected readonly checkinAction$ = this.localStore.selectByKey("checkinAction").pipe(RxjsUtils.filterFalsy());
    protected readonly isActionSidebarOpen$ = this.localStore.selectByKey("isActionSidebarOpen");
    protected readonly actionButtonState$ = this.localStore.selectByKey("actionButtonState");
    protected readonly isHospitalListOpen$ = this.localStore.selectByKey("isHospitalListOpen");
    protected readonly isBookmarkedHospitalsVisible$ = this.localStore.selectByKey("isBookmarkedHospitalsVisible");
    protected readonly initialModificationFormData$ = this.localStore.selectByKey("initialModificationFormData");
    protected readonly flightOperation$ = this.localStore.selectByKey("checkinAction").pipe(
        RxjsUtils.filterFalsy(),
        map((checkinAction) => checkinAction.flightOperation)
    );
    protected readonly isPanelExpanded$ = this.localStore.selectByKey("isPanelExpanded");

    protected readonly ButtonTheme = ButtonTheme;
    protected readonly CheckinActionType = CheckinActionType;

    // TODO to implement roles in DTATS-59
    protected readonly isAtcController = true;

    protected readonly modifyCheckinFormGroup = new FormGroup<CheckinActionFormGroupData>({
        standbyDuration: new FormControl(0, { nonNullable: true }),
        modifyCheckin: new FormControl(null),
    });
    protected readonly hasModifyFormChanged$ = this.modifyCheckinFormGroup.controls.modifyCheckin.valueChanges.pipe(
        combineLatestWith(this.initialModificationFormData$),
        map(([formData, initialFormData]) => !equal(formData, initialFormData)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    @ViewChild(FlightsPanelComponent) private readonly flightsPanelComponent: FlightsPanelComponent | undefined;
    @ViewChild(FlightsLayerDirective) private readonly flightsLayerDirective: FlightsLayerDirective | undefined;

    protected readonly mapOptions: MapOptions;

    constructor(
        private readonly localStore: LocalComponentStore<FlightsContainerComponentState>,
        private readonly store: Store,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly dialogService: DialogService,
        private readonly matDialog: MatDialog,
        @Inject(LEAFLET_MAP_CONFIG) leafletConfig: LeafletMapConfig
    ) {
        this.localStore.setState({
            selectedTab: undefined,
            checkinAction: undefined,
            isActionSidebarOpen: false,
            actionButtonState: undefined,
            initialModificationFormData: undefined,
            isPanelExpanded: false,
            isHospitalListOpen: false,
            isBookmarkedHospitalsVisible: false,
            bookmarkedHospitals: undefined,
        });

        this.mapOptions = {
            center: leafletConfig.defaultPosition,
            zoom: leafletConfig.zoom.initial,
            minZoom: leafletConfig.zoom.min,
            maxZoom: leafletConfig.zoom.max,
            zoomControl: false,
            preferCanvas: true,
        };

        this.listenOnCheckinAction();
    }

    public ngOnInit() {
        this.updateSelectedTab();
    }

    protected updateSelectedTab(tab?: FlightsTab) {
        if (tab) {
            this.localStore.patchState({ isPanelExpanded: true });
        }

        this.localStore.patchState({ selectedTab: tab });
        this.store.dispatch(new FlightsActions.GetFlights(this.isAtcController, tab?.type));
    }

    protected clearSelection() {
        this.localStore.patchState({ selectedTab: undefined, isPanelExpanded: false });
        this.store.dispatch(new FlightsActions.GetFlights(this.isAtcController));
    }

    protected closePanel() {
        this.localStore.patchState({ isPanelExpanded: false });
    }

    protected selectFlight(flightId: string | undefined, source: "map" | "list") {
        this.store.dispatch(new FlightsActions.SelectFlight(flightId));

        if (source === "map" && flightId) {
            this.flightsPanelComponent?.scrollFlightIntoView(flightId);

            return;
        }

        if (source === "list" && flightId) {
            this.flightsLayerDirective?.zoomToFlightItem(
                flightId,
                this.localStore.selectSnapshotByKey("isPanelExpanded") && this.flightsPanelComponent
                    ? this.flightsPanelComponent.clientWidth
                    : 0
            );
        }
    }

    protected handleCheckinAction(checkinAction: CheckinAction) {
        this.localStore.patchState({ isActionSidebarOpen: false });

        switch (checkinAction.type) {
            case CheckinActionType.Approve:
                this.store
                    .dispatch(new FlightsActions.AcceptCheckin(checkinAction.id))
                    .pipe(untilDestroyed(this))
                    .subscribe(() => {
                        const error = this.store.selectSnapshot(FlightsState.error);
                        if (error) {
                            this.toastService.error(this.transloco.translate("datsLibDashboard.flightsContainer.acceptanceErrorMessage"));
                        }
                    });
                break;

            case CheckinActionType.Stop:
                this.store
                    .dispatch(new FlightsActions.StopCheckin(checkinAction.id))
                    .pipe(untilDestroyed(this))
                    .subscribe(() => {
                        const error = this.store.selectSnapshot(FlightsState.error);
                        if (error) {
                            this.toastService.error(this.transloco.translate("datsLibDashboard.flightsContainer.stopErrorMessage"));
                        }
                    });
                break;

            case CheckinActionType.Standby:
                this.localStore.patchState({ checkinAction, isActionSidebarOpen: true, actionButtonState: CheckinActionButtonState.Delay });
                this.manageActionFormState(checkinAction.type);
                break;

            case CheckinActionType.Modify:
                this.localStore.patchState({ checkinAction, isActionSidebarOpen: true, actionButtonState: CheckinActionButtonState.Save });
                this.manageActionFormState(checkinAction.type);
                break;

            case CheckinActionType.Archive:
                this.openArchiveDialog(checkinAction);

                break;
        }
    }

    protected openArchiveDialog(checkinAction: CheckinAction): void {
        this.dialogService
            .open(ArchiveDialogComponent)
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap((formValues) => this.store.dispatch(new FlightsActions.ArchiveCheckin(checkinAction.id, formValues))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);
                if (error) {
                    this.toastService.error(this.transloco.translate("datsLibDashboard.flightsContainer.archiveErrorMessage"));
                }
            });
    }

    protected setActionButtonState(isTimeExceeded: boolean, actionButtonState: CheckinActionButtonState | undefined): void {
        if (actionButtonState === CheckinActionButtonState.Save) {
            return;
        }

        this.localStore.patchState({
            actionButtonState: isTimeExceeded ? CheckinActionButtonState.Reject : CheckinActionButtonState.Delay,
        });
    }

    protected async closeActionSidebar(isModifyType: boolean) {
        const hasFormChanges = await lastValueFrom(this.hasModifyFormChanged$.pipe(first(), untilDestroyed(this)));

        if (hasFormChanges && isModifyType) {
            const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("datsLibDashboard.flightsContainer.modifyLeaveDialog.titleText"),
                    confirmationText: this.transloco.translate("datsLibDashboard.flightsContainer.modifyLeaveDialog.confirmationText"),
                    declineButtonLabel: this.transloco.translate("datsLibDashboard.flightsContainer.modifyLeaveDialog.declineButtonLabel"),
                    confirmButtonLabel: this.transloco.translate(
                        "datsLibDashboard.flightsContainer.modifyLeaveDialog.confirmationButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            });

            dialogRef
                .afterClosed()
                .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
                .subscribe(() => this.localStore.patchState({ isActionSidebarOpen: false }));

            return;
        }

        this.localStore.patchState({ isActionSidebarOpen: false });
    }

    protected applyCheckinAction(): void {
        const checkinAction = this.localStore.selectSnapshotByKey("checkinAction");
        this.modifyCheckinFormGroup.markAsTouched();

        if (this.modifyCheckinFormGroup.invalid || !checkinAction) {
            return;
        }

        switch (checkinAction.type) {
            case CheckinActionType.Standby: {
                this.standbyCheckin(checkinAction.id, this.modifyCheckinFormGroup.controls.standbyDuration.value);
                break;
            }
            case CheckinActionType.Modify: {
                this.modifyCheckin(checkinAction.id, this.modifyCheckinFormGroup.controls.modifyCheckin.value);
                break;
            }
            default:
                return;
        }
    }

    protected restoreFormValues(): void {
        const checkinAction = this.localStore.selectSnapshotByKey("checkinAction");

        if (!checkinAction?.flightOperation || checkinAction.type !== CheckinActionType.Modify) {
            return;
        }

        const preparedData = this.prepareInitialModifyFormValues(checkinAction.flightOperation);
        this.modifyCheckinFormGroup.controls.modifyCheckin.reset(preparedData, { emitEvent: false });
    }

    protected manageHospitalListPanel(isOpen: boolean): void {
        if (isOpen) {
            this.store.dispatch(new FlightsActions.GetAllHospitals());
        }

        this.localStore.patchState({ isHospitalListOpen: isOpen, bookmarkedHospitals: undefined });
    }

    protected setBookmarkedHospitalList(list: AlertPointOfInterest[]): void {
        this.localStore.patchState({ bookmarkedHospitals: list });
    }

    protected dispatchHospitalList(): void {
        const newList = this.localStore.selectSnapshotByKey("bookmarkedHospitals");

        if (newList) {
            this.store
                .dispatch(new FlightsActions.SetBookmarkedHospitals(newList))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.manageHospitalListPanel(false);
                    const error = this.store.selectSnapshot(FlightsState.error);

                    if (error) {
                        this.toastService.error(this.transloco.translate("datsLibDashboard.flightsContainer.bookmarkHospitalErrorMessage"));
                    }
                });
        } else {
            this.manageHospitalListPanel(false);
        }
    }

    protected displayBookmarkedHospitals(): void {
        this.localStore.patchState(({ isBookmarkedHospitalsVisible }) => ({ isBookmarkedHospitalsVisible: !isBookmarkedHospitalsVisible }));
    }

    private standbyCheckin(id: string, duration: number): void {
        const actionState = this.localStore.selectSnapshotByKey("actionButtonState");

        if (actionState === CheckinActionButtonState.Reject) {
            this.store
                .dispatch(new FlightsActions.RejectCheckin(id))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    const error = this.store.selectSnapshot(FlightsState.error);

                    if (error) {
                        this.toastService.error(this.transloco.translate("datsLibDashboard.flightsContainer.rejectCheckinErrorMessage"));
                    }
                });

            return;
        }

        this.store
            .dispatch(new FlightsActions.StandbyCheckin(id, duration))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);

                if (error) {
                    this.toastService.error(this.transloco.translate("datsLibDashboard.flightsContainer.rejectCheckinErrorMessage"));
                }
            });
    }

    private modifyCheckin(id: string, formValues: ModifyCheckinFormValues | null): void {
        if (!formValues) {
            return;
        }

        this.store
            .dispatch(new FlightsActions.ModifyCheckinAndAccept(id, formValues))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);

                if (error) {
                    this.toastService.error(this.transloco.translate("datsLibDashboard.flightsContainer.acceptanceErrorMessage"));
                }
            });
    }

    protected resendConfirmation(id: string): void {
        this.store
            .dispatch(new FlightsActions.ResendCheckinConfirmation(id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);

                if (error) {
                    this.toastService.error(this.transloco.translate("datsLibDashboard.flightsContainer.resendConfirmationErrorMessage"));
                }
            });
    }

    private manageActionFormState(checkinActionType: CheckinActionType.Modify | CheckinActionType.Standby): void {
        if (checkinActionType === CheckinActionType.Standby) {
            this.modifyCheckinFormGroup.controls.standbyDuration.enable({ emitEvent: false });
            this.modifyCheckinFormGroup.controls.modifyCheckin.disable({ emitEvent: false });
            this.modifyCheckinFormGroup.controls.modifyCheckin.reset();

            return;
        }

        this.modifyCheckinFormGroup.controls.modifyCheckin.enable({ emitEvent: false });
        this.modifyCheckinFormGroup.controls.standbyDuration.disable({ emitEvent: false });
        this.modifyCheckinFormGroup.controls.standbyDuration.reset();
    }

    private listenOnCheckinAction(): void {
        this.localStore
            .selectByKey("checkinAction")
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((data) => {
                this.modifyCheckinFormGroup.controls.modifyCheckin.setValue(this.prepareInitialModifyFormValues(data.flightOperation));
                this.localStore.patchState({ initialModificationFormData: this.prepareInitialModifyFormValues(data.flightOperation) });
            });
    }

    private prepareInitialModifyFormValues(flightOperation: FlightOperation): ModifyCheckinFormValues {
        return {
            startAt: new Date(flightOperation.plannedStartAt.setSeconds(0, 0)),
            finishAt: new Date(flightOperation.plannedEndAt.setSeconds(0, 0)),
            height: flightOperation.mission?.maxHeight ?? 0,
            customHeight: flightOperation.mission?.maxHeight ?? 0,
            delayStart: 0,
            flightTime: DateUtils.minutesBetween(flightOperation.plannedStartAt, flightOperation.plannedEndAt),
        };
    }
}
