import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { FlightAcceptanceType, FlightProgress, FlightProgressPhase } from "../../models/flight.models";

interface FFlightProgressBadgeComponentState {
    progress: FlightProgress | undefined;
    acceptanceType: FlightAcceptanceType | undefined;
}

@Component({
    selector: "dats-lib-flight-progress-badge[progress]",
    templateUrl: "./flight-progress-badge.component.html",
    styleUrls: ["./flight-progress-badge.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightProgressBadgeComponent {
    @Input() public set progress(value: FlightProgress | undefined) {
        this.localStore.patchState({ progress: value });
        this.isAcknowledged = coerceBooleanProperty(value?.isAcknowledged);
    }

    @Input() public set acceptanceType(value: FlightAcceptanceType | undefined) {
        this.localStore.patchState({ acceptanceType: value });
    }

    @HostBinding("class.fill") private isAcknowledged = false;

    protected readonly progress$ = this.localStore.selectByKey("progress");
    protected readonly acceptanceType$ = this.localStore.selectByKey("acceptanceType");
    protected readonly FlightProgressPhase = FlightProgressPhase;
    protected readonly FlightAcceptanceType = FlightAcceptanceType;

    constructor(private readonly localStore: LocalComponentStore<FFlightProgressBadgeComponentState>) {
        this.localStore.setState({
            progress: undefined,
            acceptanceType: undefined,
        });
    }
}
