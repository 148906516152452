import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { AirspaceElement } from "../../models/flight.models";

interface FisZonesBadgeComponentState {
    elements: AirspaceElement[];
    designatorsLabel: string | undefined;
}

@Component({
    selector: "dats-lib-fis-zones-badge[elements]",
    templateUrl: "./fis-zones-badge.component.html",
    styleUrls: ["./fis-zones-badge.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FisZonesBadgeComponent {
    @Input() public set elements(value: AirspaceElement[]) {
        if (value.length > 0) {
            const elements = this.sortElements(value);
            this.localStore.patchState({
                elements,
                designatorsLabel: this.getDesignatorsLabel(elements),
            });
        } else {
            this.localStore.patchState({
                elements: [],
                designatorsLabel: undefined,
            });
        }
    }

    protected readonly elements$ = this.localStore.selectByKey("elements").pipe(RxjsUtils.filterFalsy());
    protected readonly designatorsLabel$ = this.localStore.selectByKey("designatorsLabel");

    constructor(private readonly localStore: LocalComponentStore<FisZonesBadgeComponentState>) {
        this.localStore.setState({
            elements: [],
            designatorsLabel: undefined,
        });
    }

    private getDesignatorsLabel(elements: AirspaceElement[]): string {
        if (elements.length === 1) {
            return "";
        }

        return elements.map((element: AirspaceElement) => element.designator).join(", ");
    }

    private sortElements(elements: AirspaceElement[]): AirspaceElement[] {
        return elements.sort((left, right) => left.designator.localeCompare(right.designator));
    }
}
