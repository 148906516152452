import { AirspaceType, FlightCategory } from "./flight.models";

export enum OperationTypeFilter {
    Type112 = "Type112",
}

export type FlightFilterType = FlightCategory | AirspaceType | OperationTypeFilter;

export interface ChipFilter<T> {
    value: T;
    valueLabelTranslationKey?: string;
    valueLabelParam?: number | string;
}
