import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { EMPTY, Observable, catchError, map, throwError } from "rxjs";
import { ALERT_ENDPOINTS, AlertEndpoints } from "../alert.tokens";
import {
    Alert,
    AlertErrorType,
    AlertPointOfInterest,
    AlertPointOfInterestWithPages,
    AlertProposal,
    AlertType,
    LocationAlertEntity,
    PoiType,
} from "../models/alert.models";
import {
    GetPoiListResponseBody,
    PageableResponseBody,
    categorizeAlertsByBadge,
    convertGetPoiListResponseBodyToPoiWithPages,
} from "./alert-api.converters";

@Injectable({
    providedIn: "root",
})
export class AlertApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(ALERT_ENDPOINTS) private readonly endpoints: AlertEndpoints) {}

    public getAlerts() {
        return this.httpClient.get<{ alerts: PageableResponseBody<Alert> }>(this.endpoints.manageAlerts).pipe(
            map((response) => categorizeAlertsByBadge(response.alerts.content)),
            catchError(() => throwError(() => ({ type: AlertErrorType.CannotGetAlerts })))
        );
    }

    public createLocationAlert(alertData: LocationAlertEntity): Observable<void> {
        return this.httpClient
            .post<void>(this.endpoints.manageAlerts, alertData)
            .pipe(catchError(() => throwError(() => ({ type: AlertErrorType.CannotCreateLocationAlert }))));
    }

    public getAlertProposals(type: AlertType, poiId?: string, designator?: string): Observable<AlertProposal> {
        const params: HttpParams = new HttpParams();
        params.append("type", type);

        if (poiId) {
            params.append("poiId", poiId);
        }

        if (designator) {
            params.append("designator", designator);
        }

        return this.httpClient
            .get<AlertProposal>(this.endpoints.getAlertProposals, { params })
            .pipe(catchError(() => throwError(() => ({ type: AlertErrorType.CannotGetAlertProposals }))));
    }

    public getPoiList(poiType: PoiType, isBookmarked: boolean = false): Observable<AlertPointOfInterestWithPages> {
        const params: HttpParams = new HttpParams().append("bookmarked", isBookmarked);

        if (poiType) {
            params.append("type", poiType);
        }

        return this.httpClient.get<GetPoiListResponseBody>(this.endpoints.managePoiList, { params }).pipe(
            map((response) => convertGetPoiListResponseBodyToPoiWithPages(response.items)),
            catchError(() => throwError(() => ({ type: AlertErrorType.CannotGetPointsOfInterest })))
        );
    }

    public setBookmarkedHospitals(bookmarkedHospitals: AlertPointOfInterest[], newBookmarkedHospitals: AlertPointOfInterest[]) {
        const payload = this.createBookmarkedHospitalsRequestPayload(bookmarkedHospitals, newBookmarkedHospitals);

        if (!payload.addBookmarks.length && !payload.removeBookmarks.length) {
            return EMPTY;
        }

        return this.httpClient
            .put<void>(this.endpoints.managePoiList, payload)
            .pipe(catchError(() => throwError(() => ({ type: AlertErrorType.CannotBookmarkHospitals }))));
    }

    private createBookmarkedHospitalsRequestPayload(
        bookmarkedHospitals: AlertPointOfInterest[],
        newBookmarkedHospitals: AlertPointOfInterest[]
    ) {
        const addBookmarks = newBookmarkedHospitals
            .filter(
                (newBookmarkedHospital) =>
                    newBookmarkedHospital.isBookmarked &&
                    !bookmarkedHospitals.some(
                        (bookmarkedHospital) => bookmarkedHospital.id === newBookmarkedHospital.id && bookmarkedHospital.isBookmarked
                    )
            )
            .map((hospital) => hospital.id);

        const removeBookmarks = bookmarkedHospitals
            .filter(
                (bookmarkedHospital) =>
                    bookmarkedHospital.isBookmarked &&
                    !newBookmarkedHospitals.some(
                        (newBookmarkedHospital) => newBookmarkedHospital.id === bookmarkedHospital.id && newBookmarkedHospital.isBookmarked
                    )
            )
            .map((hospital) => hospital.id);

        return { addBookmarks, removeBookmarks };
    }
}
