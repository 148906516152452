<ng-container *ngrxLet="{ progress: progress$, acceptanceType: acceptanceType$ } as vm">
    <ng-container [ngSwitch]="vm.progress?.phase">
        <span *ngSwitchCase="FlightProgressPhase.Emergency" class="chip emergency">
            <dtm-ui-icon name="alert-fill"></dtm-ui-icon>
            {{ "datsLibDashboard.flightProgressBadge.statusLabel.Emergency" | transloco }}
        </span>
        <ng-container *ngSwitchCase="FlightProgressPhase.Stop">
            <span class="chip stop" [ngClass]="vm.progress?.isAcknowledged ? 'stop-acknowledged' : 'stop-not-acknowledged'">
                <dtm-ui-icon name="arrow-down-circle-fill"></dtm-ui-icon>
                {{
                    (vm.progress?.isAcknowledged
                        ? "datsLibDashboard.flightProgressBadge.statusLabel.StopAcknowledged"
                        : "datsLibDashboard.flightProgressBadge.statusLabel.StopNotAcknowledged"
                    ) | transloco
                }}
            </span>
        </ng-container>
        <span *ngSwitchCase="FlightProgressPhase.Overdue" class="chip overdue">
            <dtm-ui-icon name="hourglass-fill"></dtm-ui-icon>
            {{ "datsLibDashboard.flightProgressBadge.statusLabel.Overdue" | transloco }}
        </span>
        <span
            *ngSwitchCase="FlightProgressPhase.Completed"
            class="chip"
            [class.completed-atc]="vm.acceptanceType === FlightAcceptanceType.ATC"
            [class.completed-system]="vm.acceptanceType === FlightAcceptanceType.System"
            [class.default]="vm.acceptanceType !== FlightAcceptanceType.ATC && vm.acceptanceType !== FlightAcceptanceType.System"
        >
            <dtm-ui-icon name="focus-2-fill"></dtm-ui-icon>
            {{ "datsLibDashboard.flightProgressBadge.statusLabel.Completed" | transloco }}
        </span>
        <span *ngSwitchDefault class="chip default capitalize">
            {{ vm.progress?.phase }}
        </span>
    </ng-container>
</ng-container>
