<div
    class="wrapper"
    *ngrxLet="{
        isLoggedIn: isLoggedIn$,
        isHeaderUserButtonShortMode: isHeaderUserButtonShortMode$,
        userName: userName$,
        isAtcController: isAtcController$
    } as vm"
>
    <dats-lib-flights-navbar [isAtcController]="vm.isAtcController" />
    <dtm-ui-header-user-button
        userButtonSlot
        [isUserLoggedIn]="vm.isLoggedIn"
        [isShortMode]="vm.isHeaderUserButtonShortMode"
        [userName]="vm.userName"
        (logout)="logout()"
    >
    </dtm-ui-header-user-button>
</div>
