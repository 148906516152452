import { Pipe, PipeTransform } from "@angular/core";
import { UavMass } from "../models/flight.models";

const VERY_LIGHT_THRESHOLD = 0.25;
const LIGHT_THRESHOLD = 0.9;
const MEDIUM_THRESHOLD = 4;
const HEAVY_THRESHOLD = 25;

@Pipe({
    name: "uavMassLimit",
})
export class UavMassLimitPipe implements PipeTransform {
    public transform(value: number): string {
        if (value < VERY_LIGHT_THRESHOLD) {
            return UavMass.VeryLight;
        } else if (value < LIGHT_THRESHOLD) {
            return UavMass.Light;
        } else if (value >= LIGHT_THRESHOLD && value < MEDIUM_THRESHOLD) {
            return UavMass.MediumLight;
        } else if (value >= MEDIUM_THRESHOLD && value <= HEAVY_THRESHOLD) {
            return UavMass.Medium;
        }

        return UavMass.Heavy;
    }
}
