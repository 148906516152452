<ng-container
    *ngrxLet="{
        flight: flight$,
        isCheckinDataAcknowledged: isCheckinDataAcknowledged$,
        category: category$,
        isAtcController: isAtcController$
    } as vm"
>
    <div class="badges">
        <div
            class="status"
            [matTooltip]="
                (vm.flight.progress.phase
                    ? ('datsLibDashboard.flightItem.progressPhaseTooltip' | transloco : { value: vm.flight.progress.phase })
                    : '') +
                (vm.flight.acceptance.phase
                    ? ('datsLibDashboard.flightItem.acceptancePhaseTooltip' | transloco : { value: vm.flight.acceptance.phase })
                    : '') +
                (vm.flight.acceptance.type
                    ? ('datsLibDashboard.flightItem.acceptanceTypeTooltip' | transloco : { value: vm.flight.acceptance.type })
                    : '')
            "
            matTooltipPosition="above"
            matTooltipClass="flight-status-tooltip"
        >
            <dats-lib-flight-acceptance-badge
                *ngIf="vm.flight.progress.phase === FlightProgressPhase.Uncompleted; else progressBadgeTemplate"
                [acceptance]="vm.flight.acceptance"
                [operationType]="vm.flight.operation.type"
            ></dats-lib-flight-acceptance-badge>
            <ng-template #progressBadgeTemplate>
                <dats-lib-flight-progress-badge
                    [progress]="vm.flight.progress"
                    [acceptanceType]="vm.flight.acceptance.type"
                ></dats-lib-flight-progress-badge>
            </ng-template>
        </div>
        <div class="info-badges">
            <span *ngIf="vm.flight.operation.isBvlos" class="chip default">
                {{ "datsLibDashboard.flightItem.bvlosBadgeLabel" | transloco }}
            </span>
            <span *ngIf="vm.flight.operation.mission" class="chip default">
                {{ "datsLibDashboard.flightItem.missionBadgeLabel" | transloco }}
            </span>
            <ng-container *ngIf="vm.isAtcController; else fisZonesBadgeTemplate">
                <ng-container *ngIf="vm.flight | invoke : getAirspaceTypeElements : [AirspaceType.RPA] as elements">
                    <dats-lib-rpa-badge *ngIf="elements?.length" [elements]="elements"></dats-lib-rpa-badge>
                </ng-container>
            </ng-container>
            <ng-template #fisZonesBadgeTemplate>
                <ng-container
                    *ngIf="
                        vm.flight | invoke : getAirspaceTypeElements : [AirspaceType.CTR, AirspaceType.MCTR, AirspaceType.ATZ] as elements
                    "
                >
                    <dats-lib-fis-zones-badge *ngIf="elements?.length" [elements]="elements"></dats-lib-fis-zones-badge>
                </ng-container>
            </ng-template>
        </div>
    </div>
    <div class="details">
        <div class="name">
            <ng-container [ngSwitch]="vm.flight.operation.type">
                <dtm-ui-icon *ngSwitchCase="FlightOperationType.Emergency112" name="alarm-warning-fill" class="emergency"></dtm-ui-icon>
                <dtm-ui-icon *ngSwitchCase="FlightOperationType.Ghost112" name="spy-fill" class="emergency"></dtm-ui-icon>
                <dtm-ui-icon *ngSwitchDefault name="user"></dtm-ui-icon>
            </ng-container>
            {{ vm.flight.operation.operator.name | slice : 0 : 50 }}
            <ng-container *ngIf="vm.flight.operation.operator.name.length > 50">...</ng-container>
        </div>
        <div class="more">
            <button type="button" class="button-icon" (click)="openCheckinDetails()">
                <dtm-ui-icon name="information-fill"></dtm-ui-icon>
            </button>
            <button
                type="button"
                class="button-icon"
                [matMenuTriggerFor]="flightDetailsMenu"
                *ngIf="vm.flight.progress.phase !== FlightProgressPhase.Stop"
            >
                <dtm-ui-icon name="more"></dtm-ui-icon>
            </button>
        </div>

        <mat-menu #flightDetailsMenu="matMenu" xPosition="before">
            <button mat-menu-item type="button" (click)="modifyCheckin(CheckinActionType.Stop)">
                <div class="details-menu-item">
                    <dtm-ui-icon name="arrow-down-circle-fill" class="status-stop"></dtm-ui-icon>
                    <span>{{ "datsLibDashboard.flightItem.orderLandNowLabel" | transloco }}</span>
                </div>
            </button>
            <button mat-menu-item type="button" (click)="modifyCheckin(CheckinActionType.Archive)">
                <div class="details-menu-item">
                    <dtm-ui-icon name="archive-fill"></dtm-ui-icon>
                    <span>{{ "datsLibDashboard.flightItem.archive" | transloco }}</span>
                </div>
            </button>
        </mat-menu>
    </div>
    <div class="content">
        <div class="flight-details">
            <dats-lib-time-height-display
                [operation]="vm.flight.operation"
                [modifications]="vm.flight.modifications"
                [isEmergency]="vm.flight.progress.phase === FlightProgressPhase.Emergency"
                [isDataAcknowledged]="vm.isCheckinDataAcknowledged"
            ></dats-lib-time-height-display>
            <dats-lib-uav-info [uav]="vm.flight.operation.uav"></dats-lib-uav-info>
        </div>
        <dats-lib-confirmation-bar
            *ngIf="!vm.isCheckinDataAcknowledged"
            (resend)="confirmationResend.emit(vm.flight.id)"
        ></dats-lib-confirmation-bar>
    </div>
    <dats-lib-flight-progress-bar
        *ngIf="vm.category | invoke : isProgressBarVisible"
        [category]="vm.category"
        [flightStartAt]="vm.flight.operation.plannedStartAt"
        [flightEndAt]="vm.flight.operation.plannedEndAt"
    ></dats-lib-flight-progress-bar>
    <dats-lib-flight-item-actions
        *ngIf="
            vm.isCheckinDataAcknowledged &&
            (vm.flight.acceptance.phase === FlightAcceptancePhase.Pending || vm.flight.acceptance.phase === FlightAcceptancePhase.Standby)
        "
        (checkinModify)="modifyCheckin($event)"
    ></dats-lib-flight-item-actions>
</ng-container>
