<div class="standby-form" *ngrxLet="{ isTimeExceeded: isTimeExceeded$ } as vm">
    <dats-lib-operation-details [operationDetails]="operationDetails$ | ngrxPush"></dats-lib-operation-details>
    <div class="time-offset">
        <dtm-ui-expanded-number-input
            [formControl]="durationControl"
            [minValue]="0"
            [stepValue]="1"
            [valueSuffix]="'datsLibDashboard.standbyCheckinForm.controlSuffix' | transloco"
            [predefinedOptions]="PREDEFINED_TIME_OFFSET"
        >
            <label>{{ "datsLibDashboard.standbyCheckinForm.controlLabel" | transloco }}</label>
        </dtm-ui-expanded-number-input>
    </div>
    <div class="info">
        <p class="text" *ngIf="!vm.isTimeExceeded">{{ "datsLibDashboard.standbyCheckinForm.rejectInfo.offsetText" | transloco }}</p>
        <ng-container *ngIf="vm.isTimeExceeded">
            <p class="warning">{{ "datsLibDashboard.standbyCheckinForm.rejectInfo.main" | transloco }}</p>
            <p class="text">{{ "datsLibDashboard.standbyCheckinForm.rejectInfo.rejectText" | transloco }}</p>
        </ng-container>
    </div>
</div>
