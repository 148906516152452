import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FlightsRoutingModule } from "@dtm-frontend/drone-air-traffic-services-lib/flights";
import { DTM_AUTH_GUARD_REQUIRED_ROLES, DtmAuthGuard, NOT_AUTHORIZED_ROUTE } from "@dtm-frontend/shared/auth";
import { NotAuthorizedComponent } from "@dtm-frontend/shared/ui";

const routes: Routes = [
    {
        path: NOT_AUTHORIZED_ROUTE,
        component: NotAuthorizedComponent,
        canActivate: [DtmAuthGuard],
    },
    { path: "**", redirectTo: "/flights" },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), FlightsRoutingModule],
    providers: [
        {
            provide: DTM_AUTH_GUARD_REQUIRED_ROLES,
            useValue: [],
        },
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
