import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { LetModule, PushModule } from "@ngrx/component";
import { ExpandablePanelComponent } from "./components/expandable-panel/expandable-panel.component";

@NgModule({
    imports: [CommonModule, LetModule, MatSlideToggleModule, FormsModule, PushModule],
    declarations: [ExpandablePanelComponent],
    exports: [ExpandablePanelComponent],
})
export class SharedModule {}
