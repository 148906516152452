<ng-container *ngrxLet="{ selectedAlertType: selectedAlertType$ } as vm">
    <div
        *ngIf="
            vm.selectedAlertType === AlertType.AreaCircle ||
            vm.selectedAlertType === AlertType.AreaPolygon ||
            vm.selectedAlertType === AlertType.AreaCorridor
        "
        @slideInSideOffScreenUp
        @fade
        class="panel"
    >
        <div class="geometry-types">
            <button
                type="button"
                class="button-secondary geometry-type-button"
                [class.selected]="vm.selectedAlertType === AlertType.AreaCircle"
                (click)="alertTypeChange.emit(AlertType.AreaCircle)"
            >
                <dtm-ui-icon name="pen-nib-oval"></dtm-ui-icon>
                <span>{{ "datsLibAlert.alertGeometryPanel.circleLabel" | transloco }}</span>
            </button>
            <button
                type="button"
                class="button-secondary geometry-type-button"
                [class.selected]="vm.selectedAlertType === AlertType.AreaPolygon"
                (click)="alertTypeChange.emit(AlertType.AreaPolygon)"
            >
                <dtm-ui-icon name="pen-nib-prism"></dtm-ui-icon>
                <span>{{ "datsLibAlert.alertGeometryPanel.polygonLabel" | transloco }}</span>
            </button>
            <button
                type="button"
                class="button-secondary geometry-type-button"
                [class.selected]="vm.selectedAlertType === AlertType.AreaCorridor"
                (click)="alertTypeChange.emit(AlertType.AreaCorridor)"
            >
                <dtm-ui-icon name="pen-nib-line"></dtm-ui-icon>
                <span>{{ "datsLibAlert.alertGeometryPanel.corridorLabel" | transloco }}</span>
            </button>
        </div>
        <button type="button" class="button-icon" (click)="alertTypeChange.emit(undefined)">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
</ng-container>
