import { UavType } from "@dtm-frontend/shared/ui";

export interface FlightsData {
    tabs: FlightsTab[];
    flightList: FlightList;
}

export interface FlightsTab {
    type: FlightsTabType;
    total: number;
    isActionRequired: boolean;
}

export enum CheckinActionType {
    Standby = "Standby",
    Modify = "Modify",
    Approve = "Approve",
    Stop = "Stop",
    Archive = "Archive",
}

export interface CheckinAction {
    id: string;
    type: CheckinActionType;
    flight: FlightItem;
}

export enum CheckinActionButtonState {
    Delay = "Delay",
    Reject = "Reject",
    Save = "Save",
}

export interface OperationDetails {
    plannedStartAt: Date;
    plannedEndAt: Date;
    maxHeight: number;
}

export enum FlightsTabType {
    UTM_ATC = "UTM_ATC",
    UTM_FIS = "UTM_FIS",
    AtcAccepted = "AtcAccepted",
    SystemAccepted = "SystemAccepted",
    FIS = "FIS",
    TWR = "TWR",
    ATZ = "ATZ",
    Mission = "Mission",
    Archive = "Archive",
}

export enum FlightCategory {
    Pending_112 = "Pending_112",
    Emergency = "Emergency",
    Stop = "Stop",
    Pending = "Pending",
    AcceptedATC = "AcceptedATC",
    AcceptedSystem = "AcceptedSystem",
    AcceptedOther = "AcceptedOther",
    CompletedATC = "CompletedATC",
    CompletedSystem = "CompletedSystem",
    CompletedOther = "CompletedOther",
    Rejected = "Rejected",
    OverdueATC = "OverdueATC",
    OverdueSystem = "OverdueSystem",
    OverdueOther = "OverdueOther",
    Standby = "Standby",
    Canceled = "Canceled",
    Other = "Other",
    Mission = "Mission",
}

export interface FlightItem {
    id: string;
    createdAt: Date;
    completedAt?: Date;
    acceptance: FlightAcceptance;
    progress: FlightProgress;
    modification: FlightModification | undefined;
    operation: FlightOperation;
    category: FlightCategory;
    is112?: boolean;
    isAirspaceTypeCTR?: boolean;
    isAirspaceTypeMCTR?: boolean;
}

export interface FlightAcceptance {
    phase?: FlightAcceptancePhase;
    isAcknowledged: boolean;
    type?: FlightAcceptanceType;
}

export enum FlightAcceptanceType {
    Other = "OTHER",
    System = "SYSTEM",
    ATC = "ATC",
}

export enum FlightAcceptancePhase {
    Uncompleted = "UNCOMPLETED",
    Pending = "PENDING",
    Canceled = "CANCELED",
    Standby = "STANDBY",
    Rejected = "REJECTED",
    Accepted = "ACCEPTED",
}

export interface FlightProgress {
    phase?: FlightProgressPhase;
    isAcknowledged: boolean;
}

export enum FlightProgressPhase {
    Uncompleted = "UNCOMPLETED",
    Emergency = "EMERGENCY",
    Stop = "STOP",
    Overdue = "OVERDUE",
    Completed = "COMPLETED",
}

export interface FlightModification {
    initial: FlightModificationDetails;
    modified: FlightModificationDetails;
    isTimeModified: boolean;
    isHeightModified: boolean;
}

export interface FlightModificationDetails {
    createdAt: Date;
    isAcknowledged: boolean;
    plannedStartAt: Date;
    plannedEndAt: Date;
    maxHeight: number;
}

export interface FlightOperation {
    plannedStartAt: Date;
    plannedEndAt: Date;
    isBvlos: boolean;
    mission?: FlightMission;
    pilot: Pilot;
    operator: Operator;
    uav: Uav;
    airspace: Airspace;
    geography: Geography;
    type: FlightOperationType;
}

export interface FlightMission {
    id: string;
    startAt: Date;
    endAt: Date;
    maxHeight: number;
}

export interface Pilot {
    firstName: string;
    lastName: string;
    mobile: string;
}

export interface Operator {
    name: string;
}

export interface Uav {
    model: string;
    type: UavType;
    isSwarm: boolean;
    takeOffMass?: number;
    takeOffMassLimit: number;
}

export interface Airspace {
    elements: AirspaceElement[];
}

export interface AirspaceElement {
    geozone: string;
    designator: string;
    type: string;
    tags: string[];
}

export interface Geography {
    latitude: number;
    longitude: number;
    maxHeight: number;
    radius: number;
}

export enum FlightOperationType {
    Standard = "STANDARD",
    Emergency112 = "EMERGENCY_112",
    Ghost112 = "GHOST_112",
}

export enum UavMass {
    VeryLight = "VeryLight",
    Light = "Light",
    MediumLight = "MediumLight",
    Medium = "Medium",
    Heavy = "Heavy",
}

export enum TagImportance {
    Red = "RED",
    Yellow = "YELLOW",
    Green = "GREEN",
    Blue = "BLUE",
}

export interface ModifyCheckinFormValues {
    height: number | null;
    flightTime: number | null;
    customHeight: number | null;
    delayStart: number | null;
    startAt: Date | null;
    finishAt: Date | null;
}

export interface ArchiveCheckinFormValues {
    id: string;
    description: string;
}

export enum MissionType {
    BVLOS = "BVLOS",
    VLOS = "VLOS",
}

export interface JurisdictionMission {
    missionId: string;
    operatorName: string;
    type: MissionType;
    startDateTime: Date;
    endDateTime: Date;
    maxHeight: number;
}

export enum FlightsErrorType {
    CannotGetFlights = "CannotGetFlights",
    CannotStandbyCheckin = "CannotStandbyCheckin",
    CannotModifyCheckin = "CannotModifyCheckin",
    CannotAcceptCheckin = "CannotAcceptCheckin",
    CannotRejectCheckin = "CannotRejectCheckin",
    CannotStopCheckin = "CannotStopCheckin",
    CannotArchiveCheckin = "CannotArchiveCheckin",
    CannotResendCheckinConfirmation = "CannotResendCheckinConfirmation",
    CannotCreateLocationAlert = "CannotCreateLocationAlert",
    CannotGetAlertProposals = "CannotGetAlertProposals",
    CannotGetAlerts = "CannotGetAlerts",
    CannotGetPointsOfInterest = "CannotGetPointsOfInterest",
    CannotBookmarkHospitals = "CannotBookmarkHospitals",
    CannotGetMissions = "CannotGetMissions",
}

export interface FlightsError {
    type: FlightsErrorType;
}

export interface FlightList {
    categorizedFlights: Partial<
        Record<
            FlightCategory,
            {
                visibleItems: FlightItem[];
                total: number;
            }
        >
    >;
    visibleFlights: FlightItem[];
    filteredOutFlights: FlightItem[];
}

export enum AirspaceType {
    RPA = "RPA",
    CTR = "CTR",
    MCTR = "MCTR",
    ATZ = "ATZ",
}
