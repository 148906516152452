<div
    class="sidebar"
    [class.sidebar-folded]="!vm.isOpen"
    *ngrxLet="{
        confirmButtonType: confirmButtonType$,
        confirmButtonText: confirmButtonText$,
        headerText: headerText$,
        isOpen: isOpen$,
        isProcessing: isProcessing$,
        shouldDisplayRestoreButton: shouldDisplayRestoreButton$
    } as vm"
>
    <div class="panel-header">
        <h2 class="heading-text">{{ vm.headerText }}</h2>
        <button type="button" class="button-icon" (click)="actionCancel.emit()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <section class="top-section">
        <dtm-ui-loader-container [isShown]="vm.isProcessing">
            <ng-content></ng-content>
        </dtm-ui-loader-container>
    </section>
    <footer class="actions">
        <button
            *ngIf="vm.shouldDisplayRestoreButton"
            type="button"
            class="button-secondary"
            [disabled]="vm.isProcessing"
            (click)="restore.emit()"
        >
            <dtm-ui-icon name="refresh"></dtm-ui-icon>
            {{ "datsLibDashboard.actionSidebar.restoreButtonLabel" | transloco }}
        </button>

        <section class="modifications">
            <button type="button" class="button-secondary" [disabled]="vm.isProcessing" (click)="actionCancel.emit()">
                {{ "datsLibDashboard.actionSidebar.cancelButtonLabel" | transloco }}
            </button>
            <button
                type="button"
                [disabled]="vm.isProcessing"
                [ngClass]="vm.confirmButtonType === ButtonTheme.Warn ? 'button-warn' : 'button-primary'"
                (click)="actionConfirm.emit()"
            >
                {{ !vm.confirmButtonText ? ("datsLibDashboard.actionSidebar.saveButtonLabel" | transloco) : vm.confirmButtonText }}
            </button>
        </section>
    </footer>
</div>
