import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { DateFormatOptions } from "@jsverse/transloco-locale";
import { FlightModification, FlightOperation } from "../../models/flight.models";

interface TimeHeightDisplayComponentState {
    operation: FlightOperation | undefined;
    modifications: FlightModification[];
    isEmergency: boolean;
    isDataAcknowledged: boolean;
}

@Component({
    selector: "dats-lib-time-height-display[operation]",
    templateUrl: "./time-height-display.component.html",
    styleUrls: ["./time-height-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TimeHeightDisplayComponent {
    @Input() public set operation(value: FlightOperation | undefined) {
        this.localStore.patchState({ operation: value });
    }

    @Input() public set modifications(value: FlightModification[] | undefined) {
        this.localStore.patchState({ modifications: value ?? [] });
    }

    @Input() public set isEmergency(value: BooleanInput) {
        this.localStore.patchState({ isEmergency: coerceBooleanProperty(value) });
    }

    @Input() public set isDataAcknowledged(value: BooleanInput) {
        this.localStore.patchState({ isDataAcknowledged: coerceBooleanProperty(value) });
    }

    protected readonly operation$ = this.localStore.selectByKey("operation").pipe(RxjsUtils.filterFalsy());
    protected readonly modifications$ = this.localStore.selectByKey("modifications").pipe(RxjsUtils.filterFalsy());
    protected readonly isEmergency$ = this.localStore.selectByKey("isEmergency");
    protected readonly isDataAcknowledged$ = this.localStore.selectByKey("isDataAcknowledged");

    protected readonly localTimezoneLocalizeDateConfig: DateFormatOptions = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
    };
    protected readonly utcLocalizeDateConfig: DateFormatOptions = {
        ...this.localTimezoneLocalizeDateConfig,
        timeZone: "UTC",
    };

    constructor(private readonly localStore: LocalComponentStore<TimeHeightDisplayComponentState>) {
        this.localStore.setState({
            operation: undefined,
            modifications: [],
            isEmergency: false,
            isDataAcknowledged: true,
        });
    }
}
