import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UavType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Uav } from "../../models/flight.models";

interface UavInfoComponentState {
    uav: Uav | undefined;
}

@Component({
    selector: "dats-lib-uav-info",
    templateUrl: "./uav-info.component.html",
    styleUrls: ["./uav-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavInfoComponent {
    @Input() public set uav(value: Uav | undefined) {
        this.localStore.patchState({ uav: value });
    }

    protected readonly uav$ = this.localStore.selectByKey("uav").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<UavInfoComponentState>) {
        this.localStore.setState({
            uav: undefined,
        });
    }

    protected readonly UavType = UavType;
}
