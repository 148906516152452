import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { CheckinActionType } from "../../models/flight.models";

@Component({
    selector: "dats-lib-flight-item-actions",
    templateUrl: "./flight-item-actions.component.html",
    styleUrls: ["./flight-item-actions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightItemActionsComponent {
    @Output() public checkinModify = new EventEmitter<CheckinActionType>();

    protected readonly CheckinActionType = CheckinActionType;
}
