<ng-container *ngrxLet="uav$ as uav">
    <span [matTooltip]="'datsLibDashboard.uavInfo.tooltip' | transloco : { type: uav.type, model: uav.model }">
        <ng-container *ngIf="uav.isSwarm; else singleUavTemplate">
            <dtm-ui-icon name="drone-swarm"></dtm-ui-icon>
        </ng-container>
    </span>
    <ng-template #singleUavTemplate>
        <dtm-ui-icon
            *ngIf="uav.type === UavType.Airplane || uav.type === UavType.VerticalTakeOffAndLanding; else defaultDroneIconTemplate"
            name="plane"
        ></dtm-ui-icon>
    </ng-template>
    <ng-template #defaultDroneIconTemplate>
        <dtm-ui-icon name="drone"></dtm-ui-icon>
    </ng-template>
    <ng-container *ngIf="uav.takeOffMass; else massLimitTemplate">{{ uav.takeOffMass | uavMass }}</ng-container>
    <ng-template #massLimitTemplate>{{
        "datsLibDashboard.uavInfo.uavMassLimitLabel" | transloco : { value: (uav.takeOffMassLimit | uavMassLimit) }
    }}</ng-template>
</ng-container>
