<ng-container
    *ngrxLet="{
        operation: operation$,
        modifications: modifications$,
        isEmergency: isEmergency$,
        isDataAcknowledged: isDataAcknowledged$
    } as vm"
>
    <ng-container *ngIf="vm.modifications.length > 1; else noModificationsTemplate">
        <ng-template [ngTemplateOutlet]="currentDataTemplate" [ngTemplateOutletContext]="{ $implicit: vm.modifications[0] }"></ng-template>

        <div
            *ngIf="!vm.isEmergency"
            class="row no-icon"
            [ngClass]="vm.isDataAcknowledged ? 'modification-acknowledged' : 'modification-not-acknowledged'"
        >
            <dtm-ui-time-range-display
                [startTime]="vm.modifications[1].plannedStartAt"
                [endTime]="vm.modifications[1].plannedEndAt"
                timeZone="UTC"
            ></dtm-ui-time-range-display>
            <span class="height-label">
                {{ "datsLibDashboard.timeHeightDisplay.heightLabel" | transloco : { value: vm.modifications[0].maxHeight } }}
            </span>
        </div>
    </ng-container>

    <ng-template #noModificationsTemplate>
        <ng-template [ngTemplateOutlet]="currentDataTemplate" [ngTemplateOutletContext]="{ $implicit: vm.operation }"></ng-template>
    </ng-template>

    <ng-template #currentDataTemplate let-data>
        <div class="row" [class.data-emergency]="vm.isEmergency" [class.modification-proposed]="!vm.isDataAcknowledged">
            <div class="column">
                <dtm-ui-icon name="time-utc"></dtm-ui-icon>
                <dtm-ui-time-range-display
                    [startTime]="data.plannedStartAt"
                    [endTime]="data.plannedEndAt"
                    timeZone="UTC"
                    [matTooltip]="
                        'datsLibDashboard.timeHeightDisplay.timeDisplayTooltip'
                            | transloco
                                : {
                                      dateFrom: data.plannedStartAt | localizeDate : localTimezoneLocalizeDateConfig,
                                      dateTo: data.plannedEndAt | localizeDate : localTimezoneLocalizeDateConfig,
                                      dateFromUtc: data.plannedStartAt | localizeDate : utcLocalizeDateConfig,
                                      dateToUtc: data.plannedEndAt | localizeDate : utcLocalizeDateConfig
                                  }
                    "
                    matTooltipClass="time-display-tooltip"
                ></dtm-ui-time-range-display>
            </div>
            <div class="column">
                <dtm-ui-icon name="complex-top-height"></dtm-ui-icon>
                <span class="height-label">{{
                    "datsLibDashboard.timeHeightDisplay.heightLabel" | transloco : { value: data.maxHeight ?? data.geography.maxHeight }
                }}</span>
            </div>
        </div>
    </ng-template>
</ng-container>
