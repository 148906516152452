<dtm-ui-header [routeTitle]="">
    <dtm-ui-header-user-button
        userButtonSlot
        [isUserLoggedIn]="isLoggedIn$ | ngrxPush"
        [isShortMode]="isHeaderUserButtonShortMode$ | ngrxPush"
        [userName]="userName$ | ngrxPush"
        (logout)="logout()"
    >
    </dtm-ui-header-user-button>
</dtm-ui-header>
