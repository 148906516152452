import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { AlertPointOfInterest } from "../../models/flight.models";

interface BookmarkedHospitalListComponentState {
    hospitalList: AlertPointOfInterest[];
}

@Component({
    selector: "dats-lib-bookmarked-hospital-list[hospitalList]",
    templateUrl: "./bookmarked-hospital-list.component.html",
    styleUrls: ["./bookmarked-hospital-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class BookmarkedHospitalListComponent {
    @Input() public set hospitalList(value: AlertPointOfInterest[] | undefined) {
        this.localStore.patchState({ hospitalList: value ?? [] });
    }

    @Output() public bookmarkedHospitalsChange = new EventEmitter<AlertPointOfInterest[]>();

    protected readonly hospitalList$ = this.localStore.selectByKey("hospitalList");

    constructor(private readonly localStore: LocalComponentStore<BookmarkedHospitalListComponentState>) {
        this.localStore.setState({ hospitalList: [] });
    }

    protected markHospital(hospital: AlertPointOfInterest): void {
        const currentHospitalList = this.localStore.selectSnapshotByKey("hospitalList");

        if (!currentHospitalList) {
            return;
        }

        const hospitalIndex = currentHospitalList.findIndex((element) => element.id === hospital.id);

        if (hospitalIndex !== -1) {
            const hospitalList = [...currentHospitalList];

            const updatedHospital = {
                ...hospitalList[hospitalIndex],
                isBookmarked: !hospitalList[hospitalIndex].isBookmarked,
            };

            hospitalList[hospitalIndex] = updatedHospital;

            this.localStore.patchState({ hospitalList });
            this.bookmarkedHospitalsChange.emit(hospitalList);
        }
    }
}
