import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from "@angular/core";
import { LocalComponentStore, MILLISECONDS_IN_MINUTE, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject, combineLatestWith, map, of, switchMap, takeUntil, timer } from "rxjs";
import { FlightCategory } from "../../models/flight.models";

const MINUTES_IN_TIMESPAN = 30;
const OVERDUE_DURATION = 120;

interface ProgressTimeSpan {
    bufferMinutes: number;
    completedMinutes: number;
}

interface FlightProgressBarComponentState {
    flightStartAt: Date | undefined;
    flightEndAt: Date | undefined;
    category: FlightCategory;
    completedMinutes: number | undefined;
    timeSpans: ProgressTimeSpan[];
}

@UntilDestroy()
@Component({
    selector: "dats-lib-flight-progress-bar",
    templateUrl: "./flight-progress-bar.component.html",
    styleUrls: ["./flight-progress-bar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightProgressBarComponent implements OnInit {
    @Input({ required: true }) public set flightStartAt(value: Date | undefined) {
        this.localStore.patchState({ flightStartAt: value });
    }

    @Input({ required: true }) public set flightEndAt(value: Date | undefined) {
        this.localStore.patchState({ flightEndAt: value });
    }

    @Input({ required: true }) public set category(value: FlightCategory) {
        this.localStore.patchState({ category: value });
        this.categoryClassName = this.getCategoryClassName(value);
    }

    @HostBinding("class") private categoryClassName = "";

    protected readonly flightStartAt$ = this.localStore.selectByKey("flightStartAt").pipe(RxjsUtils.filterFalsy());
    protected readonly flightEndAt$ = this.localStore.selectByKey("flightEndAt").pipe(RxjsUtils.filterFalsy());
    protected readonly category$ = this.localStore.selectByKey("category");
    protected readonly completedMinutes$ = this.localStore.selectByKey("completedMinutes");
    protected readonly timeSpans$ = this.localStore.selectByKey("timeSpans");
    protected readonly duration$ = this.category$.pipe(
        switchMap((category) =>
            this.isOverdue(category)
                ? of(OVERDUE_DURATION)
                : this.flightStartAt$.pipe(
                      combineLatestWith(this.flightEndAt$),
                      map(([start, end]) => this.getDurationInMinutes(start, end))
                  )
        )
    );
    protected readonly FlightCategory = FlightCategory;
    private readonly timerStartTime$ = this.category$.pipe(
        switchMap((category) => (this.isOverdue(category) ? this.flightEndAt$ : this.flightStartAt$))
    );
    private readonly clearTimer = new Subject<void>();

    constructor(private readonly localStore: LocalComponentStore<FlightProgressBarComponentState>) {
        this.localStore.setState({
            flightStartAt: undefined,
            flightEndAt: undefined,
            category: FlightCategory.Pending,
            completedMinutes: undefined,
            timeSpans: [],
        });
    }

    public ngOnInit() {
        this.setData();
    }

    private setData(): void {
        const category = this.localStore.selectSnapshotByKey("category");

        if (category === FlightCategory.Emergency) {
            this.setEmergencyTimeSpan();
        } else {
            this.startTimer();
            this.watchProgress();
            this.watchForTimerClear();
        }
    }

    private watchProgress() {
        this.duration$
            .pipe(
                combineLatestWith(this.completedMinutes$),
                map(([duration, completedMinutes]) => {
                    const timeSpans = this.getInitialTimeSpans(duration);
                    if (completedMinutes) {
                        this.localStore.patchState({
                            timeSpans: this.getTimeSpansCompletedValue(completedMinutes, timeSpans),
                        });
                    } else {
                        this.localStore.patchState({
                            timeSpans,
                        });
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private getDurationInMinutes(start: Date, end: Date): number {
        const millisecondsDuration = end.getTime() - start.getTime();

        return Math.floor(millisecondsDuration / MILLISECONDS_IN_MINUTE);
    }

    private getInitialTimeSpans(duration: number): ProgressTimeSpan[] {
        const timeSpans: ProgressTimeSpan[] = [];
        const totalTimeSpansNumber = this.getTotalTimeSpansNumber(duration);

        for (let i = 0; i < totalTimeSpansNumber - 1; i++) {
            timeSpans.push({
                bufferMinutes: 30,
                completedMinutes: 0,
            });
        }

        timeSpans.push({
            bufferMinutes: duration - (totalTimeSpansNumber - 1) * MINUTES_IN_TIMESPAN,
            completedMinutes: 0,
        });

        return timeSpans;
    }

    private getTimeSpansCompletedValue(completedMinutes: number, timeSpans: ProgressTimeSpan[]): ProgressTimeSpan[] {
        for (let i = 0; i < timeSpans.length; i++) {
            if (completedMinutes >= MINUTES_IN_TIMESPAN * (i + 1)) {
                timeSpans[i].completedMinutes = MINUTES_IN_TIMESPAN;
            } else {
                const completed = completedMinutes - MINUTES_IN_TIMESPAN * i;
                timeSpans[i].completedMinutes = Math.max(completed, 0);
            }
        }

        return timeSpans;
    }

    protected getTotalTimeSpansNumber(duration: number): number {
        return Math.ceil(duration / MINUTES_IN_TIMESPAN);
    }

    protected getPercentageValue(minutesToDisplay: number) {
        return (minutesToDisplay / MINUTES_IN_TIMESPAN) * 100;
    }

    private getCompletedMinutes(timerStartTime: Date): number {
        const currentTime = new Date().getTime();
        const millisecondsCompleted = currentTime - timerStartTime.getTime();

        return Math.floor(millisecondsCompleted / MILLISECONDS_IN_MINUTE);
    }

    private startTimer() {
        timer(0, MILLISECONDS_IN_MINUTE)
            .pipe(
                switchMap(() => this.timerStartTime$),
                takeUntil(this.clearTimer),
                untilDestroyed(this)
            )
            .subscribe((timerStartTime) => {
                this.setCompletedMinutes(timerStartTime);
            });
    }

    private setCompletedMinutes(timerStartTime: Date) {
        this.localStore.patchState({
            completedMinutes: this.getCompletedMinutes(timerStartTime),
        });
    }

    private getProgressStartDate(category: FlightCategory): Date | undefined {
        if (this.isOverdue(category)) {
            return this.localStore.selectSnapshotByKey("flightEndAt");
        }

        return this.localStore.selectSnapshotByKey("flightStartAt");
    }

    private setEmergencyTimeSpan() {
        this.localStore.patchState({
            timeSpans: [
                {
                    bufferMinutes: 0,
                    completedMinutes: 0,
                },
            ],
        });
    }

    private watchForTimerClear() {
        this.completedMinutes$
            .pipe(
                combineLatestWith(this.duration$),
                map(([completedMinutes, duration]) => {
                    if (completedMinutes && completedMinutes > 0 && completedMinutes >= duration) {
                        this.clearTimer.next();
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private getCategoryClassName(category: FlightCategory): string {
        switch (category) {
            case FlightCategory.Stop:
                return "category-stop";
            case FlightCategory.AcceptedATC:
                return "category-atc-accepted";
            case FlightCategory.AcceptedSystem:
                return "category-system-accepted";
            case FlightCategory.OverdueATC:
            case FlightCategory.OverdueSystem:
            case FlightCategory.OverdueOther:
                return "category-overdue";
            default:
                return "default";
        }
    }

    protected isOverdue(category: FlightCategory): boolean {
        return (
            category === FlightCategory.OverdueATC || category === FlightCategory.OverdueSystem || category === FlightCategory.OverdueOther
        );
    }
}
