import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { AlertType } from "../../../alert/models/alert.models";
import { AlertActions } from "../../../alert/state/alert.actions";
import { AlertState } from "../../../alert/state/alert.state";

interface NavbarComponentState {
    isAtcController: boolean;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-flights-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NavbarComponent {
    @Input({ required: true }) public set isAtcController(value: BooleanInput) {
        this.localStore.patchState({ isAtcController: coerceBooleanProperty(value) });
    }

    protected readonly selectedAlertType$ = this.store.select(AlertState.selectedAlertType);

    protected readonly isAtcController$ = this.localStore.selectByKey("isAtcController");

    constructor(private readonly localStore: LocalComponentStore<NavbarComponentState>, private readonly store: Store) {
        this.localStore.setState({ isAtcController: false });
    }

    protected changeAlertType(alertType: AlertType) {
        this.store.dispatch(new AlertActions.SelectAlertType(alertType));
    }
}
