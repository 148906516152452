import { AnimationEvent } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatest, map, startWith } from "rxjs";
import { CategorizedFlights, CheckinAction, FlightCategory, FlightsTab, FlightsTabType } from "../../models/flight.models";
import { ChipFilter, FlightFilterType, OperationTypeFilter } from "../../models/flights-filters.models";
import { AVAILABLE_AIRSPACE_TYPE_FILTERS, AVAILABLE_CATEGORY_FILTERS } from "../../utils/defaults";
import { CategoryFlightListComponent } from "../category-flight-list/category-flight-list.component";

interface FlightsPanelComponentState {
    tabs: FlightsTab[];
    selectedTab: FlightsTab | undefined;
    isProcessing: boolean;
    isMainPanelExpanded: boolean;
    isAtcController: boolean;
    categorizedFlights: CategorizedFlights | undefined;
    appliedFilters: FlightFilterType[];
    selectedFlightId: string | undefined;
}

const ANIMATION_DURATION = 300;
const ANIMATION_DELAY = 0;
const FOLD_OFFSET = "628px";

@Component({
    selector: "dats-lib-flights-panel",
    templateUrl: "./flights-panel.component.html",
    styleUrls: ["./flights-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.foldAnimation(ANIMATION_DURATION, ANIMATION_DELAY, FOLD_OFFSET)],
})
export class FlightsPanelComponent {
    @Input({ required: true }) public set tabs(value: FlightsTab[] | undefined) {
        this.localStore.patchState({ tabs: value ?? [] });
    }
    @Input() public set selectedTab(value: FlightsTab | undefined) {
        this.localStore.patchState({ selectedTab: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isMainPanelExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isAtcController(value: BooleanInput) {
        this.localStore.patchState({ isAtcController: coerceBooleanProperty(value) });
    }
    @Input({ required: true }) public set categorizedFlights(value: CategorizedFlights | undefined) {
        this.localStore.patchState({ categorizedFlights: value });
    }
    @Input({ required: true }) public set selectedFlightId(value: string | undefined) {
        this.localStore.patchState({ selectedFlightId: value });
    }

    @Output() public readonly selectedTabUpdate: EventEmitter<FlightsTab> = new EventEmitter<FlightsTab>();
    @Output() public readonly selectionClear: EventEmitter<void> = new EventEmitter<void>();
    @Output() public readonly checkinModify: EventEmitter<CheckinAction> = new EventEmitter<CheckinAction>();
    @Output() public readonly panelClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() public readonly confirmationResend = new EventEmitter<string>();
    @Output() public readonly selectedFlightChanged = new EventEmitter<string>();

    public get clientWidth(): number {
        return this.flightsPanelWrapperElement?.nativeElement.clientWidth ?? 0;
    }

    protected readonly tabs$ = this.localStore.selectByKey("tabs");
    protected readonly selectedTab$ = this.localStore.selectByKey("selectedTab");
    protected readonly isMainPanelExpanded$ = this.localStore.selectByKey("isMainPanelExpanded");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isAtcController$ = this.localStore.selectByKey("isAtcController");
    protected readonly categorizedFlights$ = this.localStore.selectByKey("categorizedFlights");
    protected readonly chipFilters$ = this.categorizedFlights$.pipe(map((flights) => this.getChipFilters(flights)));
    protected readonly appliedFilters$ = this.localStore.selectByKey("appliedFilters");
    protected readonly selectedFlightId$ = this.localStore.selectByKey("selectedFlightId");

    protected readonly lastSelectedFlight$ = combineLatest([this.categorizedFlights$, this.selectedFlightId$]).pipe(
        map(([flights, selectedFlightId]) =>
            Object.values(flights ?? {})
                .flat(2)
                .find((flight) => flight.id === selectedFlightId)
        ),
        RxjsUtils.filterFalsy(),
        startWith(undefined)
    );

    protected readonly FlightCategory = FlightCategory;
    protected readonly FlightsTabType = FlightsTabType;

    @ViewChildren(CategoryFlightListComponent) private readonly categoryFlightListComponent:
        | QueryList<CategoryFlightListComponent>
        | undefined;
    @ViewChild("flightsPanelWrapper") private readonly flightsPanelWrapperElement: ElementRef<HTMLElement> | undefined;

    constructor(private readonly localStore: LocalComponentStore<FlightsPanelComponentState>) {
        this.localStore.setState({
            tabs: [],
            selectedTab: undefined,
            isProcessing: false,
            isMainPanelExpanded: false,
            isAtcController: false,
            categorizedFlights: undefined,
            appliedFilters: [],
            selectedFlightId: undefined,
        });
    }

    public scrollFlightIntoView(flightId: string) {
        this.categoryFlightListComponent?.forEach((list) => list.scrollFlightIntoView(flightId));
    }

    protected selectTab(tab: FlightsTab) {
        if (tab.total === 0) {
            return;
        }

        this.selectedTabUpdate.emit(tab);
    }

    protected closePanel() {
        this.panelClose.emit();
    }

    protected handleFoldAnimationDone(event: AnimationEvent) {
        if (event.fromState !== "void" && event.toState === "closed") {
            this.selectionClear.emit();
        }
    }

    private getChipFilters(flights: CategorizedFlights | undefined): ChipFilter<FlightFilterType>[] {
        if (!flights) {
            return [];
        }

        const chips: ChipFilter<FlightFilterType>[] = [];
        let operationType112FilterCount = 0;

        for (const [key, items] of Object.entries(flights)) {
            const category = key as FlightCategory;

            if (AVAILABLE_CATEGORY_FILTERS.includes(category)) {
                chips.push(this.prepareFilterChip(category, "datsLibDashboard.flightsPanel.categoryFilterValueLabel", items?.length));
            }

            operationType112FilterCount += items?.filter((flight) => flight.is112).length ?? 0;
        }

        if (operationType112FilterCount) {
            chips.unshift(
                this.prepareFilterChip(
                    OperationTypeFilter.Type112,
                    "datsLibDashboard.flightsPanel.operationTypeFilterValueLabel",
                    operationType112FilterCount
                )
            );
        }

        if (this.localStore.selectSnapshotByKey("selectedTab")?.type === FlightsTabType.TWR) {
            AVAILABLE_AIRSPACE_TYPE_FILTERS.forEach((airspaceType) => {
                chips.push(this.prepareFilterChip(airspaceType, "datsLibDashboard.flightsPanel.airspaceTypeFilterValueLabel"));
            });
        }

        return chips;
    }

    private prepareFilterChip(
        value: FlightFilterType,
        valueLabelTranslationKey?: string,
        valueLabelParam?: number
    ): ChipFilter<FlightFilterType> {
        return {
            value,
            valueLabelTranslationKey,
            valueLabelParam: valueLabelParam,
        };
    }

    protected applyFilters(appliedFilters: FlightFilterType[]) {
        this.localStore.patchState({ appliedFilters });
    }
}
