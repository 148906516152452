import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { LEAFLET_MAP_CONFIG, LeafletMapConfig } from "@dtm-frontend/shared/map/leaflet";
import { ButtonTheme, DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { LatLngBounds, LatLngExpression, MapOptions, latLngBounds } from "leaflet";
import { ToastrService } from "ngx-toastr";
import { EMPTY, map, switchMap } from "rxjs";
import { AlertPointOfInterest, AlertType } from "../../../alert/models/alert.models";
import { AlertActions } from "../../../alert/state/alert.actions";
import { AlertState } from "../../../alert/state/alert.state";
import {
    CheckinAction,
    CheckinActionType,
    FlightAcceptancePhase,
    FlightItem,
    FlightsTab,
    ModifyCheckinFormValues,
} from "../../models/flight.models";
import { FlightFilterType } from "../../models/flights-filters.models";
import { FlightsActions } from "../../state/flights.actions";
import { FlightsState } from "../../state/flights.state";
import { ArchiveDialogComponent } from "../archive-dialog/archive-dialog.component";
import { FlightsLayerDirective } from "../flights-layer/flights-layer.directive";
import { FlightsPanelComponent } from "../flights-panel/flights-panel.component";

enum ActionSidebarType {
    Checkin = "Checkin",
    Hospital = "Hospital",
}

interface FlightsContainerComponentState {
    checkinAction: CheckinAction | undefined;
    actionSidebarType: ActionSidebarType | undefined;
    zoom: { bounds: LatLngBounds } | undefined;
}

// TODO DTATS-72 - integration with api
const JURISDICTION_COORDS_STUB: LatLngExpression[] = [
    /* eslint-disable no-magic-numbers*/
    [52.38, 20.35],
    [52.38, 20.87],
    [52.58, 20.87],
    [52.58, 20.35],
    [52.38, 20.35],
    /* eslint-enable no-magic-numbers*/
];

@UntilDestroy()
@Component({
    selector: "dats-lib-flights-container",
    templateUrl: "./flights-container.component.html",
    styleUrls: ["./flights-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightsContainerComponent {
    protected readonly isPanelProcessing$ = this.store.select(FlightsState.isPanelProcessing);
    protected readonly isActionProcessing$ = this.store.select(FlightsState.isActionProcessing);
    protected readonly flightList$ = this.store.select(FlightsState.filteredFlightList);
    protected readonly hospitalList$ = this.store.select(AlertState.hospitalList);
    protected readonly selectedFlightId$ = this.store.select(FlightsState.selectedFlightId);
    protected readonly appliedFilters$ = this.store.select(FlightsState.appliedFilters);
    protected readonly isAtcController$ = this.store.select(FlightsState.isAtcController);
    protected readonly selectedTabType$ = this.store.select(FlightsState.selectedTabType);
    protected readonly selectedAlertType$ = this.store.select(AlertState.selectedAlertType);
    protected readonly tabs$ = this.store.select(FlightsState.tabs);
    protected readonly jurisdictionMissions$ = this.store.select(FlightsState.jurisdictionMissions);

    protected readonly ActionSidebarType = ActionSidebarType;
    protected readonly FlightAcceptancePhase = FlightAcceptancePhase;
    protected readonly checkinAction$ = this.localStore.selectByKey("checkinAction").pipe(RxjsUtils.filterFalsy());
    protected readonly actionSidebarType$ = this.localStore.selectByKey("actionSidebarType");
    protected readonly zoom$ = this.localStore.selectByKey("zoom");
    protected readonly flight$ = this.localStore.selectByKey("checkinAction").pipe(
        RxjsUtils.filterFalsy(),
        map((checkinAction) => checkinAction.flight)
    );

    protected readonly ButtonTheme = ButtonTheme;
    protected readonly CheckinActionType = CheckinActionType;

    @ViewChild(FlightsPanelComponent) private readonly flightsPanelComponent: FlightsPanelComponent | undefined;
    @ViewChild(FlightsLayerDirective) private readonly flightsLayerDirective: FlightsLayerDirective | undefined;

    protected readonly mapOptions: MapOptions;

    constructor(
        private readonly localStore: LocalComponentStore<FlightsContainerComponentState>,
        private readonly store: Store,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly dialogService: DialogService,
        @Inject(LEAFLET_MAP_CONFIG) leafletConfig: LeafletMapConfig
    ) {
        this.localStore.setState({
            checkinAction: undefined,
            actionSidebarType: undefined,
            zoom: undefined,
        });

        this.mapOptions = {
            center: leafletConfig.defaultPosition,
            zoom: leafletConfig.zoom.initial,
            minZoom: leafletConfig.zoom.min,
            maxZoom: leafletConfig.zoom.max,
            zoomControl: false,
            preferCanvas: true,
        };

        this.store.dispatch(new FlightsActions.GetFlights());
        this.store.dispatch(new FlightsActions.GetMissions());
    }

    protected updateSelectedTab(tab?: FlightsTab) {
        this.store.dispatch(new FlightsActions.SelectFlightsTab(tab?.type));
    }

    protected selectFlight(flightId: string | undefined, source: "map" | "list") {
        this.store.dispatch(new FlightsActions.SelectFlight(flightId));

        if (source === "map" && flightId) {
            this.flightsPanelComponent?.scrollFlightIntoView(flightId);

            return;
        }

        if (source === "list" && flightId) {
            this.flightsLayerDirective?.zoomToFlightItem(
                flightId,
                this.store.selectSnapshot(FlightsState.selectedTabType) && this.flightsPanelComponent
                    ? this.flightsPanelComponent.clientWidth
                    : 0
            );
        }
    }

    protected changeAlertType(alertType: AlertType | undefined) {
        this.store.dispatch(new AlertActions.SelectAlertType(alertType));
    }

    protected handleCheckinAction(checkinAction: CheckinAction) {
        this.localStore.patchState({ actionSidebarType: undefined });

        switch (checkinAction.type) {
            case CheckinActionType.Approve:
                this.store
                    .dispatch(new FlightsActions.AcceptCheckin(checkinAction.id))
                    .pipe(untilDestroyed(this))
                    .subscribe(() => {
                        const error = this.store.selectSnapshot(FlightsState.error);
                        if (error) {
                            this.toastService.error(this.transloco.translate("datsLibFlights.flightsContainer.acceptanceErrorMessage"));
                        }
                    });
                break;

            case CheckinActionType.Stop:
                this.store
                    .dispatch(new FlightsActions.StopCheckin(checkinAction.id))
                    .pipe(untilDestroyed(this))
                    .subscribe(() => {
                        const error = this.store.selectSnapshot(FlightsState.error);
                        if (error) {
                            this.toastService.error(this.transloco.translate("datsLibFlights.flightsContainer.stopErrorMessage"));
                        }
                    });
                break;

            case CheckinActionType.Standby:
                this.localStore.patchState({ checkinAction, actionSidebarType: ActionSidebarType.Checkin });
                break;

            case CheckinActionType.Modify:
                this.localStore.patchState({ checkinAction, actionSidebarType: ActionSidebarType.Checkin });
                break;

            case CheckinActionType.Archive:
                this.openArchiveDialog(checkinAction);

                break;
        }
    }

    protected openArchiveDialog(checkinAction: CheckinAction): void {
        this.dialogService
            .open(ArchiveDialogComponent)
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap((formValues) => this.store.dispatch(new FlightsActions.ArchiveCheckin(checkinAction.id, formValues))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);
                if (error) {
                    this.toastService.error(this.transloco.translate("datsLibFlights.flightsContainer.archiveErrorMessage"));
                }
            });
    }

    protected async closeActionSidebar() {
        this.localStore.patchState({ actionSidebarType: undefined });
    }

    protected manageHospitalListPanel(isOpen: boolean): void {
        this.localStore.patchState({ actionSidebarType: isOpen ? ActionSidebarType.Hospital : undefined });
    }

    protected dispatchHospitalList(hospitals: AlertPointOfInterest[]): void {
        this.store
            .dispatch(new AlertActions.SetBookmarkedHospitals(hospitals))
            .pipe(
                switchMap(() => {
                    const error = this.store.selectSnapshot(FlightsState.error);

                    if (error) {
                        this.toastService.error(this.transloco.translate("datsLibFlights.flightsContainer.bookmarkHospitalErrorMessage"));

                        return EMPTY;
                    }

                    return this.store.dispatch(new AlertActions.GetAllHospitals());
                }),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.manageHospitalListPanel(false);
            });
    }

    protected delayOrRejectCheckin(event: { id: string; duration: number; isTimeExceeded: boolean }): void {
        if (event.isTimeExceeded) {
            this.store
                .dispatch(new FlightsActions.RejectCheckin(event.id))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    const error = this.store.selectSnapshot(FlightsState.error);

                    if (error) {
                        this.toastService.error(this.transloco.translate("datsLibFlights.flightsContainer.rejectCheckinErrorMessage"));
                    }
                });

            return;
        }

        this.store
            .dispatch(new FlightsActions.StandbyCheckin(event.id, event.duration))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);

                if (error) {
                    this.toastService.error(this.transloco.translate("datsLibFlights.flightsContainer.rejectCheckinErrorMessage"));
                }
            });
    }

    protected modifyCheckin({ flight, formValues }: { flight: FlightItem; formValues: ModifyCheckinFormValues }): void {
        const action =
            flight.acceptance.phase === FlightAcceptancePhase.Pending
                ? new FlightsActions.ModifyCheckinAndAccept(flight.id, formValues)
                : new FlightsActions.ModifyCheckin(flight.id, formValues);

        this.store
            .dispatch(action)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);

                if (error) {
                    this.toastService.error(this.transloco.translate("datsLibFlights.flightsContainer.acceptanceErrorMessage"));
                }
            });
    }

    protected resendConfirmation(id: string): void {
        this.store
            .dispatch(new FlightsActions.ResendCheckinConfirmation(id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);

                if (error) {
                    this.toastService.error(this.transloco.translate("datsLibFlights.flightsContainer.resendConfirmationErrorMessage"));
                }
            });
    }

    protected applyFilters(filters: FlightFilterType[]): void {
        this.store.dispatch(new FlightsActions.ApplyFilters(filters));
    }

    protected zoomToJurisdiction() {
        const bounds = latLngBounds(JURISDICTION_COORDS_STUB);
        this.localStore.patchState({
            zoom: {
                bounds,
            },
        });
    }
}
