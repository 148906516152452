import { InjectionToken } from "@angular/core";

export interface FlightsEndpoints {
    getFlights: string;
    standbyCheckin: string;
    modifyCheckin: string;
    stopCheckin: string;
    alerts: string;
    getAlertProposals: string;
    resendCheckinConfirmation: string;
    getPoiList: string;
}

export const FLIGHTS_ENDPOINTS = new InjectionToken<FlightsEndpoints>("Flights endpoints");
