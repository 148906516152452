<div class="list" *ngrxLet="hospitalList$ as hospitalList">
    <div
        class="hospital-chip"
        [ngClass]="{ bookmarked: hospital.isBookmarked }"
        *ngFor="let hospital of hospitalList"
        (click)="markHospital(hospital)"
    >
        <span class="name">{{ hospital.title }}</span>
        <dtm-ui-icon [name]="hospital.isBookmarked ? 'star-fill' : 'star'"></dtm-ui-icon>
    </div>
</div>
