<div class="operation" *ngrxLet="{ operationDetails: operationDetails$ } as vm">
    <div class="details">
        <dtm-ui-label-value [label]="'datsLibDashboard.operationDetails.startLabel' | transloco">
            <div
                class="utc-time-wrapper"
                [matTooltip]="
                    vm.operationDetails.plannedStartAt | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }
                "
            >
                <dtm-ui-icon name="time-utc"></dtm-ui-icon>
                {{ vm.operationDetails.plannedStartAt | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}
            </div>
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'datsLibDashboard.operationDetails.finishLabel' | transloco">
            <div
                class="utc-time-wrapper"
                [matTooltip]="
                    vm.operationDetails.plannedEndAt | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }
                "
            >
                <dtm-ui-icon name="time-utc"></dtm-ui-icon>
                {{ vm.operationDetails.plannedEndAt | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}
            </div>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'datsLibDashboard.operationDetails.heightLabel' | transloco"
            [value]="
                'datsLibDashboard.operationDetails.metersValue' | transloco : { value: vm.operationDetails.maxHeight | localizeNumber }
            "
        ></dtm-ui-label-value>
    </div>

    <div class="time-info">
        <dtm-ui-icon name="error-warning-fill"></dtm-ui-icon>
        {{
            "datsLibDashboard.operationDetails.timeInfo"
                | transloco
                    : {
                          time: vm.operationDetails.plannedEndAt | localizeDate : { timeStyle: "short", timeZone: "UTC" },
                          date: vm.operationDetails.plannedEndAt | localizeDate : { timeZone: "UTC", timeZoneName: "short" }
                      }
        }}
    </div>
</div>
