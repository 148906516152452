export interface Alert {
    id: string;
    type: AlertBadgeType;
    title: string;
    text: string;
    startedAt: string;
    finishedAt: string;
    polygon: Polygon;
}

export enum AlertBadgeType {
    NoFlyZone = "NO_FLY_ZONE",
    Message = "MESSAGE",
    Modification = "MODIFICATION",
    NotStarted = "NOT_STARTED",
}

export type CategorizedAlertsByBadge = Partial<Record<AlertBadgeType, Alert[]>>;

interface Polygon {
    data: string;
}
