<h2 mat-dialog-title>{{ "datsLibDashboard.archiveConfirmationDialog.header" | transloco }}</h2>

<form [formGroup]="form">
    <dtm-ui-select-field formControlName="id" [isClearable]="false">
        <label>{{ "datsLibDashboard.archiveConfirmationDialog.templateLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let archiveOption of archiveOptions" [value]="archiveOption.id">
            {{ archiveOption.id | transloco }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <dtm-ui-textarea-field [maxLength]="200">
        <label>{{ "datsLibDashboard.archiveConfirmationDialog.descriptionLabel" | transloco }}</label>
        <textarea matInput type="text" formControlName="description"></textarea>
        <div class="field-error" *dtmUiFieldHasError="form.controls.description; name: 'maxlength'; error as error">
            {{ "datsLibDashboard.archiveConfirmationDialog.maxLengthErrorMessage" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-textarea-field>
</form>

<mat-dialog-actions>
    <button type="button" [mat-dialog-close]="false" class="button-secondary">
        {{ "datsLibDashboard.archiveConfirmationDialog.declineButtonLabel" | transloco }}
    </button>
    <button type="button" (click)="submit()" class="button-warn">
        {{ "datsLibDashboard.archiveConfirmationDialog.confirmationButtonLabel" | transloco }}
    </button>
</mat-dialog-actions>
