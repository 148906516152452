<ng-container *ngrxLet="{ timeSpans: timeSpans$, completedMinutes: completedMinutes$, duration: duration$, category: category$ } as vm">
    <div class="progress-wrapper">
        <mat-progress-bar
            *ngFor="let timeSpan of vm.timeSpans"
            [value]="timeSpan.completedMinutes | invoke : getPercentageValue"
            mode="buffer"
            [bufferValue]="timeSpan.bufferMinutes | invoke : getPercentageValue"
        ></mat-progress-bar>
    </div>
    <span class="label" *ngIf="vm.duration && vm.completedMinutes !== undefined">
        <ng-container *ngIf="vm.completedMinutes < 0">
            {{
                "datsLibDashboard.flightProgressBar.timeToStartLabel" | transloco : { value: (vm.completedMinutes | math : "abs") }
            }}</ng-container
        >
        <ng-container *ngIf="vm.completedMinutes >= 0">
            <ng-container *ngIf="vm.category | invoke : isOverdue; else timeLeftTemplate">
                {{ "datsLibDashboard.flightProgressBar.overdueTimeLabel" | transloco : { value: vm.completedMinutes } }}
            </ng-container>
            <ng-template #timeLeftTemplate>
                {{
                    "datsLibDashboard.flightProgressBar.timeLeftLabel"
                        | transloco : { value: vm.duration - vm.completedMinutes | math : "max" : 0 }
                }}
            </ng-template>
        </ng-container>
    </span>
</ng-container>
