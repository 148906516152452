import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatest, map } from "rxjs";
import { AirspaceType, CheckinAction, FlightCategory, FlightItem } from "../../models/flight.models";
import { FlightFilterType, OperationTypeFilter } from "../../models/flights-filters.models";
import { FlightItemComponent } from "../flight-item/flight-item.component";

interface CategoryFlightListComponentState {
    category: FlightCategory | undefined;
    flights: FlightItem[];
    isExpanded: boolean;
    isAtcController: boolean;
    filters: FlightFilterType[];
    filteredFlights: FlightItem[];
    selectedFlightId: string | undefined;
}

@Component({
    selector: "dats-lib-category-flight-list",
    templateUrl: "./category-flight-list.component.html",
    styleUrls: ["./category-flight-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CategoryFlightListComponent {
    @Input({ required: true }) public set flights(value: FlightItem[] | undefined) {
        this.localStore.patchState({ flights: value ?? [] });
    }

    @Input() public set category(value: FlightCategory | undefined) {
        this.localStore.patchState({ category: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    @Input() public set isAtcController(value: BooleanInput) {
        this.localStore.patchState({ isAtcController: coerceBooleanProperty(value) });
    }

    @Input() public set filters(value: FlightFilterType[] | undefined) {
        this.localStore.patchState({ filters: value ?? [] });
    }

    @Input({ required: true }) public set selectedFlightId(value: string | undefined) {
        this.localStore.patchState({ selectedFlightId: value });
    }

    @Output() public readonly confirmationResend = new EventEmitter<string>();
    @Output() public readonly checkinModify = new EventEmitter<CheckinAction>();
    @Output() public readonly selectedFlightChanged = new EventEmitter<string>();

    @HostBinding("class") protected get categoryClassName() {
        return this.getCategoryClassName(this.localStore.selectSnapshotByKey("category"));
    }

    protected readonly flights$ = this.localStore.selectByKey("flights");
    protected readonly category$ = this.localStore.selectByKey("category").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isAtcController$ = this.localStore.selectByKey("isAtcController");
    protected readonly filters$ = this.localStore.selectByKey("filters");
    protected readonly selectedFlightId$ = this.localStore.selectByKey("selectedFlightId");
    protected readonly filteredFlights$ = combineLatest([this.flights$, this.category$, this.filters$]).pipe(
        map(([flights, category, filters]) => flights.filter((flight) => this.isFlightVisible(flight, category, filters)))
    );

    @ViewChildren(FlightItemComponent) private readonly flightItemComponents: QueryList<FlightItemComponent> | undefined;

    constructor(private readonly localStore: LocalComponentStore<CategoryFlightListComponentState>) {
        this.localStore.setState({
            flights: [],
            category: undefined,
            isExpanded: true,
            isAtcController: false,
            filters: [],
            filteredFlights: [],
            selectedFlightId: undefined,
        });
    }

    public scrollFlightIntoView(flightId: string) {
        this.flightItemComponents?.find((flightItem) => flightItem.flightId === flightId)?.scrollIntoView();
    }

    protected changeSelection(isSelected: boolean, flight: FlightItem): void {
        this.selectedFlightChanged.emit(isSelected ? flight.id : undefined);
    }

    private getCategoryClassName(category: FlightCategory | undefined): string {
        switch (category) {
            case FlightCategory.Pending_112:
                return "category-pending-112";
            case FlightCategory.Emergency:
                return "category-emergency";
            case FlightCategory.Stop:
                return "category-stop";
            case FlightCategory.AcceptedATC:
            case FlightCategory.CompletedATC:
            case FlightCategory.Rejected:
            case FlightCategory.Canceled:
                return "category-atc";
            case FlightCategory.OverdueATC:
            case FlightCategory.OverdueSystem:
            case FlightCategory.OverdueOther:
                return "category-overdue";
            default:
                return "default";
        }
    }

    private isFlightVisible(flight: FlightItem, category: FlightCategory, filters: FlightFilterType[]): boolean {
        if (!filters.length) {
            return true;
        }

        for (const filter of filters) {
            if (filter === OperationTypeFilter.Type112 && flight.is112) {
                return true;
            }

            if (filter === AirspaceType.CTR && flight.isAirspaceTypeCTR) {
                return true;
            }

            if (filter === AirspaceType.MCTR && flight.isAirspaceTypeMCTR) {
                return true;
            }

            if (filter === category) {
                return true;
            }
        }

        return false;
    }
}
