<dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isDisabled]="true" *ngrxLet="mission$ as mission">
    <div class="header" headerSlot>
        <div class="header-row">
            <div class="header-row-item"><dtm-ui-icon name="user"></dtm-ui-icon> {{ mission.operatorName }}</div>
            <div class="header-row-item">
                <div class="chip default">
                    {{ "datsLibDashboard.missionItem.missionTypeLabel" | transloco : { value: mission.type } }}
                </div>
                <button type="button" class="button-icon" (click)="openMissionDetails()">
                    <dtm-ui-icon name="information-fill"></dtm-ui-icon>
                </button>
            </div>
        </div>
        <div class="header-row">
            <div
                class="header-row-item"
                [matTooltip]="mission.startDateTime | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }"
            >
                <dtm-ui-icon name="calendar-check-fill" class="success"></dtm-ui-icon>
                <div class="date">
                    <div>
                        {{
                            "datsLibDashboard.timeHeightDisplay.fromLabel"
                                | transloco : { value: mission.startDateTime | localizeDate : { dateStyle: "short", timeZone: "UTC" } }
                        }}
                    </div>
                    <span class="time">{{ mission.startDateTime | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}</span>
                </div>
            </div>
            <div
                class="header-row-item"
                [matTooltip]="mission.endDateTime | localizeDate : { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' }"
            >
                <dtm-ui-icon name="calendar-close-fill" class="error"></dtm-ui-icon>
                <div class="date">
                    <div>
                        {{
                            "datsLibDashboard.timeHeightDisplay.toLabel"
                                | transloco : { value: mission.endDateTime | localizeDate : { dateStyle: "short", timeZone: "UTC" } }
                        }}
                    </div>
                    <span class="time">{{ mission.endDateTime | localizeDate : { timeStyle: "short", timeZone: "UTC" } }}</span>
                </div>
            </div>
            <div class="header-row-item">
                <dtm-ui-icon name="complex-top-height" class="height-icon"></dtm-ui-icon>
                <span class="height-label">{{
                    "datsLibDashboard.timeHeightDisplay.heightLabel" | transloco : { value: mission.maxHeight | localizeNumber }
                }}</span>
            </div>
        </div>
    </div>

    <!-- TODO: DTATS-232 mission details -->
</dtm-ui-expandable-panel>
