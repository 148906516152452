import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { FlightsTab, FlightsTabType } from "../../models/flight.models";

interface FlightsTabItemComponentState {
    flightsTab: FlightsTab | undefined;
    isSelected: boolean;
}

@Component({
    selector: "dats-lib-flights-tab-item[flightsTab]",
    templateUrl: "./flights-tab-item.component.html",
    styleUrls: ["./flights-tab-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightsTabItemComponent {
    @Input() public set flightsTab(value: FlightsTab | undefined) {
        this.localStore.patchState({ flightsTab: value ?? undefined });
    }
    @Input() public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });
    }
    @HostBinding("class.selected") protected get isTabSelected() {
        return this.localStore.selectSnapshotByKey("isSelected");
    }
    protected readonly flightsTab$ = this.localStore.selectByKey("flightsTab");
    protected readonly FlightsTabType = FlightsTabType;

    constructor(private readonly localStore: LocalComponentStore<FlightsTabItemComponentState>) {
        this.localStore.setState({
            flightsTab: undefined,
            isSelected: false,
        });
    }
}
