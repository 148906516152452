import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { FlightsContainerComponent } from "./components/flights-container/flights-container.component";

const routes: Routes = [
    {
        path: "flights",
        component: FlightsContainerComponent,
        canActivate: [DtmRoleGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FlightsRoutingModule {}
