<ng-container *ngrxLet="{ isExpanded: isExpanded$, isDisabled: isDisabled$ } as vm">
    <div class="header-container" [class.expanded]="vm.isExpanded">
        <div class="header">
            <ng-content select="[headerSlot]"></ng-content>
        </div>

        <mat-slide-toggle
            (click)="togglePanel($event, vm.isExpanded, vm.isDisabled)"
            [disabled]="vm.isDisabled"
            [ngModel]="vm.isExpanded"
        ></mat-slide-toggle>
    </div>

    <div class="content" *ngIf="vm.isExpanded" @slideInWithMargin>
        <ng-content></ng-content>
    </div>
</ng-container>
